import React from 'react';
import {
    Tooltip, Box
} from '@material-ui/core';
import L1 from 'img/L1.png';
import L2 from 'img/L2.png';
import L3 from 'img/L3.png';
import L1New from 'img/L1_new.png';
import L2New from 'img/L2_new.png';
import L3New from 'img/L3_new.png';
import L1NewAetna from 'img/L1_new_aetna.png';
import L2NewAetna from 'img/L2_new_aetna.png';
import L3NewAetna from 'img/L3_new_aetna.png';

export default function RenderLayoutOption({ record, brandName }) {
    const id = `${record.id}`;
    const imgStyle = {
        width: '10rem', borderRadius: '5px', margin: '1.2rem 1rem'
    };
    return (
        <Tooltip
            arrow
            placement="right"
            title={
                id === '1'
                    ? (
                        <img
                            src={brandName === 'Aetna' ? L1NewAetna : (brandName === 'Aetna International' ? L1New : L1)}
                            style={imgStyle}
                            alt={record.name}
                        />
                    )
                    : id === '2'
                        ? (
                            <img
                                src={brandName === 'Aetna' ? L2NewAetna : (brandName === 'Aetna International' ? L2New : L2)}
                                style={imgStyle}
                                alt={record.name}
                            />
                        )
                        : (
                            <img
                                src={brandName === 'Aetna' ? L3NewAetna : (brandName === 'Aetna International' ? L3New : L3)}
                                style={imgStyle}
                                alt={record.name}
                            />
                        )
            }
        >
            <Box style={{ width: '100%' }}>{record.name}</Box>
        </Tooltip>
    );
}
