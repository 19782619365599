import * as React from 'react';
import {
    Notification
} from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'components/theme';
import LoginForm from './LoginForm';

const Login = () => {
    return (
        <ThemeProvider theme={theme}>
            <LoginForm />
            <Notification />
        </ThemeProvider>
    );
};

export default Login;
