import React, { useState } from 'react';

import { useDataProvider } from 'react-admin';
import {
    Grid, TextField, Button,
    TableContainer, Table, TableHead, TableRow, TableBody, TableCell, CircularProgress
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function UserSelect({
    selected, toggleSelected
}) {
    const dataProvider = useDataProvider();
    const [q, setQ] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const findUsers = () => {
        setLoading(true);
        dataProvider.findUsers(q.trim())
            .then(({ data }) => {
                setUsers(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <Grid container spacing={2} fullWidth>
            <Grid item xs={12}>
                <TextField value={q} onChange={(e) => setQ(e.target.value)} />
                <Button onClick={findUsers} disabled={!q.trim() || loading} color="inherit">Search</Button>
                {loading && <CircularProgress size="1rem" />}
            </Grid>
            <Grid item xs={12}>

                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell>Selected</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        <Button onClick={() => toggleSelected(row)}>{row.userName}</Button>
                                    </TableCell>
                                    <TableCell>{selected.some((s) => s.id === row.id) && <CheckBoxIcon />}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
