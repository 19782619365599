import React from 'react';
import { createPortal } from 'react-dom';
import { Draggable } from 'react-beautiful-dnd';

const FixedDraggable = (props) => {
    const optionalPortal = (styles, element) => {
        const dragEl = document.getElementById('draggableForModal');

        if (styles && styles.position && styles.position === 'fixed') {
            return createPortal(
                element,
                dragEl,
            );
        }
        return element;
    };

    return (
        <Draggable {...props}>
            {(...pp) => {
                return (
                    <>
                        {optionalPortal(pp[0].draggableProps.style, props.children && props.children(...pp))}
                    </>
                );
            }}
        </Draggable>
    );
};

export default FixedDraggable;
