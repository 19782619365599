import React, { useState } from 'react';
import {
    IconButton, Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import Tour from 'reactour';
import GetTourSteps from './TourSteps';

const { forwardRef, useImperativeHandle } = React;

const useStyles = makeStyles(() => ({
    helper: {
        cursor: 'pointer',
        position: 'relative',
        left: '-10px'
    },
    columnHelper: {
        width: '1rem',
        height: '1rem',
        color: '#66cabb',
        cursor: 'pointer'
    },
    tourBox: {
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap',
        alignItems: 'stretch',
        padding: '.5rem 0'
    },
    tourIcon: {
        flex: 1
    },
    tourEntity: {
        flex: 20
    }
}));

export const TourContainer = forwardRef(({ stepNames, children }, ref) => {
    const steps = GetTourSteps(stepNames);
    const [currentTourStep, setCurrentTourStep] = useState(0);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const myRef = React.createRef();

    useImperativeHandle(ref, () => ({

        onShowTour(s) {
            const selector = `[data-tour="${s}"]`;
            for (let index = 0; index < steps.length; index += 1) {
                if (steps[index].selector === selector) {
                    myRef.current.gotoStep(index);
                    break;
                }
            }

            setIsTourOpen(true);
        }

    }));

    return (
        <>
            {children}

            <Tour
                steps={steps}
                isOpen={isTourOpen}
                ref={myRef}
                goToStep={currentTourStep}
                onRequestClose={() => {
                    setIsTourOpen(false);
                    setCurrentTourStep(0);
                }}
            />
        </>
    );
});

export default function TourWrapper({ tourSelector, onShowTour, children }) {
    const classes = useStyles();
    return (
        <Box data-tour={tourSelector} className={classes.tourBox}>
            <Box className={classes.tourEntity}>{children}</Box>
            <Box className={classes.tourIcon}>
                <HelpIcon className={classes.columnHelper} onClick={() => onShowTour(tourSelector)} />
            </Box>
        </Box>
    );
}

export function TourHeader({
    tourSelector, onShowTour, children, label
}) {
    return (
        <Box
            component="span"
            data-tour={tourSelector}
        >
            {children}
            {label && (<span>{label}</span>)}

            <TourIcon onShowTour={onShowTour} tourSelector={tourSelector} />
        </Box>
    );
}

export function TourIcon({ onShowTour, tourSelector }) {
    const classes = useStyles();
    return (
        <IconButton
            onClick={(e) => {
                e.stopPropagation();
                onShowTour(tourSelector);
            }}
            style={{
                padding: '0', background: '#fff', marginLeft: '.25rem', zIndex: '1', pointerEvents: 'auto'
            }}
            className="tour-icon"
        >
            <HelpIcon
                className={classes.columnHelper}
            />
        </IconButton>
    );
}
