import React, { useState } from 'react';
import {
    Button,
    Dialog, DialogActions, DialogTitle, Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDataProvider, useRefresh } from 'react-admin';

function CopyButton(props) {
    const {
        record = {}, resource, isDeleteInterface = false, callback = null, title = 'Are you sure you want to delete this?', cancelText = 'No', okText = 'Yes'
    } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const onCopyClick = () => {
        setOpenConfirm(true);
    };
    const closeConfirm = (confirmed) => {
        if (confirmed === true) {
            setLoading(true);
            const action = isDeleteInterface ? dataProvider.deleteItem(resource, record.id) : dataProvider.changeStatus(resource, { id: record.id, statusId: 4 });
            action
                .then(() => {
                    if (callback) {
                        callback();
                    }
                    refresh();
                })
                .finally(() => {
                    setLoading(false);
                    setOpenConfirm(false);
                });
        } else {
            setOpenConfirm(false);
        }
    };

    return (
        <>
            <Button
                color="primary"
                onClick={onCopyClick}
            >
                <Tooltip title="Delete">
                    <DeleteIcon />
                </Tooltip>
            </Button>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={closeConfirm}
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogActions>
                        <Button onClick={closeConfirm} disabled={loading}>
                            {cancelText}
                        </Button>
                        <Button onClick={() => closeConfirm(true)} color="primary" disabled={loading}>
                            {loading ? 'Deleting...' : okText}
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
}

export default CopyButton;
