import React, { useState } from 'react';
import {
    Button,
    Dialog, DialogActions, DialogTitle, Tooltip,
    FormControlLabel, Switch
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useDataProvider, useNotify } from 'react-admin';

function CopyButton(props) {
    const {
        record = {}, resource, history, isCustom = false, callback
    } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const [copying, setCopying] = useState(false);
    const [isDraft, setIsDraft] = useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const onCopyClick = () => {
        if (isCustom) {
            callback(record);
            return;
        }
        setOpenConfirm(true);
    };
    const closeConfirm = (confirmed) => {
        if (confirmed === true) {
            setCopying(true);
            dataProvider.duplicate(resource, { id: record.id, isDraft })
                .then((r) => {
                    if (!r.data && r.message) {
                        notify(r.message, 'error');
                    } else {
                        history.push(`/${resource}/${r.data}`);
                    }
                })
                .finally(() => {
                    setCopying(false);
                    setOpenConfirm(false);
                });
        } else {
            setOpenConfirm(false);
        }
    };

    return (
        <>
            <Button
                color="primary"
                onClick={onCopyClick}
            >
                <Tooltip title={isCustom ? 'Copy' : (record.draftFromId ? 'Copy' : 'Copy or Create Draft')}>
                    <FileCopyIcon />
                </Tooltip>
            </Button>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={closeConfirm}
                >
                    <DialogTitle id="alert-dialog-title">
                        {
                            isDraft
                                ? 'Are you sure you want to create draft from this microsite?'
                                : 'Are you sure you want to duplicate this microsite?'
                        }
                    </DialogTitle>
                    <DialogActions>
                        {
                            !record.draftFromId && (
                                <FormControlLabel
                                    style={{ marginRight: (isDraft ? '14rem' : '10rem') }}
                                    control={
                                        (
                                            <Switch
                                                checked={isDraft}
                                                onChange={() => setIsDraft(!isDraft)}
                                                name="isDraft"
                                                color="primary"
                                            />
                                        )
                                    }
                                    label="Create Draft Site"
                                />
                            )
                        }
                        <Button onClick={closeConfirm} disabled={copying}>
                            No
                        </Button>
                        <Button onClick={() => closeConfirm(true)} color="primary" disabled={copying}>
                            {copying ? 'Duplicating...' : 'Yes'}
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
}

export default CopyButton;
