/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, IconButton, Grid, FormControlLabel, Checkbox
} from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import {
    AddCircle as AddCircleIcon
} from '@material-ui/icons';

import { TourContainer, TourIcon } from 'components/Tour/TourWrapper';
import EditAccordion from './EditAccordion';
import AssetList from './AssetList';
import CalloutsInput from '../section/CalloutsInput';
import AssetSelectDialog from './AssetSelectDialog';
import Attachments from '../section/Attachments';
import FindADoctor from '../section/FindADoctor';
import TransparencyCoverage from '../section/TransparencyCoverage';
import OpenEnrollment from '../section/OpenEnrollment';
import MainSection from '../section/MainSection';
import Navigation from '../section/Navigation';
import Branding from '../section/Branding';
import getBase64 from '../../getBase64';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '0 !important'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    icon: {
        marginTop: '2px'
    },
    header: {
        width: '10rem',
        color: '#fff',
        background: '#707070',
        lineHeight: '28px',
        height: '28px',
        cursor: 'move'
    },

    edit: {

    },
    accordions: {
        boxShadow: '0px 3px 6px #00000029',
        background: '#fff'
    },
    addAssetBtn: {
        position: 'absolute',
        color: '#555',
        right: '4rem',
        top: '1rem'
    },
    fileRoot: {
        background: 'none'
    },
    RaSimpleFormIterator: {
        line: {
            background: '#f00'
        }
    }
}));

export default function EditPane(props) {
    const [initialized, setInitialized] = useState(false);
    const [fetchedOptions, setFetchedOptions] = useState(false);
    const [assetCategory, setAssetCategory] = useState('');
    const [assetOptions, setAssetOptions] = useState(null);

    const [allAgreed, setAllAgreed] = useState(false);
    const [acknowledge, setAcknowledge] = useState(false);
    const [confidential, setConfidential] = useState(false);
    const [stateRestrictions, setStateRestrictions] = useState(false);
    const [spanishTranslated, setSpanishTranslated] = useState(false);

    const {
        record = {}, resource, setFormData, formData, onAssetLoaded, onAllAgreed
    } = props;
    const [medias, setMedias] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const classes = useStyles();

    const [themeOptions, setThemeOptions] = useState([]);

    const layouts = [
        { id: 1, name: 'Layout 1' },
        { id: 2, name: 'Layout 2' },
        { id: 3, name: 'Layout 3' }
    ];

    const toggleAssetSelect = (asset) => {
        const changed = assetOptions.find((o) => o.category.name === assetCategory)
            .assets.find((a) => a.id === asset.id);
        changed.selected = !changed.selected;
        setAssetOptions([...assetOptions]);
        setFormData('assets', [...assetOptions]);
    };

    const toggleAssetFeatured = (asset) => {
        const changed = assetOptions.find((o) => o.category.name === assetCategory)
            .assets.find((a) => a.id === asset.id);
        changed.featured = !changed.featured;
        setAssetOptions([...assetOptions]);
        setFormData('assets', [...assetOptions]);
    };

    const dataProvider = useDataProvider();
    useEffect(() => {
        // load assets
        if (!fetchedOptions) {
            setFetchedOptions(true);

            dataProvider.getAssetOptions(resource, { fundingType: record.fundingTypeName, id: record.id, language: record.language || 'English' })
                .then(({ data }) => {
                    setAssetOptions(data);
                    setFormData('assets', data);
                    onAssetLoaded();
                })
                .catch((optionError) => {
                    setFetchedOptions(false);
                    // eslint-disable-next-line no-console
                    console.error(optionError);
                });
        }

        if (record.additionalAttachments && !initialized) {
            setAttachments(record.additionalAttachments);
        }
        if (record.customerLogoFileData && !initialized) {
            setFormData('customerLogoFileData', {
                ...record.customerLogoFileData
            });
        }

        if (record.medias && !initialized) {
            setMedias(record.medias.length > 0 ? record.medias : [{ id: `${-new Date().getTime()}`, value: '' }]);
        }
        if (record.defaultDoctorButton && !initialized) {
            setInitialized(true);
        }

        onAllAgreed(allAgreed);

        if (themeOptions.length === 0 && record.options) {
            setThemeOptions(record.options.themeOptions.map((t) => ({ id: t, name: t })));
        }
    }, [record, resource, dataProvider, initialized, setFormData, assetOptions, fetchedOptions, onAssetLoaded, allAgreed, onAllAgreed, themeOptions]);

    const saveAssetChange = (categoryName, newAssets) => {
        const { assets } = assetOptions.find((s) => s.category.name === categoryName);
        for (const a of assets) {
            a.selected = false;
        }

        for (let i = 0; i < newAssets.length; i += 1) {
            const a = newAssets[i];
            const aa = assets.find((s) => s.id === a.id);
            aa.selected = true;
            aa.presentationSequence = i;
        }
        setAssetOptions([...assetOptions]);
        setFormData('assets', [...assetOptions]);
    };

    const changeMedias = (v) => {
        setMedias(v);
        setFormData('medias', v);
    };
    const changeAttachments = (v) => {
        setAttachments(v);
        setFormData('additionalAttachments', v);
    };

    const onLogoChange = (file) => {
        if (!file) {
            setFormData('customerLogoFileData', null);
        } else {
            getBase64(file).then(async (fileData) => {
                setFormData('customerLogoFileData', {
                    fileData,
                    fileName: file.name,
                    id: `${-new Date().getTime()}`
                });
            });
        }
    };

    const tourNames = record && record.fundingTypeName && record.fundingTypeName.startsWith('S')
        ? [
            'Name', 'Client Name',
            'Vanity URL', 'PS Unique Code',
            'Theme', 'Layout',
            'Client Logo', 'Client Logo Permission',

            'ID Card', 'Inspira HSA/FSA',
            'Health Care 101', 'Log In',
            'Contact Popup',
            'Contact Phone',
            'Contact Start Time', 'Contact End Time',
            'Find A Doctor Link',
            'Find A Doctor Popup',

            'Title', 'Introduction Content', 'Media Oembed', 'Password', 'Expiry Date',

            'Open Enrollment', 'Enable Open Enrollment', 'Link To Open Enrollment', 'Document Browser', 'OE Start Date',
            'OE End Date',
            'Enable Find A Doctor',
            'Find A Doctor Button Link',
            'Find A Doctor Button Label',
            'Find A Doctor Content',
            'Attachment Title',

            'Acknowledgement', 'Acknowledgement2',
            'Save'
        ]
        : [
            'Name', 'Client Name',
            'Vanity URL', 'PS Unique Code',
            'Theme', 'Layout',
            'Client Logo', 'Client Logo Permission',

            'ID Card', 'Inspira HSA/FSA',
            'Health Care 101', 'Log In',
            'Contact Popup',
            'Contact Phone',
            'Contact Start Time', 'Contact End Time',
            'Find A Doctor Link',
            'Find A Doctor Popup',

            'Title', 'Media Oembed', 'Password', 'Expiry Date', 'Open Enrollment', 'Enable Open Enrollment',
            'Link To Open Enrollment', 'Document Browser', 'OE Start Date',
            'OE End Date',

            'Enable Find A Doctor',
            'Find A Doctor Button Link',
            'Find A Doctor Button Label',
            'Find A Doctor Content',

            'Attachment Title',

            'Acknowledgement', 'State Restrictions', 'Acknowledgement2',
            'Save'
        ];
    const tourRef = React.useRef();
    const forwardShowTour = (s) => tourRef.current.onShowTour(s);
    const fundingType = record?.fundingTypeName;
    const stateRestrictionsRequired = record && record.fundingTypeName && record.fundingTypeName.startsWith('F');
    return (
        <TourContainer
            ref={tourRef}
            stepNames={tourNames}
        >
            <Box style={{ padding: '0' }} className={classes.edit}>

                <div className={classes.accordions}>
                    <div className="edit-panel-body">

                        <EditAccordion title="Branding" className={classes.root}>
                            <Branding
                                themes={themeOptions}
                                layouts={layouts}
                                onLogoChange={onLogoChange}
                                formData={formData}
                                forwardShowTour={forwardShowTour}
                                subPath={record.subPath}
                                vanityUrlReadonly={!!record.draftFromId}
                                fundingTypeName={record.fundingTypeName}
                            />
                        </EditAccordion>

                        <EditAccordion title="Navigation">
                            <Navigation
                                forwardShowTour={forwardShowTour}
                                brandName={record.brandName}
                                linkOptions={record.options?.linkOptions}
                                timezoneOptions={record.options?.timezoneOptions || []}
                                language={record.language}
                            />
                        </EditAccordion>

                        <EditAccordion title="Main Section">
                            <MainSection
                                isEnglish={record.language !== 'Spanish'}
                                hasExtraVideo={record.brandName === 'Aetna'}
                                medias={medias}
                                forwardShowTour={forwardShowTour}
                                fundingTypeName={record.fundingTypeName}
                                setMedias={changeMedias}
                            />
                        </EditAccordion>

                        {
                            assetOptions && assetOptions.map((a) => (
                                <EditAccordion key={a.category.name} title={a.category.name}>
                                    <Box style={{ width: '100%' }}>
                                        <AssetList
                                            value={a.assets.filter((v) => v.selected).sort((x, y) => ((x.presentationSequence > y.presentationSequence) ? 1 : -1))}
                                            onChange={(v) => saveAssetChange(a.category.name, v)}
                                        />
                                        <Box>
                                            <IconButton onClick={() => setAssetCategory(a.category.name)}>
                                                <AddCircleIcon color="primary" style={{ cursor: 'pointer' }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </EditAccordion>

                            ))
                        }
                        {
                            record.fundingTypeName === 'Self Insured' && record.language === 'English' && (
                                <EditAccordion title="Call-Outs">
                                    <Box style={{ width: '100%' }}>
                                        <CalloutsInput
                                            forwardShowTour={forwardShowTour}
                                            brandName={record.brandName === 'PayFlex' ? 'payflex' : ''}
                                        />
                                    </Box>
                                </EditAccordion>
                            )
                        }

                        <EditAccordion title="Open Enrollment">
                            <OpenEnrollment
                                forwardShowTour={forwardShowTour}
                            />
                        </EditAccordion>

                        <EditAccordion title={record.brandName === 'PayFlex' ? 'Customizable Button' : 'Find a Doctor Button'}>
                            <FindADoctor
                                forwardShowTour={forwardShowTour}
                                enableContent={record.language === 'English'}
                                defaultButton={record.defaultDoctorButton || {}}
                                disableTour={record.brandName === 'PayFlex'}
                            />
                        </EditAccordion>

                        <EditAccordion title="Additional Attachments">
                            <Attachments
                                forwardShowTour={forwardShowTour}
                                items={attachments}
                                onChange={changeAttachments}
                                marketSegment={record.marketSegment}
                                fundingTypeId={record.fundingTypeId}
                                brandName={record.brandName}
                            />
                        </EditAccordion>

                        <EditAccordion title="Machine-Readable Files">
                            <TransparencyCoverage
                                tcLink={record.tcLink}
                                tcLabel={record.tcLabel}
                            />
                        </EditAccordion>

                        <Grid style={{ padding: '1rem' }} container spacing={4}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={confidential}
                                            onChange={(e) => {
                                                setConfidential(e.target.checked);
                                                setAllAgreed(
                                                    acknowledge
                                                    && e.target.checked
                                                    && (record.fundingTypeName.startsWith('S') || stateRestrictions)
                                                    && (record.language === 'English' || spanishTranslated)
                                                );
                                            }}
                                            color="primary"
                                        />
                                    )}
                                    label={(
                                        <span data-tour="Acknowledgement">
                                            I acknowledge that this does not include any confidential or protected health information and has gone through
                                            the appropriate Aetna advertising compliance and legal reviews.
                                            <TourIcon onShowTour={forwardShowTour} tourSelector="Acknowledgement" />
                                        </span>
                                    )}
                                />
                            </Grid>
                            {stateRestrictionsRequired && (
                                <Grid item xs={12} style={{ padding: '.5rem 1rem' }}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={stateRestrictions}
                                                onChange={(e) => {
                                                    setStateRestrictions(e.target.checked);
                                                    setAllAgreed(
                                                        acknowledge
                                                        && confidential
                                                        && e.target.checked
                                                        && (record.language === 'English' || spanishTranslated)
                                                    );
                                                }}
                                                color="primary"
                                            />
                                        )}
                                        label={(
                                            <span data-tour="State Restrictions">
                                                I acknowledge that I&apos;ve adhered to the state level restrictions of content within the Plans, Programs,
                                                and Tools section of this tool
                                                <TourIcon onShowTour={forwardShowTour} tourSelector="State Restrictions" />
                                            </span>
                                        )}
                                    />

                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={acknowledge}
                                            onChange={(e) => {
                                                setAcknowledge(e.target.checked);
                                                setAllAgreed(
                                                    confidential
                                                    && e.target.checked
                                                    && (record.fundingTypeName.startsWith('S') || stateRestrictions)
                                                    && (record.language === 'English' || spanishTranslated)
                                                );
                                            }}
                                            color="primary"
                                        />
                                    )}
                                    label={(
                                        <span data-tour="Acknowledgement2">
                                            {`I acknowledge that the microsite I built is only to be used for
                                             ${fundingType.replace(' ', '-')} Customers.* `}
                                            <TourIcon onShowTour={forwardShowTour} tourSelector="Acknowledgement2" />
                                        </span>
                                    )}
                                />
                            </Grid>
                            {record.language === 'Spanish' && (
                                <Grid item xs={12} style={{ padding: '.5rem 1rem' }}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={spanishTranslated}
                                                onChange={(e) => {
                                                    setSpanishTranslated(e.target.checked);
                                                    setAllAgreed(
                                                        acknowledge
                                                        && confidential
                                                        && (record.fundingTypeName.startsWith('S') || stateRestrictions)
                                                        && e.target.checked
                                                    );
                                                }}
                                                color="primary"
                                            />
                                        )}
                                        label="I certify that all documents uploaded to Additional Attachments
                                        have been accurately translated to fully approved Spanish versions prior to posting to this site"
                                    />

                                </Grid>
                            )}
                        </Grid>
                    </div>
                </div>
                <AssetSelectDialog
                    open={!!assetCategory}
                    title={assetCategory}
                    assetOptions={(assetCategory && assetOptions && assetOptions.find((a) => a.category.name === assetCategory).assets) || []}
                    onClose={() => setAssetCategory('')}
                    onSave={() => setAssetCategory('')}
                    toggleSelect={toggleAssetSelect}
                    toggleFeatured={toggleAssetFeatured}
                    fundingType={record.fundingTypeName}
                />
            </Box>
        </TourContainer>
    );
}
