/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import {
    TextField, Grid, Divider, Box, IconButton, Button
} from '@material-ui/core';
import {
    AddCircle as AddCircleIcon,
    RemoveCircle as RemoveCircleIcon,
    DragIndicator as DragIndicatorIcon
} from '@material-ui/icons';
import getBase64 from 'components/getBase64';
import { TourHeader } from 'components/Tour/TourWrapper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PnPDialog from 'components/Microsite/control/PnPDialog';

function Attachments({
    items, onChange, forwardShowTour,
    marketSegment, fundingTypeId, brandName
}) {
    const [openPnP, setOpenPnP] = useState(false);

    const addItem = (pnp) => {
        if (pnp) {
            onChange([...items, {
                id: `${-new Date().getTime()}`, title: pnp.name, description: '', pnpId: pnp.id
            }]);
            setOpenPnP(false);
        } else {
            onChange([...items, { id: `${-new Date().getTime()}`, title: '', description: '' }]);
        }
    };
    const removeItem = (i) => {
        items.splice(i, 1);
        const newItems = [...items];
        onChange(newItems);
    };
    const onItemChange = (i, name, newValue) => {
        const newItems = [...items];
        newItems[i][name] = newValue;
        onChange(newItems);
    };
    const grid = 2;
    const onFileChange = (i, files) => {
        if (files === false) {
            onItemChange(i, 'id', `${-new Date().getTime()}`);
            return;
        }

        if (files.length > 0) {
            const file = files[0];
            getBase64(file).then(async (fileData) => {
                onItemChange(i, 'rawFile', { fileName: file.name, fileData });
            });
        } else {
            onItemChange(i, 'fileData', '');
        }
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging ? '#DDD' : '#FFF',
        margin: `-20x 0 ${grid}px 0`,
        ...draggableStyle
    });

    const getListStyle = () => ({
        padding: grid,
        width: '100%'
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const sorted = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        onChange(sorted);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    return (
        <Box style={{ width: '100%' }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {items && items.map((item, index) => {
                                return (
                                    <Draggable key={item.id} draggableId={item.id + item.displayTitle} index={index}>
                                        {(dragProvided, s) => (
                                            <Grid
                                                container
                                                ref={dragProvided.innerRef}
                                                {...dragProvided.draggableProps}
                                                {...dragProvided.dragHandleProps}
                                                style={getItemStyle(
                                                    s.isDragging,
                                                    dragProvided.draggableProps.style
                                                )}
                                                spacing={2}
                                                key={item.id}
                                            >
                                                {
                                                    item.id > 0 && !item.rawFile.fileData
                                                        ? (
                                                            <Grid item xs={12}>
                                                                <IconButton onClick={() => onFileChange(index, false)} style={{ color: '#f00' }}>
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                                {item.rawFile.fileName}
                                                            </Grid>
                                                        )
                                                        : null
                                                }
                                                <Grid item xs={1} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                                                    <DragIndicatorIcon style={{ color: '#2185D0' }} />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    {
                                                        item.pnpId
                                                            ? (
                                                                <TextField
                                                                    size="small"
                                                                    label=""
                                                                    placeholder="Static PnP"
                                                                    variant="outlined"
                                                                    helperText=""
                                                                    fullWidth
                                                                    style={{ marginTop: '.5rem' }}
                                                                    disabled
                                                                />
                                                            )
                                                            : (
                                                                <TextField
                                                                    size="small"
                                                                    placeholder=""
                                                                    variant="outlined"
                                                                    helperText=""
                                                                    fullWidth
                                                                    type="file"
                                                                    onChange={(e) => onFileChange(index, e.target.files)}
                                                                />

                                                            )
                                                    }
                                                    <TextField
                                                        size="small"
                                                        label={
                                                            index === 0
                                                                ? (
                                                                    <TourHeader
                                                                        tourSelector="Attachment Title"
                                                                        label="Title"
                                                                        onShowTour={forwardShowTour}
                                                                    />
                                                                )
                                                                : (<span>Title</span>)
                                                        }
                                                        placeholder=""
                                                        variant="outlined"
                                                        helperText=""
                                                        fullWidth
                                                        style={{ marginTop: '.5rem' }}
                                                        defaultValue={item.title || ''}
                                                        onBlur={(e) => onItemChange(index, 'title', e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}
                                                >
                                                    <RemoveCircleIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => removeItem(index)} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider style={{ marginBottom: '.5rem' }} />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleIcon />}
                        onClick={() => addItem()}
                    >
                        From Local
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleIcon />}
                        onClick={() => setOpenPnP(true)}
                    >
                        From Library
                    </Button>
                </Grid>
            </Grid>
            <PnPDialog
                open={openPnP}
                onClose={() => setOpenPnP(false)}
                onSelect={addItem}
                fundingTypeId={fundingTypeId}
                marketSegment={marketSegment}
                brandName={brandName}
            />
        </Box>
    );
}

export default Attachments;
