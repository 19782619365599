import React, { useState, useEffect } from 'react';
import {
    Button, Select,
    Dialog, DialogActions, DialogTitle, DialogContent, MenuItem, Tooltip
} from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { useDataProvider, useRefresh } from 'react-admin';

function ChangeOwner(props) {
    const { record = {}, resource } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newOwner, setNewOwner] = useState('');
    const [users, setUsers] = useState([]);

    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const onTransferClick = () => {
        setOpenConfirm(true);
    };
    const saveNewOwner = () => {
        setLoading(true);
        dataProvider.changeOwner(resource, { id: record.id, ownerId: newOwner })
            .then(() => {
                refresh();
            })
            .finally(() => {
                setLoading(false);
                setOpenConfirm(false);
            });
    };
    useEffect(() => {
        if (openConfirm) {
            dataProvider.getUsers()
                .then(({ data }) => {
                    setUsers(data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [dataProvider, openConfirm]);

    return (
        <>
            <Button
                color="primary"
                onClick={onTransferClick}
            >
                <Tooltip title="Change Owner">
                    <PeopleAltIcon />
                </Tooltip>
            </Button>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">Change Owner</DialogTitle>

                    <DialogContent dividers>
                        <Select
                            variant="outlined"
                            label="New Owner"
                            fullWidth
                            value={newOwner}
                            onChange={(e) => {
                                setNewOwner(e.target.value);
                            }}
                        >
                            {
                                users.map((s) => (
                                    <MenuItem key={s} value={s.id}>{s.userName}</MenuItem>
                                ))
                            }
                        </Select>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirm(false)} disabled={loading}>
                            Close
                        </Button>
                        <Button onClick={() => saveNewOwner()} color="primary" disabled={loading || !newOwner}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
}

export default ChangeOwner;
