import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDataProvider } from 'react-admin';

export default function RenameDialog({
    open, onClose, original, module
}) {
    const ext = original.type === 'folder'
        ? null
        : original.name.split('.').pop();
    const nameOnly = original.type === 'folder'
        ? original.name
        : original.name.replace(`.${ext}`, '');
    const [newName, setNewName] = useState(nameOnly);
    const [saving, setSaving] = useState(false);
    const dataProvider = useDataProvider();
    const createFolder = () => {
        setSaving(true);
        dataProvider.rename('explorer', {
            relativePath: original.relativePath,
            type: original.type,
            module,
            newName: ext ? `${newName}.${ext}` : newName
        })
            .then(() => {
                onClose(ext ? `${newName}.${ext}` : newName);
                setSaving(false);
                setNewName('');
            });
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="form-dialog-title">Rename</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="newName"
                    label="New name"
                    type="text"
                    fullWidth
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={createFolder} color="primary" disabled={!newName || saving}>
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
