import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton, Grid, LinearProgress
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useDataProvider } from 'react-admin';
import MenuBar from './MenuBar';
import FolderBreadcrumbs from './FolderBreadcrumbs';
import FileInfosView from './FilesView';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const {
        children, classes, onClose, ...other
    } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiDialogContent);

const CalloutsFolder = 'Image Library';

export default function ResourceDialog({
    open, onClose, onFileSelect, module, useStaticCallouts, brandName, allowCopyLink
}) {
    const [viewMode, setViewMode] = useState('List');
    const [currentDirectory, setCurrentDirectory] = useState('');
    const [fileInfos, setFileInfos] = useState(null);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    React.useEffect(() => {
        if (open && fileInfos === null) {
            setLoading(true);
            dataProvider.dir('explorer', { directory: currentDirectory, module, brand: brandName })
                .then((r) => {
                    setFileInfos(r.data);
                    setLoading(false);
                });
        }
    }, [currentDirectory, dataProvider, open, fileInfos, module, brandName]);

    const refreshDir = () => {
        setFileInfos(null);
    };
    const openDirectory = (dir) => {
        setFileInfos(null);
        setCurrentDirectory(dir);
    };
    const onFolderAdded = (name) => {
        setFileInfos([{ name, type: 'folder', relativePath: currentDirectory ? `/${currentDirectory}/${name}` : `/${name}` }, ...fileInfos]);
    };
    const onFileDelete = (f) => {
        setFileInfos(fileInfos.filter((i) => i.relativePath !== f.relativePath));
        dataProvider.delete('explorer', { relativePath: f.relativePath, module, type: f.type })
            .then(() => {
            });
    };

    const isRoot = currentDirectory === '';
    const staticFolders = [];
    if (isRoot && useStaticCallouts) {
        staticFolders.push({
            name: CalloutsFolder,
            type: 'folder'
        });
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="file-browser-dialog" open={open} maxWidth="sm" fullWidth>
            <DialogTitle id="file-browser-dialog" onClose={onClose}>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="h6">File Browser</Typography>
                    </Grid>
                    <Grid item xs={7} style={{ textAlign: 'right' }}>
                        {
                            currentDirectory !== 'Image Library' && (
                                <MenuBar
                                    viewMode={viewMode}
                                    onChangeViewMode={setViewMode}
                                    directory={currentDirectory}
                                    onFolderAdded={onFolderAdded}
                                    onFileUploaded={refreshDir}
                                    module={module}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <FolderBreadcrumbs directory={currentDirectory} cd={openDirectory} />
                <FileInfosView
                    fileInfos={fileInfos}
                    onFileSelect={onFileSelect}
                    directory={currentDirectory}
                    openFolder={openDirectory}
                    onFileDelete={onFileDelete}
                    module={module}
                    staticFolders={staticFolders}
                    allowCopyLink={allowCopyLink}
                />
                <Typography variant="caption">
                    Double click to select
                </Typography>
                {loading && <LinearProgress />}
            </DialogContent>
        </Dialog>
    );
}
