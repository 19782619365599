/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
    useDataProvider
} from 'react-admin';
import {
    InputBase, Typography, Grid, Paper,
    TableContainer, Table, TableBody, TableCell, TableRow, TableHead
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        width: '100%',
        minHeight: '11rem'
    },
    title: {
        color: '#000'
    },
    tile: {
        boxShadow: '0px 3px 6px #00000029',
        cursor: 'pointer'
    },
    thumb: {
        cursor: 'pointer'
    },
    titleBar: {
        background: '#fff',
        cursor: 'default'
    },
    contentRow: {
        cursor: 'pointer'
    },
    input: {
        width: '10rem'
    },
    inputIcon: {
        padding: '0'
    },
    fab: {
        position: 'absolute', left: '.5rem', top: '.5rem', zIndex: '2'
    }
}));

export default function PnPDialog({
    open, onClose, onSelect, marketSegment, fundingTypeId, brandName
}) {
    const classes = useStyles();
    const [q, setQ] = useState('');
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [pnps, setPNPs] = useState([]);
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (brandName === 'PayFlex' || (marketSegment && brandName)) {
            dataProvider.getPNPs({ marketSegment, fundingTypeId, brandName })
                .then(({ data }) => {
                    setPNPs(data);
                    setLoading(false);
                })
                .catch((optionError) => {
                    setError(optionError);
                    setLoading(false);
                });
        }
    }, [dataProvider, marketSegment, fundingTypeId, brandName]);

    if (!open) return null;

    const qReg = q ? new RegExp(q, 'i') : null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h6">Select Static PnP</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <InputBase
                            className={classes.input}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                        />
                        <IconButton type="submit" className={classes.inputIcon} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <TableContainer component={Paper} style={{ maxHeight: '400px' }}>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Saved On</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pnps.filter((p) => !q || p.name.toLowerCase().includes(q.toLowerCase()))
                                    .map((pnp) => {
                                        return (
                                            <TableRow key={pnp.id}>
                                                <TableCell>
                                                    <Button color="primary" onClick={() => onSelect(pnp)}>
                                                        {pnp.name}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>{new Date(pnp.createdOn).toLocaleDateString()}</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        );
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
