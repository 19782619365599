import apiUtil from './apiUtil';

const authProvider = {
    // called when the user attempts to log in
    login: (formData) => {
        const request = new Request(apiUtil.build('account/login'), {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((auth) => {
                if (auth.token) {
                    localStorage.setItem('auth', JSON.stringify(auth));
                } else {
                    throw new Error(auth.message || 'Invalid username or password');
                }
            })
            .catch((errorMessage) => {
                if (typeof errorMessage === 'string' && errorMessage.includes('Failed to fetch')) {
                    throw new Error('Network error');
                }

                throw new Error(errorMessage);
            });
    },

    logout: () => {
        if (!localStorage.getItem('auth')) return Promise.resolve();

        const request = new Request(apiUtil.build('account/logout'));
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(() => {
                localStorage.removeItem('auth');
                localStorage.removeItem('isCloseMsg');
            })
            .catch(() => {
                throw new Error('Network error');
            });
    },

    checkError: (response) => {
        const { status } = response || { status: 404 };
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },

    getAuth: () => {
        return localStorage.getItem('auth')
            ? JSON.parse(localStorage.getItem('auth'))
            : null;
    },

    getPermissions: () => Promise.resolve()
};

export default authProvider;
