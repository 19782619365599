/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/core/styles';
import { TextField, Box, Divider } from '@material-ui/core';
import {
    DragIndicator as DragIndicatorIcon,
    AddCircle as AddCircleIcon,
    RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import { TourHeader } from 'components/Tour/TourWrapper';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
const useStyles = makeStyles(() => ({
    mediaItem: {
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap',
        alignItems: 'stretch',
        padding: '.5rem 0'
    },
    mediaDnD: {
        flex: 1
    },
    mediaInput: {
        flex: 20
    },
    mediaBtn: {
        flex: 1
    }
}));

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#DDD' : '#FFF',
    margin: `-20x 0 ${grid}px 0`,
    ...draggableStyle
});

const getListStyle = () => ({
    padding: grid,
    width: '100%'
});

function MediaInput({
    items, onChange, forwardShowTour
}) {
    const classes = useStyles();
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const sorted = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        onChange(sorted);
    };
    const addItem = () => {
        onChange([...items, { id: `${-new Date().getTime()}`, value: '' }]);
    };
    const removeItem = (i) => {
        items.splice(i, 1);
        const newItems = [...items];
        onChange(newItems);
    };
    const onItemChange = (i, newValue) => {
        const newItems = [...items];
        newItems[i].value = newValue;
        onChange(newItems);
    };
    return (
        <Box>
            <Box>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {items && items.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(dragProvided, s) => (
                                            <Box
                                                className={classes.mediaItem}
                                                ref={dragProvided.innerRef}
                                                {...dragProvided.draggableProps}
                                                {...dragProvided.dragHandleProps}
                                                style={getItemStyle(
                                                    s.isDragging,
                                                    dragProvided.draggableProps.style
                                                )}
                                            >
                                                <Box
                                                    className={classes.mediaDnD}
                                                >
                                                    <DragIndicatorIcon style={{ marginTop: '.5rem', color: 'rgb(33, 133, 208)' }} />
                                                </Box>
                                                <Box
                                                    className={classes.mediaInput}
                                                >
                                                    <TextField
                                                        label={
                                                            index === 0
                                                                ? (
                                                                    <TourHeader
                                                                        tourSelector="Media Oembed"
                                                                        label="Media"
                                                                        onShowTour={forwardShowTour}
                                                                    />
                                                                )
                                                                : (<span>Media</span>)
                                                        }
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        value={item.value}
                                                        onChange={(e) => onItemChange(index, e.target.value)}
                                                    />
                                                </Box>
                                                <Box
                                                    className={classes.mediaBtn}
                                                    style={{ padding: '0', paddingTop: '.5rem', textAlign: 'center' }}
                                                >
                                                    <RemoveCircleIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => removeItem(index)} />
                                                </Box>
                                            </Box>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Box>
            <Box>
                <AddCircleIcon color="primary" style={{ cursor: 'pointer' }} onClick={addItem} />
                <Divider style={{ marginBottom: '1rem' }} />
            </Box>
        </Box>
    );
}

export default MediaInput;
