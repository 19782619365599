/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import {
    Link, Typography, Grid
} from '@material-ui/core';
import toFullLink from 'components/UrlUtil';

export default function FoundDialog({
    open, onClose, presentations, fileName, history
}) {
    const gotoPresentation = (id) => {
        history.push(`/all-microsites/${id}`);
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6"><b>{fileName}</b> has been found in:</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                {
                    presentations.map((p) => (
                        <Grid key={p.id} container>
                            <Grid item xs={3}><b>Segment:</b></Grid>
                            <Grid item xs={9}>{p.segment}</Grid>
                            <Grid item xs={3}><b>Microsite:</b></Grid>
                            <Grid item xs={9}>
                                <span>View: &nbsp;</span>
                                <Link target="_blank" href={toFullLink(p.url, `/${p.urlSeg}`)}>
                                    {p.name}
                                </Link>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={9}>
                                <span>Edit: &nbsp;</span>
                                <Link style={{ cursor: 'pointer' }} onClick={() => gotoPresentation(p.id)}>
                                    {p.name}
                                </Link>
                            </Grid>
                            <Grid item xs={3}><b>Location:</b></Grid>
                            <Grid item xs={9}>{p.location}</Grid>
                        </Grid>
                    ))
                }
            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
