import tourContent from './TourContent';

const GetTourSteps = (names) => {
    // return names.map((s) => TourSteps.find((t) => t.selector === `[data-tour="${s}"]`));
    return names.map((s) => {
        const tour = tourContent.find((t) => t.title === s);
        return {
            selector: `[data-tour="${s}"]`,
            content: tour ? tour.content : ''
        };
    }).filter((n) => !!n.content);
};

export default GetTourSteps;
