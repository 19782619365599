import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';

export default function FolderBreadcrumbs({ directory, cd }) {
    const segments = directory.split('/');
    const current = segments.pop();
    const parentDir = [];
    for (let index = 0; index < segments.length; index += 1) {
        const name = segments[index];
        parentDir.push({
            name,
            link: segments.slice(0, index + 1).join('/')
        });
    }

    const nameColor = directory === 'Image Library'
        ? 'primary'
        : 'inherit';

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <HomeIcon onClick={() => cd('')} style={{ cursor: 'pointer' }} />
            {
                parentDir.map((s) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link
                        color={nameColor}
                        key={s.link}
                        onClick={() => cd(s.link)}
                        style={{ cursor: 'pointer' }}
                    >
                        {s.name}
                    </Link>
                ))
            }
            <Typography
                color={nameColor}
            >
                {current}
            </Typography>
        </Breadcrumbs>
    );
}
