import {
    Button, CircularProgress, Container, CssBaseline, Grid, Paper, TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import {
    useLogin, useNotify, useDataProvider
} from 'react-admin';
import { Redirect } from 'react-router';
import logo from 'Aetna_logo_R.png';
import ResetPasswordDialog from './ResetPasswordDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3rem'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    forgetPassword: {
        textTransform: 'none',
        textDecoration: 'underline'
    }
}));

const LoginForm = (props) => {
    const classes = useStyles();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [resetting, setResetting] = useState(false);
    const [submittingReset, setSubmittingReset] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const dataProvider = useDataProvider();
    const login = useLogin();
    const notify = useNotify();

    const { isAuthUser, error, loading } = props;
    if (isAuthUser && !!window.localStorage.getItem('user')) return <Redirect to="/" />;

    const submitForm = () => {
        if (userName === '' || password === '') {
            notify('User name or password are required', 'error');
        } else {
            login({ userName, password })
                .catch(() => notify('Invalid email or password', 'error'));
        }
    };

    const dismissMessage = () => {
        props.setErrorMsg(null);
    };

    const submitting = loading && !!userName && !!password;

    const showForgotPassword = () => {
        setResetting(true);
    };
    const hideForgotPassword = () => {
        setResetting(false);
    };

    const submitResetEmail = () => {
        setSubmittingReset(true);
        const fd = new FormData();
        fd.append('email', resetEmail);
        dataProvider.sendResetEmail(fd).then((t) => {
            if (t.data && t.data.isEmailSent) {
                notify('Instructions to reset your password have been sent to the email address. Please contact us if you require further assistance.');
                setResetting(false);
            } else {
                notify(t.message || 'System failed to send password reset email. Please try again later or contact us if you require further assistance.', 'error');
            }
        }).finally(() => setSubmittingReset(false));
    };

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper variant="outlined" className={classes.paper}>
                <img src={logo} alt="Aetna Logo" style={{ width: '12rem', marginBottom: '1rem' }} />
                <TextField
                    label="Email/User Name"
                    required
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    className="form-input"
                    value={userName}
                    autoFocus
                    onChange={(e) => setUserName(e.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter') { submitForm(); } }}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    className="form-input"
                    type="password"
                    value={password}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter') { submitForm(); } }}
                />
                <Grid container>
                    <Grid item xs>
                        <Button color="primary" onClick={showForgotPassword} className={classes.forgetPassword}>
                            Forgot password?
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={submitting}
                            className={classes.submit}
                            onClick={submitForm}
                        >
                            {submitting && (
                                <CircularProgress
                                    color="secondary"
                                    size={12}
                                    className={classes.buttonProgress}
                                />
                            )} Login
                        </Button>

                    </Grid>
                </Grid>
            </Paper>
            <ResetPasswordDialog
                open={resetting}
                error={error}
                dismissMessage={dismissMessage}
                onClose={hideForgotPassword}
                resetEmail={resetEmail}
                setResetEmail={setResetEmail}
                submitResetEmail={submitResetEmail}
                submittingReset={submittingReset}
            />
        </Container>
    );
};

export default LoginForm;
