import React from 'react';
import {
    Edit, SimpleForm,
    TextInput,
    required,
    Toolbar, SaveButton
} from 'react-admin';
import { Grid } from '@material-ui/core';

const EditToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const AttachmentEdit = (props) => {
    return (
        <Edit title="Edit Attachment" {...props}>
            <SimpleForm
                toolbar={<EditToolbar />}
                redirect="list"
            >
                <Grid container spacing={2} fullWidth>
                    <Grid item xs={12}>
                        <TextInput
                            source="name"
                            label="Title"
                            validate={[required()]}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default AttachmentEdit;
