import {
    MenuItem, Select, Typography, Grid, TextField, FormControl, InputLabel
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import {
    useDataProvider, useNotify
} from 'react-admin';
import authProvider from '../authProvider';
import { TourContainer, TourHeader } from '../Tour/TourWrapper';

export default function ProfileDialog({
    open, onClose
}) {
    const dataProvider = useDataProvider();
    const [profileData, setProfileData] = useState(null);
    const auth = authProvider.getAuth();

    const notify = useNotify();
    useEffect(() => {
        if (open) {
            dataProvider.getProfile()
                .then(({ data }) => {
                    setProfileData(data);
                });
        }
    }, [dataProvider, open]);

    const validateAndSubmit = () => {
        let formError = null;
        if (!profileData.firstName) {
            formError = 'First Name is required';
        } else if (!profileData.lastName) {
            formError = 'Last Name is required';
        } else if (!profileData.email) {
            formError = 'Email is required';
        } else if (!profileData.userName) {
            formError = 'User name is required';
        } else if (!profileData.role) {
            formError = 'Role is required';
        }
        if (formError) {
            notify(formError, 'error');
        } else {
            const fd = { ...profileData, password: null };
            dataProvider.saveProfile(fd).then((d) => {
                if (d.data === true) {
                    notify('Profile has been updated', 'info');
                    onClose();
                } else {
                    notify(d.message || 'Fail to save profile, please try again later.', 'error');
                }
            }).catch(() => {
                notify('Fail to save profile, please try again later.', 'error');
            });
        }
    };

    const tourRef = React.useRef();
    if (!open) return null;

    const forwardShowTour = (s) => tourRef.current.onShowTour(s);
    const tourNames = ['First Name', 'Last Name', 'Username', 'Email Address', 'Group', 'The Segment'];

    const segments = ['Middle Market', 'National Accounts', 'PayFlex', 'Public and Labor'];
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Profile</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                {profileData
                    ? (
                        <TourContainer
                            ref={tourRef}
                            stepNames={tourNames}
                        >
                            <Grid container spacing={2} fullWidth>
                                <Grid item xs={6}>
                                    <TextField
                                        name="firstName"
                                        label={(
                                            <TourHeader tourSelector={tourNames[0]} onShowTour={forwardShowTour}>
                                                <span>First Name</span>
                                            </TourHeader>
                                        )}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={profileData.firstName}
                                        onChange={(e) => {
                                            setProfileData({ ...profileData, firstName: e.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="lastName"
                                        label={(
                                            <TourHeader tourSelector={tourNames[1]} onShowTour={forwardShowTour}>
                                                <span>Last Name</span>
                                            </TourHeader>
                                        )}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={profileData.lastName}
                                        onChange={(e) => {
                                            setProfileData({ ...profileData, lastName: e.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="userName"
                                        label={(
                                            <TourHeader tourSelector={tourNames[2]} onShowTour={forwardShowTour}>
                                                <span>User name</span>
                                            </TourHeader>
                                        )}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={profileData.userName}
                                        onChange={(e) => {
                                            setProfileData({ ...profileData, userName: e.target.value });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="email"
                                        type="email"
                                        label={(
                                            <TourHeader tourSelector={tourNames[1]} onShowTour={forwardShowTour}>
                                                <span>Email</span>
                                            </TourHeader>
                                        )}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        value={profileData.email}
                                        onChange={(e) => {
                                            setProfileData({ ...profileData, email: e.target.value });
                                        }}
                                    />
                                </Grid>
                                {
                                    auth.roles.includes('Admin') && (
                                        <>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <InputLabel>
                                                        <TourHeader tourSelector="Group" onShowTour={forwardShowTour}>
                                                            <span>Group</span>
                                                        </TourHeader>
                                                    </InputLabel>
                                                    <Select
                                                        value={profileData.role}
                                                        variant="outlined"
                                                        label="Group"
                                                        fullWidth
                                                        required
                                                        onChange={(e) => {
                                                            setProfileData({ ...profileData, role: e.target.value });
                                                        }}
                                                    >
                                                        <MenuItem value="Admin">Admin</MenuItem>
                                                        <MenuItem value="User">User</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    <InputLabel>
                                                        <TourHeader tourSelector={tourNames[4]} onShowTour={forwardShowTour}>
                                                            <span>Segments</span>
                                                        </TourHeader>
                                                    </InputLabel>
                                                    <Select
                                                        multiple
                                                        variant="outlined"
                                                        label="Segments"
                                                        fullWidth
                                                        style={{ maxWidth: '430px' }}
                                                        value={(profileData.segment || '').split('|')}
                                                        onChange={(e) => {
                                                            setProfileData({ ...profileData, segment: e.target.value.join('|'), segments: e.target.value });
                                                        }}
                                                    >
                                                        {
                                                            segments.map((s) => (
                                                                <MenuItem key={s} value={s}>{s}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                            </Grid>
                        </TourContainer>
                    )
                    : 'loading...'}
            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    variant="contained"
                    onClick={onClose}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={validateAndSubmit}
                >
                    Save
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
