import React, { useState } from 'react';
import { AppBar, UserMenu } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import NeedHelpDialog from './NeedHelpDialog';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1
    },
    marketSegmentSelect: {
        width: '12rem',
        '& .MuiOutlinedInput-root': {
            background: '#fff'
        }
    }
});

const TheUserMenu = (props) => (
    <UserMenu {...props} />
);

const TheAppBar = (props) => {
    const classes = useStyles();
    const [needHelp, setNeedHelp] = useState(false);
    return (
        <AppBar {...props} userMenu={<TheUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
            <Button onClick={() => setNeedHelp(true)} color="inherit">Need Help <ContactSupportIcon color="inherit" /></Button>
            <NeedHelpDialog open={needHelp} onClose={() => setNeedHelp(false)} />
        </AppBar>
    );
};

export default TheAppBar;
