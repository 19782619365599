const apiUtil = {
    build: (url) => {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        } else {
            return (process.env.REACT_APP_SERVER_URL || '') + (url.startsWith('/') ? url : `/${url}`);
        }
    },
    buildLink: (url) => {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        } else {
            return (process.env.REACT_APP_SERVER_URL || '').replace('/api', '') + (url.startsWith('/') ? url : `/${url}`);
        }
    }
};

export default apiUtil;
