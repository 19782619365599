import {
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField
} from '@material-ui/core';
import React from 'react';

export default function ResetPasswordDialog({
    open, onClose,
    resetEmail, setResetEmail,
    submitResetEmail, submittingReset
}) {
    const onEmailChange = (e) => {
        setResetEmail(e.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the email address associated with your account. If you no longer use the email address associated with your account, please contact customer support.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="reset-email"
                    label="Email Address"
                    type="text"
                    value={resetEmail}
                    onChange={onEmailChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={submittingReset || !resetEmail}
                    onClick={submitResetEmail}
                    color="primary"
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
