import React, { useState, useEffect } from 'react';
import {
    Button, Divider, Box,
    Dialog, DialogActions, DialogTitle, DialogContent, MenuItem, Tooltip,
    Input, FormControl, Chip
} from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useRefresh } from 'react-admin';
import UserSelect from 'components/UserSelect';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

function ShareWith(props) {
    const { record = {}, resource } = props;
    const classes = useStyles();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sharing, setSharing] = useState([]);
    const [users, setUsers] = useState([]);

    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const handleChange = (event) => {
        setSharing(event.target.value);
    };

    const onTransferClick = () => {
        setOpenConfirm(true);
    };

    const onSelectionChange = (v) => {
        setSharing(v);
    };

    const saveChange = () => {
        setLoading(true);
        const userIds = sharing.map((s) => s.id);
        dataProvider.shareWith(resource, { id: record.id, shares: userIds })
            .then(() => {
                refresh();
            })
            .finally(() => {
                setLoading(false);
                setOpenConfirm(false);
            });
    };

    const toggleSelected = (u) => {
        const current = sharing || [];
        if (current.some((c) => c.id === u.id)) {
            setSharing([...current.filter((v) => v.id !== u.id)]);
        } else {
            setSharing([...current, u]);
        }
    };

    useEffect(() => {
        if (openConfirm) {
            dataProvider.getSharing(record.id)
                .then(({ data }) => {
                    setSharing(data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setSharing([]);
        }
    }, [dataProvider, openConfirm, record]);

    return (
        <>
            <Button
                color="primary"
                onClick={onTransferClick}
            >
                <Tooltip title="Share With">
                    <PeopleAltIcon />
                </Tooltip>
            </Button>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title">Share With</DialogTitle>

                    <DialogContent dividers>
                        <Box>
                            <b>Selected:</b>
                        </Box>
                        <Box mb={1}>
                            {
                                sharing.map((s) => <Chip onClick={() => toggleSelected(s)} label={s.userName} />)
                            }
                        </Box>
                        <Divider />
                        <Box mt={1}>
                            <UserSelect selected={sharing} toggleSelected={toggleSelected} />
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirm(false)} disabled={loading}>
                            Close
                        </Button>
                        <Button onClick={() => saveChange()} color="primary" disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
}

export default ShareWith;
