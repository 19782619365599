import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useInput } from 'react-admin';

const stateCodes = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',
    'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH',
    'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

const StateInput = (props) => {
    const {
        input
    } = useInput(props);
    const { className } = props;

    return (

        <FormControl
            variant="outlined"
            className={className || ''}
            fullWidth
            size="small"
            style={{ marginTop: '.5rem' }}
        >
            <InputLabel id="theme-select-outlined-label">
                State Situs
            </InputLabel>
            <Select
                size="small"
                multiple
                fullWidth
                labelId="theme-select-outlined-label"
                id="stateSitusSelect"
                label="State Situs"
                variant="outlined"
                {...input}
                value={input && input.value ? input.value : []}
            >
                {stateCodes.map((s) => <MenuItem key={s} value={s}>{s}</MenuItem>)}

            </Select>
        </FormControl>
    );
};
export default StateInput;
