/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Divider, Button
} from '@material-ui/core';
import {
    TextInput, DateInput, required, BooleanInput, FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import RichTextInputToolbar from 'components/RichTextInputToolbar.json';
import { TourHeader } from 'components/Tour/TourWrapper';
import MediaInput from '../control/MediaInput';
import ResourceDialog from '../../FileBrowser/ResourceDialog';

export default function MainSection({
    medias, setMedias, fundingTypeName,
    forwardShowTour, isEnglish, hasExtraVideo, brandName
}) {
    const [openDialog, setOpenDialog] = useState(false);

    const onClickFileBrowser = () => {
        setOpenDialog(true);
    };

    const onDialogClosed = () => {
        setOpenDialog(false);
    };
    const onFileSelect = (fileInfo) => {
        const e = { target: { value: fileInfo.relativePath } };
        console.log('#ODS# ~ file: MainSection.js ~ line 32 ~ onFileSelect ~ e', e);
        // onFileChange();
        setOpenDialog(false);
    };

    const hereUrl = brandName === 'Aetna International'
        ? 'https://aetna.highspot.com/spots/5daf13218117173881240fcd?list=6479fc50e2d61c096db1df7b&overview=false'
        : 'https://aetna.highspot.com/spots/59de9ee312795819ac81c464?list=5ee7afce81171767a6812f26&overview=false';

    return (
        <Box style={{ width: '100%' }}>
            <TextInput
                label={(
                    <TourHeader
                        tourSelector="Title"
                        label={isEnglish ? 'Microsite Title' : 'Plan Sponsor Name'}
                        onShowTour={forwardShowTour}
                    />
                )}
                source="presentationTitle"
                multiline
                variant="outlined"
                helperText=""
                fullWidth
            />
            {(brandName === 'Aetna International' || (brandName !== 'Aetna International' && fundingTypeName === 'Self Insured')) && isEnglish && (
                <RichTextInput
                    label={(
                        <>
                            <TourHeader
                                tourSelector="Introduction Content"
                                label="Introduction Content"
                                onShowTour={forwardShowTour}
                            />
                            <br />
                            <Button
                                style={{ marginLeft: '.5rem' }}
                                color="primary"
                                variant="contained"
                                onClick={onClickFileBrowser}
                            >
                                Upload File
                            </Button>
                            <div className="ql-toolbar ql-snow" style={{ width: '614px', marginTop: '0.25rem', marginBottom: '-1.5rem' }}>
                                To attach a FILE below using the link
                                <button type="button" className="ql-link" style={{ float: 'inherit', cursor: 'default' }} disabled><svg viewBox="0 0 18 18"> <line className="ql-stroke" x1="7" x2="11" y1="7" y2="11" /> <path className="ql-even ql-stroke" d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z" /> <path className="ql-even ql-stroke" d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z" /> </svg></button>
                                function, please REMEMBER to upload the file with the &quot;Upload File&quot; button found here.
                            </div>
                        </>
                    )}
                    source="introductionContent"
                    style={{ minHeight: '30rem' }}
                    helperText=""
                    validate={[required()]}
                    rows={4}
                    toolbar={RichTextInputToolbar}
                />
            )}

            <Divider style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />
            {/* {
                hasExtraVideo && (
                    <>
                        <BooleanInput
                            label="Enable SundaySky Video"
                            helperText=""
                            source="isExtraVideoOn"
                        />
                        <Typography variant="body2" gutterBottom>
                            <b>CAUTION</b>: SundaySky videos are not available for all plan sponsors.
                            Please contact AetnaResourceMicrosite@AETNA.com before enabling this video to verify that a version is available for your plan sponsor.
                        </Typography>
                        <Divider style={{ marginTop: '.5rem', marginBottom: '.5rem' }} />
                    </>
                )
            } */}
            {isEnglish && (
                <>
                    <Typography variant="body2" gutterBottom style={{ marginBottom: '1rem' }}>
                        Please use the entire YouTube or Vimeo link, including https://. &nbsp;
                        <a target="_blank" color="primary" href={hereUrl}>
                            Click here
                        </a> to view a list of currently available videos which can be used on the Microsites.
                        Please note only videos available externally can be added to the Microsites.
                    </Typography>
                    <MediaInput
                        items={medias}
                        onChange={(v) => setMedias(v)}
                        forwardShowTour={forwardShowTour}
                    />
                </>
            )}
            <BooleanInput
                label="Enable Password"
                helperText=""
                source="passwordEnabled"
            />
            <FormDataConsumer>
                {({ formData }) => {
                    if (formData.passwordEnabled) {
                        return (
                            <TextInput
                                source="password"
                                variant="outlined"
                                label={(
                                    <TourHeader
                                        tourSelector="Password"
                                        label="Password"
                                        onShowTour={forwardShowTour}
                                    />
                                )}
                                helperText=""
                                fullWidth
                            />
                        );
                    }

                    return null;
                }}
            </FormDataConsumer>

            <DateInput
                source="expiry"
                variant="outlined"
                helperText=""
                fullWidth
                label={(
                    <TourHeader
                        tourSelector="Expiry Date"
                        label="Microsite Expiry"
                        onShowTour={forwardShowTour}
                    />
                )}
            />

            {openDialog && (
                <ResourceDialog
                    open={openDialog}
                    onClose={onDialogClosed}
                    onFileSelect={onFileSelect}
                    allowCopyLink
                    brandName=""
                    module=""
                />
            )}
        </Box>
    );
}
