import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import toFullLink from 'components/UrlUtil';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
    root: {
        width: 90
    },
    media: {
        height: 60
    }
});

const AssetImageField = ({ record = {} }) => {
    const classes = useStyles();
    const src = toFullLink(record.imageLink, '/public/asset');
    return (

        <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image={src}
                title={record.displayTitle}
            />
        </Card>
    );
};

export default AssetImageField;
