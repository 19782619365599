import React, { useState, useEffect } from 'react';
import {
    List, Datagrid, TextField,
    Filter, TextInput, DateField, SelectInput, TopToolbar, Button, useDataProvider, useRefresh, useRecordContext
} from 'react-admin';
import {
    makeStyles, Typography, Box, Grid, TextField as MulTextField, MenuItem,
    DialogTitle as MuiDialogTitle, Dialog, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, Button as MulButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import { TimePicker } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import { TextLinkField } from 'components/ListFields';
import toFullLink from 'components/UrlUtil';
import Pagination from 'components/Pagination';
import CopyButton from 'components/ListButtons/Copy';
import EditButton from 'components/ListButtons/Edit';
import StatusButton from 'components/ListButtons/Status';
import DeleteButton from 'components/ListButtons/Delete';
import ChangeOwnerButton from 'components/ListButtons/ChangeOwner';

import authProvider from 'components/authProvider';
import Empty from 'components/Empty';
import { TourContainer, TourHeader } from 'components/Tour/TourWrapper';
import message from 'components/Common/Message';

import DateFnsUtils from '@date-io/date-fns';
import 'antd/dist/antd.css';

const zomeZoneObj = {
    'Mountain Standard Time': 'MST',
    'Pacific Standard Time': 'PST',
    'Central Standard Time': 'CST',
    'Eastern Standard Time': 'EST'
};

const formatTime = (time) => {
    const times = time.split(':');
    let hour = times[0];
    const min = times[1];
    let str = '';
    if (hour > 12) {
        hour -= 12;
        str = 'PM';
    } else if (parseInt(hour, 10) === 0) {
        hour = 12;
        str = 'AM';
    } else if (parseInt(hour, 10) === 12) {
        hour = 12;
        str = 'PM';
    } else {
        str = 'AM';
    }

    return `${parseInt(hour, 10)}:${min}${str}`;
};

const columns = [
    {
        id: 'participantName',
        label: 'NAME',
        minWidth: '120'
    },
    {
        id: 'emailAddress',
        label: 'EMAIL',
        minWidth: '200'
    },
    {
        id: 'language',
        label: 'LANGUAGE',
        minWidth: '120'
    }
];

const useStyles = makeStyles((theme) => ({
    actions: {
        backgroundColor: '#ccc'
    },
    button: {
    },
    header: {
        padding: '15px 32px',
        fontSize: '18px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
    },
    formTitle: {
        fontSize: '16px',
        marginBottom: '20px',
        fontWeight: '600'
    },
    formTitle1: {
        margin: 0,
        marginTop: '-10px',
        position: 'relative',
        fontSize: '14px',
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    footer: {
        margin: 0,
        padding: '20px 32px',
        borderTop: '1px solid rgba(0, 0, 0, 0.23)',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    body: {
        // padding: '1rem 2rem'
        width: 560
    },
    paper: {
        position: 'absolute',
        width: 560,
        // maxHeight: 600,
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5]
    },
    rowItem: {
        marginBottom: '15px',
        '& .quill .ql-container': {
            height: 130,
            position: 'relative'
        },
        '& .ql-snow .ql-tooltip': {
            left: '0 !important'
        }
    },
    timePicker: {
        margin: 0
    },
    middleOr: {
        textAlign: 'center'
    },
    inlineSearchButton: {
        marginLeft: theme.spacing(1)
    },
    cssLabel: {
        color: 'black'
    },
    popover: {
        pointerEvents: 'none'
    },
    popoverPaper: {
        padding: theme.spacing(1)
    },
    container: {
        height: 440,
        maxHeight: 440
    },
    registerTxt: {
        color: '#7d3f98',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    contentHeader: {
        margin: '10px 0px 30px'
    },
    contentHeaderTopic: {
        fontWeight: 'bold'
    },
    tableTitle: {
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    maxCapacity: {
        '&> label': {
            fontSize: '1rem'
        }
    },
    createDialogContent: {
        overflowY: 'hidden'
    }
}));

const CustomDateField = ({ source }) => {
    const record = useRecordContext();
    return record ? <span>{moment(record[source]).format('MM/DD/YYYY')}</span> : null;
};

const CustomTimeField = ({ source }) => {
    const record = useRecordContext();
    return record ? <span>{formatTime(record[source])}</span> : null;
};

const CustomTextField = ({ source }) => {
    const record = useRecordContext();
    return record ? <span>{zomeZoneObj[record[source]]}</span> : null;
};

const CustomRegisterField = ({ source, classes, handlePopoverOpen }) => {
    const record = useRecordContext();

    if (record) {
        const dataList = record[source].filter((item) => item.isDeleted !== true);
        return (
            <span
                className={classes.registerTxt}
                onClick={() => { handlePopoverOpen(record, dataList); }}
            >
                {dataList.length}
            </span>
        );
    }
    return null;
};

CustomTextField.defaultProps = { label: 'Time Zone' };
CustomDateField.defaultProps = { label: 'Date' };
CustomRegisterField.defaultProps = { label: 'Registrations' };

const ListFilter = (props) => {
    const auth = authProvider.getAuth();
    const { filterValues, resource } = props;
    const brandValue = filterValues.brandName || auth.brands[0];
    const aetnaBrand = auth.brands.find((item) => item === 'Aetna');
    const brands = [{ id: aetnaBrand, name: aetnaBrand }];
    const segments = auth.brandSegment.filter((x) => x.key === brandValue && auth.segments.includes(x.value)).map((x) => x.value);
    return (
        <Filter
            {...props}
            variant="outlined"
        >
            <TextInput label="Search" source="q" alwaysOn />
            {
                resource.startsWith('a') && <TextInput label="Staff" source="staff" alwaysOn />
            }
            <SelectInput
                choices={[
                    { id: '2', name: 'Active' },
                    { id: '1', name: 'Cancelled' }
                ]}
                source="status"
                alwaysOn
            />
            <SelectInput
                source="brandName"
                label="Brand"
                // initialValue={auth.brands[0]}
                // choices={auth.brands.map((s) => ({ id: s, name: s }))}
                initialValue={aetnaBrand}
                choices={brands}
                disabled={auth.brands.length === 1}
                alwaysOn
                allowEmpty={false}
            />
            {
                segments.length > 0 && (
                    <SelectInput
                        source="segment"
                        label="Segment"
                        initialValue={segments[0]}
                        choices={segments.map((s) => ({ id: s, name: s }))}
                        disabled={segments.length === 1}
                        alwaysOn
                        allowEmpty={false}
                    />
                )
            }
            {/* {
                brandValue === 'Aetna' && (
                    <SelectInput
                        choices={[
                            { id: 'English', name: 'English' },
                            { id: 'Spanish', name: 'Spanish' }
                        ]}
                        source="language"
                        alwaysOn
                    />
                )
            } */}
        </Filter>
    );
};

function CreateDialog(props) {
    const {
        resource, type = 'create',
        selectRecord, open, onClose
    } = props;
    const refresh = useRefresh();
    const tourRef = React.useRef();
    const dataProvider = useDataProvider();
    const [micrositeList, setMicrositeList] = useState([]);
    const [timezoneList, setTimezoneList] = useState([]);
    const [timeMessage, setTimeMessage] = useState('');
    const [formData, setFormData] = useState({
        micrositeId: '',
        topic: '',
        date: '',
        startTime: '',
        endTime: '',
        timeZone: '',
        maxCapacity: '',
        description: ''
    });

    const classes = useStyles();
    const forwardShowTour = (s) => tourRef.current.onShowTour(s);
    const tourNames = ['Microsite', 'Topic', 'Username', 'Email Address', 'Group', 'The Segment'];

    const returnTitle = () => {
        switch (type) {
            case 'create':
                return 'Create Event';
            case 'copy':
                return 'Copy Event';
            case 'update':
                return 'Update Event';
            default:
                return 'Create Event';
        }
    };

    const returnSaveBtn = () => {
        switch (type) {
            case 'create':
                return 'CREATE';
            case 'copy':
                return 'COPY';
            case 'update':
                return 'UPDATE';
            default:
                return 'CREATE';
        }
    };

    const onFieldChange = (val, name) => {
        setFormData({ ...formData, [name]: val });
        if (name === 'startTime') {
            if (formData.endTime && !moment(val).isBefore(formData.endTime)) {
                setTimeMessage('Please ensure start time is earlier than end time.');
            } else {
                setTimeMessage('');
            }
        } else if (name === 'endTime') {
            if (formData.startTime && !moment(formData.startTime).isBefore(val)) {
                setTimeMessage('Please ensure start time is earlier than end time.');
            } else {
                setTimeMessage('');
            }
        }
    };

    const createNew = () => {
        const data = type === 'update' ? {
            ...formData,
            startTime: moment(formData.startTime).format('HH:mm'),
            endTime: moment(formData.endTime).format('HH:mm'),
            id: selectRecord.id,
            date: moment(formData.date).format('MM/DD/YYYY'),
            status: selectRecord.status,
            deleted: selectRecord.deleted,
            sequence: selectRecord.sequence
        } : {
                ...formData,
                date: moment(formData.date).format('MM/DD/YYYY'),
                startTime: moment(formData.startTime).format('HH:mm'),
                endTime: moment(formData.endTime).format('HH:mm'),
                status: 'Active',
                deleted: false,
                sequence: 0
            };

        let action = null;
        switch (type) {
            case 'create':
                action = dataProvider.createEvent;
                break;
            case 'update':
                action = dataProvider.updateEvent;
                break;
            default:
                action = dataProvider.createEvent;
                break;
        }
        if (type === 'update') {
            if (parseInt(formData.micrositeId, 10) === parseInt(selectRecord.micrositeId, 10) && formData.topic === selectRecord.topic
                && moment(formData.date).format('MM/DD/YYYY') === moment(selectRecord.date).format('MM/DD/YYYY')
                && formData.startTime === selectRecord.startTime && formData.endTime === selectRecord.endTime && formData.timeZone === selectRecord.timeZone
                && formData.description === selectRecord.description && parseInt(formData.maxCapacity, 10) !== parseInt(selectRecord.maxCapacity, 10)) {
                action = dataProvider.updateMaxcapacity;
            }
        }
        action(resource, data).then((res) => {
            if (res.data) {
                if (type === 'create') {
                    message.success('Create event successful.');
                } else if (type === 'update') {
                    message.success('Update event successful.');
                } else if (type === 'copy') {
                    message.success('Copy event successful.');
                }
                onClose();
                refresh();
            }
        });
    };

    const validate = () => {
        if (type === 'update') {
            return !!(parseInt(formData.micrositeId, 10) === parseInt(selectRecord.micrositeId, 10) && formData.topic === selectRecord.topic
                && moment(formData.date).format('MM/DD/YYYY') === moment(selectRecord.date).format('MM/DD/YYYY')
                && formData.startTime === selectRecord.startTime && formData.endTime === selectRecord.endTime && formData.timeZone === selectRecord.timeZone
                && parseInt(formData.maxCapacity, 10) === parseInt(selectRecord.maxCapacity, 10) && formData.description === selectRecord.description)
                || !(formData.micrositeId && formData.topic && formData.date && formData.startTime
                    && formData.endTime && formData.timeZone && formData.maxCapacity && formData.description) || timeMessage;
        }
        return !(formData.micrositeId && formData.topic && formData.date && formData.startTime
            && formData.endTime && formData.timeZone && formData.maxCapacity && formData.description) || timeMessage;
    };

    useEffect(() => {
        if (open) {
            dataProvider
                .getMicrosizteList()
                .then(({ data }) => {
                    const result = data.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        };
                    });
                    setMicrositeList(result);
                });

            setTimezoneList([
                {
                    label: 'PST',
                    value: 'Pacific Standard Time'
                },
                {
                    label: 'MST',
                    value: 'Mountain Standard Time'
                },
                {
                    label: 'CST',
                    value: 'Central Standard Time'
                },
                {
                    label: 'EST',
                    value: 'Eastern Standard Time'
                }
            ]);
        }
        if (open) {
            setTimeMessage('');
            if (type === 'update' || type === 'copy') {
                setFormData({
                    micrositeId: selectRecord.micrositeId,
                    topic: (type === 'copy') ? `${selectRecord.topic} COPY` : selectRecord.topic,
                    date: moment(selectRecord.date).format('YYYY-MM-DD'),
                    startTime: moment(selectRecord.startTime, 'HH:mm'),
                    endTime: moment(selectRecord.endTime, 'HH:mm'),
                    timeZone: selectRecord.timeZone,
                    maxCapacity: selectRecord.maxCapacity,
                    description: selectRecord.description
                });
            } else {
                setFormData({
                    micrositeId: '',
                    topic: '',
                    date: '',
                    startTime: '',
                    endTime: '',
                    timeZone: '',
                    maxCapacity: '',
                    description: ''
                });
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">{returnTitle()}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers className={classes.createDialogContent}>
                <div className={classes.formTitle}>
                    Microsite
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TourContainer
                        ref={tourRef}
                        stepNames={tourNames}
                    >
                        <Grid container spacing={2} className={classes.rowItem}>
                            <Grid item xs={12}>
                                <MulTextField
                                    // label={(
                                    //     <TourHeader tourSelector={tourNames[0]} onShowTour={forwardShowTour}>
                                    //         <span>Microsite</span>
                                    //     </TourHeader>
                                    // )}
                                    label="Microsite"
                                    name="micrositeId"
                                    size="small"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.cssLabel
                                        },
                                        shrink: true
                                    }}
                                    fullWidth
                                    select
                                    value={formData.micrositeId || ''}
                                    onChange={(e) => onFieldChange(e.target.value, 'micrositeId')}
                                    variant="outlined"
                                >
                                    {
                                        micrositeList.map((item) => {
                                            return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>;
                                        })
                                    }
                                </MulTextField>
                            </Grid>
                        </Grid>
                        <div className={classes.formTitle}>
                            Event Details
                        </div>
                        <Grid container spacing={2} className={classes.rowItem}>
                            <Grid item xs={12}>
                                <MulTextField
                                    label="Topic"
                                    // label={(
                                    //     <TourHeader tourSelector={tourNames[0]} onShowTour={forwardShowTour}>
                                    //         <span>Topic</span>
                                    //     </TourHeader>
                                    // )}
                                    name="topic"
                                    size="small"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.cssLabel
                                        },
                                        shrink: true
                                    }}
                                    fullWidth
                                    value={formData.topic || ''}
                                    onChange={(e) => onFieldChange(e.target.value, 'topic')}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.rowItem}>
                            <Grid item xs={12}>
                                <KeyboardDatePicker
                                    // autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Date"
                                    disablePast
                                    format="MM/dd/yyyy"
                                    size="small"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    value={formData.date || null}
                                    onChange={(date) => onFieldChange(date, 'date')}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={classes.rowItem}>
                            <Grid item xs={4}>
                                <div className="form-group">
                                    <div className="label"><span>Start Time</span></div>
                                    <TimePicker
                                        use12Hours
                                        allowClear={false}
                                        className="custom-time-picker"
                                        format="h:mm A"
                                        placeholder=" "
                                        minuteStep={15}
                                        value={formData.startTime}
                                        onChange={(date) => onFieldChange(date, 'startTime')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className="form-group">
                                    <div className="label"><span>End Time</span></div>
                                    <TimePicker
                                        use12Hours
                                        allowClear={false}
                                        className="custom-time-picker"
                                        format="h:mm A"
                                        placeholder=" "
                                        minuteStep={15}
                                        value={formData.endTime}
                                        onChange={(date) => onFieldChange(date, 'endTime')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <MulTextField
                                    label="Time Zone"
                                    name="timeZone"
                                    size="small"
                                    select
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.cssLabel
                                        },
                                        shrink: true
                                    }}
                                    fullWidth
                                    value={formData.timeZone}
                                    onChange={(e) => onFieldChange(e.target.value, 'timeZone')}
                                    variant="outlined"
                                >
                                    {
                                        timezoneList.map((item) => {
                                            return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>;
                                        })
                                    }
                                </MulTextField>
                            </Grid>
                        </Grid>
                        {
                            timeMessage
                                ? (
                                    <Grid container spacing={2} className={classes.rowItem}>
                                        <Grid item xs={8} style={{ padding: '0 8px', color: '#f44336', marginTop: '-10px' }}>
                                            {timeMessage}
                                        </Grid>
                                    </Grid>
                                )
                                : null
                        }
                        <Grid container spacing={2} className={classes.rowItem}>
                            <Grid item xs={4}>
                                <MulTextField
                                    className={classes.maxCapacity}
                                    label="Max Capacity(Optional)"
                                    name="maxCapacity"
                                    type="number"
                                    size="small"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.cssLabel
                                        },
                                        shrink: true
                                    }}
                                    fullWidth
                                    value={formData.maxCapacity || ''}
                                    onChange={(e) => onFieldChange(e.target.value, 'maxCapacity')}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.formTitle1}>
                            Invitation
                        </div>
                        <Grid container spacing={2} className={classes.rowItem}>
                            <Grid item xs={12}>
                                <ReactQuill
                                    theme="snow"
                                    value={formData.description || ''}
                                    onChange={(value) => onFieldChange(value, 'description')}
                                />
                                {/* <MulTextField
                                    label="Invitation"
                                    name="description"
                                    // size="small"
                                    multiline
                                    minRows={6}
                                    maxRows={6}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.cssLabel
                                        },
                                        shrink: true
                                    }}
                                    fullWidth
                                    value={formData.description || ''}
                                    onChange={(e) => onFieldChange(e.target.value, 'description')}
                                    variant="outlined"
                                /> */}
                            </Grid>
                        </Grid>
                    </TourContainer>
                </MuiPickersUtilsProvider>
            </MuiDialogContent>
            <MuiDialogActions style={{ padding: '16px 24px' }}>
                <MulButton
                    variant="contained"
                    onClick={onClose}
                >
                    Cancel
                </MulButton>
                <MulButton
                    variant="contained"
                    color="primary"
                    disabled={validate()}
                    onClick={createNew}
                >
                    {returnSaveBtn()}
                </MulButton>
            </MuiDialogActions>
        </Dialog>
    );
}

export default function PresentationList(props) {
    const { resource, history } = props;
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const [tableList, setTablelist] = useState([]);
    const [selectRecord, setSelectRecord] = useState(null);
    const [selectRow, setSelectRow] = React.useState(null);
    const [openPopover, setOpenPopover] = React.useState(null);
    const auth = authProvider.getAuth();
    const linkFn = (r) => toFullLink(`${r.vanityUrl}`, `/${r.segment}`);
    const classes = useStyles(props);
    const dataProvider = useDataProvider();

    const tourRef = React.useRef();
    const forwardShowTour = (s) => tourRef.current.onShowTour(s);
    const tourNames = resource.startsWith('m')
        ? ['Customer', 'Microsite Name/Link', 'Theme', 'Funding Type', 'Creation Date', 'Modification Date', 'Microsite Status']
        : ['Microsite Owner', 'Customer', 'PS Unique Code', 'Microsite Name/Link', 'Theme', 'Funding Type', 'Creation Date', 'Modification Date', 'Microsite Status'];

    const handleClose = () => {
        setOpen(false);
    };

    const onCreate = () => {
        setType('create');
        setSelectRecord(null);
        setOpen(true);
    };

    const onEdit = (url, record) => {
        setType('update');
        setSelectRecord(record);
        setOpen(true);
    };

    const onCopy = (record) => {
        setType('copy');
        setSelectRecord(record);
        setOpen(true);
    };

    const handlePopoverOpen = (record, eventParticipantList) => {
        if (eventParticipantList && eventParticipantList.length) {
            setTablelist(eventParticipantList);
            setSelectRow(record);
            setOpenPopover(true);
        }
    };

    const handlePopoverClose = () => {
        setOpenPopover(false);
    };

    const onChangeStatus = (url, data) => {
        return dataProvider.enableEvent(url, data);
    };

    const ListActions = () => (
        <TopToolbar>
            <Button label="Create" onClick={onCreate}>
                <AddIcon />
            </Button>
        </TopToolbar>
    );

    return (
        <TourContainer
            ref={tourRef}
            stepNames={tourNames}
        >
            <Typography variant="h6" gutterBottom>
                Welcome to My Events Admin
            </Typography>
            <Typography variant="body1" gutterBottom style={{ marginBottom: '30px' }}>
                This page allows you to manage, create and edit (including changes/cancellations) events
                and post them to your designated microsite for your users to register to attend.
            </Typography>
            <Typography variant="body1">
                To automatically send meeting details to users when they register for your meeting, do the following:
            </Typography>
            <Typography variant="body1">
                1. Create a meeting invite in your video conference service of choice (WebEx/Zoom/MS Teams or other service)
            </Typography>
            <Typography variant="body1">
                2. Copy the meeting details, including the link
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '20px' }}>
                3. Select Create located on this page and paste the meeting details generated from your video conference
                service into the “Invitation” text box in the dialog that appears
            </Typography>
            <List
                {...props}
                title={resource.startsWith('m') ? 'My Event List' : 'All Event List'}
                filters={<ListFilter />}
                bulkActionButtons={false}
                classes={{ actions: classes.actions }}
                variant="outlined"
                pagination={<Pagination />}
                perPage={50}
                empty={<Empty label="Microsite" />}
                filterDefaultValues={{ segment: auth.segments[0] }}
                actions={<ListActions />}
            >
                <Datagrid>
                    <TextField
                        source="micrositeModel.clientName"
                        label="Customer"
                    // label={(
                    //     <Box style={{ width: '5.5rem' }}>
                    //         <TourHeader tourSelector="Customer" onShowTour={forwardShowTour}>
                    //             <span>Customer</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    <TextLinkField
                        text="name"
                        source="micrositeModel"
                        name="vanityUrl"
                        href={linkFn}
                        label="Microsite"
                    // label={(
                    //     <Box style={{ width: '5.5rem' }}>
                    //         <TourHeader tourSelector="Microsite Name/Link" onShowTour={forwardShowTour}>
                    //             <span>Microsite</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    <TextField
                        source="topic"
                        label="Topic"
                    // label={(
                    //     <Box style={{ width: '5.5rem' }}>
                    //         <TourHeader tourSelector="Topic" onShowTour={forwardShowTour}>
                    //             <span>Topic</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    {/* <DateField
                        source="date"
                        label={(
                            <Box style={{ width: '5.5rem' }}>
                                <TourHeader tourSelector="Date" onShowTour={forwardShowTour}>
                                    <span>Date</span>
                                </TourHeader>
                            </Box>
                        )}
                    /> */}
                    <CustomDateField
                        source="date"
                        label="Date"
                    // label={(
                    //     <Box style={{ width: '5.5rem' }}>
                    //         <TourHeader tourSelector="Date" onShowTour={forwardShowTour}>
                    //             <span>Date</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    <CustomTimeField
                        source="startTime"
                        label="Start Time"
                    // label={(
                    //     <Box style={{ width: '6.5rem' }}>
                    //         <TourHeader tourSelector="Start Time" onShowTour={forwardShowTour}>
                    //             <span>Start Time</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    <CustomTimeField
                        source="endTime"
                        label="End Time"
                    // label={(
                    //     <Box style={{ width: '6.5rem' }}>
                    //         <TourHeader tourSelector="End Time" onShowTour={forwardShowTour}>
                    //             <span>End Time</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    {/* <TextField
                        source="timeZone"
                        label={(
                            <Box style={{ width: '6.5rem' }}>
                                <TourHeader tourSelector="Time Zone" onShowTour={forwardShowTour}>
                                    <span>Time Zone</span>
                                </TourHeader>
                            </Box>
                        )}
                    /> */}
                    <CustomTextField
                        source="timeZone"
                        label="Time Zone"
                    // label={(
                    //     <Box style={{ width: '6.5rem' }}>
                    //         <TourHeader tourSelector="Date" onShowTour={forwardShowTour}>
                    //             <span>Time Zone</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    <TextField
                        source="maxCapacity"
                        label="Max Count"
                    // label={(
                    //     <Box style={{ width: '6.5rem' }}>
                    //         <TourHeader tourSelector="Max Count" onShowTour={forwardShowTour}>
                    //             <span>Max Count</span>
                    //         </TourHeader>
                    //     </Box>
                    // )}
                    />
                    {/* <TextField
                        source="eventParticipantList.length"
                        label={(
                            <Box style={{ width: '7.5rem' }}>
                                <TourHeader tourSelector="Registrations" onShowTour={forwardShowTour}>
                                    <span>Registrations</span>
                                </TourHeader>
                            </Box>
                        )}
                    /> */}
                    <CustomRegisterField
                        source="eventParticipantList"
                        label="Registrations"
                        // label={(
                        //     <Box style={{ width: '7.5rem' }}>
                        //         <TourHeader tourSelector="Registrations" onShowTour={forwardShowTour}>
                        //             <span>Registrations</span>
                        //         </TourHeader>
                        //     </Box>
                        // )}
                        classes={classes}
                        handlePopoverOpen={handlePopoverOpen}
                    />
                    <EditButton resource={resource} callback={onEdit} />
                    <CopyButton history={history} isCustom callback={onCopy} />
                    <StatusButton isCustom callback={onChangeStatus} />
                    <DeleteButton
                        isDeleteInterface
                        title="Are you sure you want to delete this event?"
                        cancelText="Cancel"
                        okText="Yes, Delete"
                        callback={() => { message.success('Delete event successful.'); }}
                    />
                    {resource.startsWith('a') && (<ChangeOwnerButton />)}
                </Datagrid>
            </List>
            <CreateDialog resource={resource} type={type} selectRecord={selectRecord} open={open} onClose={handleClose} />
            <Dialog
                open={openPopover}
                onClose={handlePopoverClose}
                maxWidth="sm"
                fullWidth
            >
                <MuiDialogTitle disableTypography>
                    <Typography variant="h6">Registrations</Typography>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    <div>
                        <div className={classes.contentHeader}>
                            <div className={classes.contentHeaderTopic}>{selectRow ? selectRow.topic : ''}</div>
                            <div>
                                {selectRow ? `${moment(selectRow.date).format('MMM DD, YYYY')} 
                                | ${formatTime(selectRow.startTime)} - ${formatTime(selectRow.endTime)} ${zomeZoneObj[selectRow.timeZone]}` : ''}
                            </div>
                        </div>
                        <div className={classes.tableTitle}>
                            {`Registered: ${tableList.length}`}
                        </div>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tableList.map((row, idx) => {
                                            return (
                                                <TableRow>
                                                    <TableCell>{row.participantName}</TableCell>
                                                    <TableCell>{row.emailAddress}</TableCell>
                                                    <TableCell>{row.language}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </MuiDialogContent>
                <MuiDialogActions style={{ padding: '16px 24px' }}>
                    <MulButton
                        variant="contained"
                        color="primary"
                        onClick={handlePopoverClose}
                    >
                        Close
                    </MulButton>
                </MuiDialogActions>
            </Dialog>
        </TourContainer>
    );
}
