import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useDataProvider, useRefresh } from 'react-admin';
import Switch from '@material-ui/core/Switch';
import {
    Button,
    Dialog, DialogActions, DialogTitle
} from '@material-ui/core';

function StatusButton(props) {
    const {
        source = 'status', record = {}, resource, isCustom = false, callback
    } = props;
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const activated = record[source] === 'Active';
    const [openConfirm, setOpenConfirm] = useState(false);
    const [changing, setChanging] = useState(false);

    const onChange = () => {
        if (isCustom) {
            setChanging(true);
            callback(resource, { id: record.id, status: activated ? 'Cancelled' : 'Active' })
                .then(() => refresh())
                .finally(() => {
                    setChanging(false);
                });
            return;
        }
        setOpenConfirm(true);
    };
    const closeConfirm = (confirmed) => {
        if (confirmed === true) {
            setChanging(true);
            dataProvider.changeStatus(resource, { id: record.id, statusId: activated ? 1 : 2 })
                .then(() => refresh())
                .finally(() => {
                    setChanging(false);
                    setOpenConfirm(false);
                });
        } else {
            setOpenConfirm(false);
        }
    };

    if (record.expired || record[source] === 'Test') {
        return null;
    }

    return (
        <>
            <Tooltip placement="bottom" title={activated ? 'Set as Inactive' : record.draftFromId ? 'Release Draft Version' : 'Set as Active'}>
                <Switch
                    checked={activated}
                    onChange={onChange}
                    disabled={changing}
                />
            </Tooltip>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={closeConfirm}
                >
                    <DialogTitle id="alert-dialog-title">
                        {
                            activated
                                ? 'Are you sure you want to change this as Inactive?'
                                : (
                                    record.draftFromId
                                        ? 'You are about to replace your current LIVE site with this draft version.'
                                        + 'These changes, once released cannot be undone. Are you sure you want to proceed?'
                                        : 'Are you sure you want to change this as Active?'
                                )
                        }
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={closeConfirm} disabled={changing}>
                            No
                        </Button>
                        <Button onClick={() => closeConfirm(true)} color="primary" disabled={changing}>
                            {changing ? 'Saving...' : 'Yes'}
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
}

export default StatusButton;
