/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton } from '@material-ui/core';
import {
    BooleanInput,
    ArrayInput, SimpleFormIterator, TextInput
} from 'react-admin';
import {
    RemoveCircle as RemoveCircleIcon,
    AddCircle as AddCircleIcon
} from '@material-ui/icons';
import RichTextInput from 'ra-input-rich-text';
import RichTextInputToolbar from 'components/RichTextInputToolbar.json';
// import FindADoctorInput from '../FindADoctorInput';
import { TourHeader } from 'components/Tour/TourWrapper';

const useStyles = makeStyles(() => ({
    arrayRoot: {
        marginTop: '0',
        '& li': {
            '& p.MuiTypography-root:first-child': {
                width: '1rem'
            }
        },
        '& li:not(:first-child)': {
            '& .tour-icon': {
                display: 'none'
            }
        }
    },
    arrayInput: {
        '& .MuiInputBase-root': {
            background: '#fff'
        }
    }
}));

export default function FindADoctor({
    forwardShowTour, enableContent, defaultButton, disableTour
}) {
    const classes = useStyles();
    return (
        <Box style={{ width: '100%' }}>
            <BooleanInput
                label={disableTour
                    ? 'Enable Button'
                    : (
                        <TourHeader
                            tourSelector="Enable Find A Doctor"
                            label="Enable Find a Doctor Button"
                            onShowTour={forwardShowTour}
                        />
                    )}
                source="findAdoctorButtonEnabled"
                helperText=""
            />
            <ArrayInput
                source="doctorButtons"
                label=""
                style={{ marginTop: '0' }}
            >
                <SimpleFormIterator
                    removeButton={
                        (
                            <IconButton aria-label="delete">
                                <RemoveCircleIcon color="primary" fontSize="small" />
                            </IconButton>
                        )
                    }
                    addButton={
                        (
                            <IconButton aria-label="add">
                                <AddCircleIcon color="primary" fontSize="small" />
                            </IconButton>
                        )
                    }
                    className={classes.arrayRoot}
                >
                    <TextInput
                        source="link"
                        initialValue={defaultButton.link}
                        label={disableTour
                            ? 'Link'
                            : (
                                <TourHeader tourSelector="Find A Doctor Button Link" onShowTour={forwardShowTour}>
                                    <span>Link</span>
                                </TourHeader>
                            )}
                        helperText=""
                        disabled={!enableContent}
                        fullWidth
                        className={classes.arrayInput}
                    />
                    <TextInput
                        label={disableTour
                            ? 'Button Label'
                            : (
                                <TourHeader tourSelector="Find A Doctor Button Label" onShowTour={forwardShowTour}>
                                    <span>Button Label</span>
                                </TourHeader>
                            )}
                        source="label"
                        helperText=""
                        initialValue={defaultButton.label}
                        disabled={!enableContent}
                        fullWidth
                        className={classes.arrayInput}
                    />
                    {enableContent && (
                        <RichTextInput
                            label={disableTour
                                ? 'Content'
                                : (
                                    <TourHeader tourSelector="Find A Doctor Content" onShowTour={forwardShowTour}>
                                        <span>Content</span>
                                    </TourHeader>
                                )}
                            source="content"
                            style={{ minHeight: '30rem' }}
                            toolbar={RichTextInputToolbar}
                            helperText="NOTE: To ensure the accuracy of this tool, please make sure your employer's plan name matches the Aetna plan name."
                        />
                    )}
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    );
}
