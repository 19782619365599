/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
    Box, Divider, Grid, Typography
} from '@material-ui/core';
import {
    TextInput, BooleanInput, FormDataConsumer, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import RichTextInputToolbar from 'components/RichTextInputToolbar.json';
import { makeStyles } from '@material-ui/core/styles';
import { TourHeader } from 'components/Tour/TourWrapper';
import NavigationPayFlex from './NavigationPayFlex';

const useStyles = makeStyles(() => ({
    div: {
        marginBottom: '.5rem',
        marginTop: '.5rem'
    }
}));
export default function Navigation({
    forwardShowTour, brandName, linkOptions, language, timezoneOptions
}) {
    const classes = useStyles();
    const formatPhone = (v) => {
        const num = v.replace(/\D/g, '');
        if (!num) return '';
        return `(${num.substring(0, 3)}) ${num.substring(3, 6)}-${num.substring(6, 10)}`;
    };
    const formatTime = (v) => {
        let num = v.replace(/\D/g, '');
        if (!num) return '';
        if (num.length > 4) {
            num = num.substring(0, 4);
        }

        switch (num.length) {
            case 4:
                return `${num.substring(0, 2)}:${num.substring(2, 4)}`;
            case 3:
                return `${num.substring(0, 1)}:${num.substring(1, 3)}`;
            case 2:
                return num < 13 ? `${num}:` : `${num.substring(0, 1)}:${num.substring(1, 2)}`;
            default:
                return num;
        }
    };
    const linkCodes = linkOptions?.map((o) => o.code);
    const docLink = linkOptions?.find((o) => o.code === 'Doctor');

    switch (brandName) {
        case 'PayFlex':
            return <NavigationPayFlex />;
        default:

            if (!linkOptions) return null;

            return (
                <Box style={{ width: '100%' }}>
                    <Box mb={2}>
                        Navigation links to appear at the top of the site. Content tiles to appear in the middle.
                    </Box>
                    <Divider classnames={classes.div} />
                    <Grid container spacing={1}>
                        {linkCodes.includes('IdCard') && (
                            <Grid item xs={6}>
                                <BooleanInput
                                    label={(
                                        <TourHeader tourSelector="ID Card" onShowTour={forwardShowTour}>
                                            <span>{linkOptions.find((o) => o.code === 'IdCard').name}</span>
                                        </TourHeader>
                                    )}
                                    helperText=""
                                    source="topLinks.IdCard.enabled"
                                />
                            </Grid>
                        )}
                        {linkCodes.includes('PayFlex') && language === 'English' && (
                            <Grid item xs={6}>
                                <BooleanInput
                                    label={(
                                        <TourHeader tourSelector="PayFlex® HSA/FSA" onShowTour={forwardShowTour}>
                                            <span>PayFlex HSA/FSA</span>
                                        </TourHeader>
                                    )}
                                    helperText=""
                                    source="topLinks.PayFlex.enabled"
                                />
                            </Grid>
                        )}
                        {linkCodes.includes('HealthCare') && (
                            <Grid item xs={6}>
                                <BooleanInput
                                    label={(
                                        <TourHeader tourSelector="Health Care 101" onShowTour={forwardShowTour}>
                                            <span>{linkOptions.find((o) => o.code === 'HealthCare').name}</span>
                                        </TourHeader>
                                    )}
                                    helperText=""
                                    source="topLinks.HealthCare.enabled"
                                />
                            </Grid>
                        )}
                        {linkCodes.includes('Login') && (
                            <Grid item xs={6}>
                                <BooleanInput
                                    label={(
                                        <TourHeader tourSelector="Log In" onShowTour={forwardShowTour}>
                                            <span>{linkOptions.find((o) => o.code === 'Login').name}</span>
                                        </TourHeader>
                                    )}
                                    helperText=""
                                    source="topLinks.Login.enabled"
                                />
                            </Grid>
                        )}
                    </Grid>

                    {linkCodes.includes('Contact') && (
                        <>
                            <Divider classnames={classes.div} />
                            <BooleanInput
                                label={(
                                    <TourHeader tourSelector="Contact Popup" onShowTour={forwardShowTour}>
                                        <span>{linkOptions.find((o) => o.code === 'Contact').name}</span>
                                    </TourHeader>
                                )}
                                helperText=""
                                source="topLinks.Contact.enabled"
                            />

                            <TextInput
                                label={(
                                    <TourHeader tourSelector="Contact Phone" onShowTour={forwardShowTour}>
                                        <span>Phone</span>
                                    </TourHeader>
                                )}
                                source="topLinks.Contact.phone"
                                variant="outlined"
                                parse={formatPhone}
                                helperText=""
                                fullWidth
                            />
                            <BooleanInput label="24x7" helperText="" source="topLinks.Contact.x24x7" />
                            <FormDataConsumer>
                                {({ formData: fd }) => {
                                    if (fd && fd.topLinks && fd.topLinks.Contact && fd.topLinks.Contact.x24x7) return null;

                                    return (
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <TextInput
                                                    label={(
                                                        <TourHeader tourSelector="Contact Start Time" onShowTour={forwardShowTour}>
                                                            <span>AM</span>
                                                        </TourHeader>
                                                    )}
                                                    source="topLinks.Contact.begin"
                                                    variant="outlined"
                                                    parse={formatTime}
                                                    helperText=""
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextInput
                                                    label={(
                                                        <TourHeader tourSelector="Contact End Time" onShowTour={forwardShowTour}>
                                                            <span>PM</span>
                                                        </TourHeader>
                                                    )}
                                                    source="topLinks.Contact.end"
                                                    variant="outlined"
                                                    parse={formatTime}
                                                    helperText=""
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                {
                                                    timezoneOptions && timezoneOptions.length > 0 && (
                                                        <SelectInput
                                                            source="topLinks.Contact.timezone"
                                                            id="contact-timezone-select"
                                                            label="Timezone"
                                                            variant="outlined"
                                                            fullWidth
                                                            choices={timezoneOptions}
                                                            helperText=""
                                                        />
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    );
                                }}
                            </FormDataConsumer>
                        </>
                    )}
                    {linkCodes.includes('Doctor') && (
                        <>
                            <Divider classnames={classes.div} />
                            <BooleanInput
                                label={(
                                    <TourHeader tourSelector="Find A Doctor Link" onShowTour={forwardShowTour}>
                                        <span>{linkOptions.find((o) => o.code === 'Doctor').name}</span>
                                    </TourHeader>
                                )}
                                helperText=""
                                source="topLinks.Doctor.enabled"
                            />
                            <Typography variant="body2" gutterBottom>
                                NOTE: To ensure the accuracy of this tool, please make sure your employer&apos;s plan name matches the Aetna plan name.
                            </Typography>
                            {
                                docLink.hasContent && (
                                    <RichTextInput
                                        label={(
                                            <TourHeader tourSelector="Find A Doctor Popup" onShowTour={forwardShowTour}>
                                                <span>Find a Doctor Popup Content</span>
                                            </TourHeader>
                                        )}
                                        source="topLinks.Doctor.content"
                                        defaultValue={docLink.content || ''}
                                        style={{ minHeight: '30rem' }}
                                        rows={4}
                                        toolbar={RichTextInputToolbar}
                                    />
                                )
                            }
                        </>
                    )}
                </Box>
            );
    }
}
