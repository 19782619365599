/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
    List, Datagrid, TextField, EditButton,
    Filter, TextInput, SelectInput, FunctionField
} from 'react-admin';
import DeleteButton from 'components/ListButtons/Delete';
import toFullLink from 'components/UrlUtil';
import Empty from 'components/Empty';
import PreviewButton from 'components/ListButtons/Preview';

import authProvider from 'components/authProvider';
import ShareWithButton from './ShareWith';

const ListFilter = (props) => {
    const auth = authProvider.getAuth();
    const { filterValues } = props;
    const brandValue = filterValues.brandName || auth.brands[0];
    const segments = auth.brandSegment.filter((x) => x.key === brandValue && auth.segments.includes(x.value)).map((x) => x.value);
    return (
        <Filter
            {...props}
            variant="outlined"
        >
            <TextInput label="Name" source="q" alwaysOn />
            <SelectInput
                source="brandName"
                label="Brand"
                initialValue={auth.brands[0]}
                choices={auth.brands.map((s) => ({ id: s, name: s }))}
                disabled={auth.brands.length === 1}
                alwaysOn
                allowEmpty={false}
            />
            {
                segments.length > 0 && (
                    <SelectInput
                        source="segment"
                        label="Segment"
                        initialValue={segments[0]}
                        choices={segments.map((s) => ({ id: s, name: s }))}
                        disabled={segments.length === 1}
                        alwaysOn
                        allowEmpty={false}
                    />
                )
            }
        </Filter>
    );
};

const SelfEditButton = (props) => {
    return <EditButton {...props} />;
};
const SelfDeleteButton = (props) => {
    return <DeleteButton {...props} />;
};
const SelfShareButton = (props) => {
    const { record } = props;
    return record.sharedBy
        ? null
        : <ShareWithButton {...props} />;
};

export default function AttachmentList(props) {
    const auth = authProvider.getAuth();
    return (
        <List
            {...props}
            exporter={false}
            filters={<ListFilter segments={auth.segments} />}
            bulkActionButtons={false}
            empty={<Empty label="Attachment" create={false} />}
            filterDefaultValues={{ brandName: auth.brands[0], segment: auth.segments[0] }}
        >
            <Datagrid>
                <TextField
                    source="name"
                    label="Title"
                />
                <TextField
                    source="segment"
                    label="Market Segment"
                />
                <TextField
                    source="fundingType"
                />
                <FunctionField
                    label="Created On"
                    render={(record) => {
                        const dt = new Date(record.createdOn);
                        return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
                    }}
                />
                <TextField
                    source="sharedBy"
                />

                <SelfEditButton />
                <SelfShareButton />
                <PreviewButton buildLink={(r) => toFullLink(`/${r.linkToken || ''}`, '/api/attachment/pnp', true)} />
                <SelfDeleteButton />

            </Datagrid>
        </List>

    );
}
