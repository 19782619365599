import React, { useState, Fragment } from 'react';
import {
    List, Datagrid, TextField, TopToolbar, Button,
    Filter, TextInput, SelectInput, FunctionField, useRefresh, useListContext, useRecordContext, useDataProvider
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import DeleteButton from 'components/ListButtons/Delete';
import message from 'components/Common/Message';
import EditButton from 'components/ListButtons/Edit';
import authProvider from 'components/authProvider';
import MessageEditor from './MessageEditor';

export default function MessageManagement(props) {
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const { resource } = props;
    const [open, setOpen] = useState(false);
    const [editingItem, setEditingItem] = useState({});
    const [type, setType] = useState('');

    const onEdit = (url, record) => {
        const temp = JSON.parse(JSON.stringify(record));
        setEditingItem(temp);
        setOpen(true);
        setType('update');
    };

    const onCreate = () => {
        setEditingItem({
            content: '', startTime: null, endTime: null, lobXUserGroups: [], isHeader: true
        });
        setOpen(true);
        setType('create');
    };

    const saveMessage = (bannerMessage) => {
        dataProvider.updateMessage(resource, bannerMessage).then((res) => {
            if (res.data) {
                if (type === 'create') {
                    message.success('Create message successful.');
                } else if (type === 'update') {
                    message.success('Update message successful.');
                }
                setOpen(false);
                refresh();
            }
        });
    };

    const CustomTextField = ({ source }) => {
        const record = useRecordContext();
        return record ? <div dangerouslySetInnerHTML={{ __html: record[source] }} /> : null;
    };

    const ListActions = () => (
        <TopToolbar>
            <Button label="Create" onClick={onCreate}>
                <AddIcon />
            </Button>
        </TopToolbar>
    );

    const Empty = ({ label, create = true }) => {
        const { basePath } = useListContext();
        return (
            <Box textAlign="center" m={1}>
                <Typography variant="h4" paragraph>
                    No {label} yet
                </Typography>
                <Button label="Create" onClick={onCreate}>
                    <AddIcon />
                </Button>
            </Box>
        );
    };

    return (
        <>
            <List
                {...props}
                exporter={false}
                bulkActionButtons={false}
                empty={<Empty label="Message" create />}
                actions={<ListActions />}
            >
                <Datagrid>
                    <CustomTextField
                        source="content"
                        label="Content"
                    />
                    <TextField
                        source="startTime"
                        label="Start Date"
                    />
                    <TextField
                        source="endTime"
                        labal="End Date"
                    />
                    <EditButton resource={resource} callback={onEdit} />
                    <DeleteButton
                        isDeleteInterface
                        title="Are you sure you want to delete this message?"
                        cancelText="Cancel"
                        okText="Yes, Delete"
                        callback={() => { message.success('Delete message successful.'); }}
                    />
                </Datagrid>
            </List>
            <MessageEditor
                open={open}
                saving={false}
                onClose={() => { setOpen(false); setEditingItem(null); }}
                data={editingItem}
                onSave={saveMessage}
            />
        </>
    );
}
