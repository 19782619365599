import authProvider from './authProvider';

const toFullLink = (link, segments = '/', addUserToken = false) => {
    const auth = addUserToken
        ? `?Authorization=${authProvider.getAuth().token}`
        : '';
    const serverUrl = process.env.REACT_APP_SERVER_URL.startsWith('/')
        ? window.location.origin + process.env.REACT_APP_SERVER_URL
        : process.env.REACT_APP_SERVER_URL;

    return link.toLowerCase().startsWith('http')
        ? link
        : `${new URL(serverUrl).origin}${segments}${(segments.endsWith('/') || link.startsWith('/') ? '' : '/')}${link}${auth}`;
};

export default toFullLink;
