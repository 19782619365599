import React from 'react';

const BASE_URL = process.env.REACT_APP_SERVER_URL;

export default function CoverSelect({
    covers, selected, onSelect
}) {
    return (
        <div style={{ display: 'flex', overflowY: 'auto' }}>
            {
                covers.map((c) => {
                    const imgSrc = `${BASE_URL.replace('/api', '')}${c.value}`;
                    return (
                        <div
                            key={c.id}
                            style={{
                                width: '31.25%',
                                // height: '184px',
                                height: '120px',
                                flex: 'none',
                                cursor: 'pointer',
                                border: (c.id === selected ? 'solid 5px #0288d1' : 'solid 5px #fff'),
                                padding: '2px'
                            }}
                            onClick={() => onSelect(c.id)}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    display: 'block',
                                    overflow: 'hidden',
                                    position: 'relative'
                                }}
                            >
                                <img
                                    alt=""
                                    src={imgSrc}
                                    style={{
                                        height: '100%',
                                        position: 'relative',
                                        left: '50%',
                                        transform: 'translateX(-50%)'
                                    }}
                                />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}
