import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import ImageIcon from '@material-ui/icons/Image';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EditIcon from '@material-ui/icons/Edit';
import ApiUtil from 'components/apiUtil';
import AddLinkIcon from '@material-ui/icons/Link';
import { useNotify } from 'react-admin';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import RenameDialog from './RenameDialog';
import authProvider from '../authProvider';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'type', numeric: false, label: 'Type' },
    {
        id: 'name', numeric: false, label: 'Name', sortable: true
    },
    { id: 'operation', label: '' }
];

function EnhancedTableHead({
    classes, order, orderBy, onRequestSort
}) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable
                            ? (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : 'asc'}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            )
                            : (headCell.label)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        minHeight: '10rem',
        marginBottom: theme.spacing(2)
    },
    container: {
        maxHeight: 440,
        minHeight: 100
    },
    table: {
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

export default function FilesView({
    fileInfos,
    onFileSelect,
    directory, openFolder,
    onFileDelete, module, staticFolders, allowCopyLink
}) {
    const rows = fileInfos || [];
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [deleting, setDeleting] = React.useState(null);
    const [renaming, setRenaming] = React.useState(null);
    const notify = useNotify();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getIcon = (f) => {
        if (f.type === 'folder') return <FolderIcon color="primary" />;
        if (f.name.match(/.(jpg|jpeg|png|gif)$/i)) return <ImageIcon color="primary" />;
        if (f.name.match(/.(pdf)$/i)) return <PictureAsPdfIcon color="primary" />;
        return <DescriptionIcon color="primary" />;
    };

    const openItem = (f) => {
        if (f.type === 'folder') {
            const folder = directory
                ? `${directory}/${f.name}`
                : f.name;
            openFolder(folder);
        } else {
            // select item
            onFileSelect(f);
        }
    };

    const submitDelete = () => {
        onFileDelete(deleting);
        setDeleting(null);
    };

    const onRenameClose = (newName) => {
        if (typeof newName === 'string') {
            renaming.name = newName;
            const segments = renaming.relativePath.split('/');
            segments[segments.length - 1] = newName;
            renaming.relativePath = segments.join('/');
        }

        setRenaming(null);
    };

    const copyLink = (f) => {
        const link = `${window.location.origin}${f.relativeLink}`;
        copyToClipboard(link).then(() => {
            notify('Link has been copied.', 'success');
            onFileSelect(f);
        }).catch((err) => {
            console.log('#ODS# ~ file: FilesView.js ~ line 198 ~ copyToClipboard ~ err', err);
        });
    };

    const copyToClipboard = (textToCopy) => {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            const textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                // eslint-disable-next-line no-unused-expressions
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    };

    const download = (f) => {
        const req = new XMLHttpRequest();
        const auth = authProvider.getAuth();
        const { token } = auth;
        req.open('GET', `${process.env.REACT_APP_SERVER_URL}/explorer/download/${module || 'u'}/${encodeURIComponent(f.relativePath)}`, true);
        req.setRequestHeader('Authorization', `Bearer ${token}`);
        req.responseType = 'blob';
        req.onload = function () {
            const blob = req.response;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = f.name;
            link.click();
        };

        req.send();
    };

    const buildButtons = (row) => {
        if (directory === 'Image Library') {
            if (row.thumb) {
                const img = ApiUtil.buildLink(row.thumb);
                return (
                    <>
                        <Tooltip title="Click to open" placement="left">
                            <Link href={img} target="_blank">
                                <Avatar variant="square" alt={row.name} src={img} className={classes.large} />
                            </Link>
                        </Tooltip>
                    </>
                );
            }
        }

        return (
            <>
                {
                    allowCopyLink && row.type === 'file' && (
                        <Tooltip title="Click to copy link">
                            <IconButton
                                style={{ padding: '3px' }}
                                onClick={() => copyLink(row)}
                            >
                                <AddLinkIcon />
                            </IconButton>
                        </Tooltip>

                    )
                }
                {
                    row.type !== 'folder' && (
                        <>
                            <Tooltip title="Download">
                                <IconButton
                                    style={{ padding: '3px' }}
                                    onClick={() => download(row)}
                                >
                                    <CloudDownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                }
                <Tooltip title="Rename">
                    <IconButton
                        style={{ padding: '3px' }}
                        onClick={() => setRenaming(row)}
                    >
                        <EditIcon />
                    </IconButton>

                </Tooltip>
                <Tooltip title="Erase">
                    <IconButton
                        style={{ padding: '3px' }}
                        onClick={() => setDeleting(row)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table
                        className={classes.table}
                        aria-labelledby="files"
                        stickyHeader
                        size="small"
                        aria-label="files"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {
                                staticFolders.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            onDoubleClick={() => openItem(row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            style={{ cursor: 'pointer' }}
                                            key={row.name}
                                        >
                                            <TableCell component="th" id={row} scope="row" style={{ width: '40px' }}>
                                                <Tooltip title="Double click to open/select">
                                                    {getIcon(row)}
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ userSelect: 'none', color: '#7d3f98' }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    row.type !== 'folder' && (
                                                        <>
                                                            <Tooltip title="Download">
                                                                <IconButton
                                                                    style={{ padding: '3px' }}
                                                                    onClick={() => download(row)}
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onDoubleClick={() => openItem(row)}
                                            role="checkbox"
                                            tabIndex={-1}
                                            style={{ cursor: 'pointer' }}
                                            key={row.name}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" style={{ width: '40px' }}>
                                                <Tooltip title="Double click to open/select">
                                                    {getIcon(row)}
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{ userSelect: 'none' }}>
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right" style={{ minWidth: '8rem' }}>
                                                {buildButtons(row)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {deleting && (
                <ConfirmDeleteDialog
                    open={!!deleting}
                    onClose={() => setDeleting(null)}
                    deleting={deleting}
                    onYes={submitDelete}
                />
            )}
            {
                renaming && (
                    <RenameDialog
                        open={!!renaming}
                        onClose={onRenameClose}
                        original={renaming}
                        module={module}
                    />
                )
            }
        </div>
    );
}
