import React, { useState } from 'react';
import {
    Edit, SimpleForm,
    useEditController,
    ListButton
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import UiToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Slide from '@material-ui/core/Slide';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import toFullLink from '../UrlUtil';
import EditPane from './control/EditPane';
import EditToolbar from './control/EditToolbar';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    editBox: {
        maxWidth: '32rem',
        position: 'absolute',
        zIndex: 2

    },
    previewBox: {
        position: 'absolute',
        right: '0',
        width: '100%',
        top: '0',
        bottom: '0'
    },
    icon: {
        marginTop: '2px'
    },
    header: {
        width: '10rem',
        color: '#fff',
        background: '#707070',
        lineHeight: '28px',
        height: '28px',
        cursor: 'move'
    },
    edit: {

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    iframe: {
        width: '100%', height: '100vh', overflow: 'hidden'
    },
    form: {
        '& >.MuiCardContent-root': {
            paddingLeft: '0',
            paddingRight: '0'
        }
    },
    RaSimpleFormIterator: {
        line: {
            background: '#f00'
        }
    }
}));

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const transformAllData = (data, restData) => {
    const {
        topLinks, callouts, passwordEnabled, password
    } = data;
    const navLinks = [];
    if (topLinks) {
        // eslint-disable-next-line guard-for-in
        for (const k in topLinks) {
            const link = topLinks[k];
            if (link.enabled) {
                navLinks.push({
                    code: k,
                    data: { ...link, enabled: 'true', x24x7: link.x24x7 ? 'true' : null }
                });
            }
        }
    }
    if (callouts && callouts.length > 0) {
        for (let index = 0; index < callouts.length; index += 1) {
            const c = callouts[index];
            if (c) {
                c.id = c.id || 0;
                c.enabled = !!c.enabled;
                c.sequence = index;
            }
        }
    }

    const fd = {
        ...data,
        navLinks,
        password: passwordEnabled ? password : '',
        ...restData
    };
    return (fd);
};

const PresentationEdit = (props) => {
    const [open, setOpen] = useState(true);
    const [formData, setFormData] = useState({});
    const [customFormData, setCustomFormData] = useState({});
    const [msg, setMsg] = useState(null);
    const [prevUpdatedOn, setPrevUpdatedOn] = useState(new Date('2020'));
    const [collapsed, setCollapsed] = useState(false);
    const [allAgreed, setAllAgreed] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const [floatMode, setFloatMode] = useState(true);
    const [headlines, setHeadlines] = useState([]);
    const [subHeadlines, setSubHeadlines] = useState([]);
    const [covers, setCovers] = useState([]);
    const [selectCover, setSelectCover] = useState(null);

    const handleClose = () => {
        setOpen(false);
        const { history, resource } = props;
        history.push(`/${resource}`);
    };
    const controllerProps = useEditController(props);
    const { record = { fundingTypeName: '', name: '' } } = controllerProps;

    const classes = useStyles();
    const previewLink = record ? toFullLink(`preview/${record.id}`, '') : '';
    const validateEdit = (values) => {
        const allData = transformAllData(values, { ...formData, coverPathId: selectCover });
        if (record.language === 'English') {
            allData.enableMedias = true;
            allData.enableDocBtnContent = true;
            allData.enableCallouts = record.fundingTypeName === 'Self Insured';
        }

        if (allData.additionalAttachments && allData.additionalAttachments.length > 0) {
            allData.additionalAttachments = allData.additionalAttachments.map((aa) => {
                if (aa.rawFile) {
                    return { ...aa, fileData: '' };
                }
                return aa;
            });
        }

        if (allData.coverPathId) {
            const coverObj = covers.find((item) => item.id === allData.coverPathId);
            allData.coverPath = (coverObj || {}).value;
        }

        const headlineObj = headlines.find((item) => item.id === allData.headlineId);
        const subHeadlineObj = subHeadlines.find((item) => item.id === allData.subheadlineId);
        allData.headline = (headlineObj || {}).value || null;
        allData.subheadline = (subHeadlineObj || {}).value || null;
        allData.spaHeadline = (headlineObj || {}).spaValue || null;
        allData.spaSubheadline = (subHeadlineObj || {}).spaValue || null;
        allData.frcaHeadline = (headlineObj || {}).frcaValue || null;
        allData.frcaSubheadline = (subHeadlineObj || {}).frcaValue || null;

        // allData.customerLogoFileData = allData.customerLogoFile ? { fileData: window.URL.createObjectURL(allData.customerLogoFile.rawFile) } : null;

        if (allData.customerLogoFile !== undefined) {
            allData.customerLogoFileData = allData.customerLogoFile ? { fileData: window.URL.createObjectURL(allData.customerLogoFile.rawFile) } : null;
        } else {
            allData.customerLogoFileData = {};
        }

        const previewWindow = document.getElementById('preview-window');
        if (previewWindow) {
            const now = new Date();
            if (((now - prevUpdatedOn) / 1000) > 2) {
                const newMsg = JSON.stringify(allData);
                if ((allData.customerLogoFile === undefined && customFormData.customerLogoFileData === null && values.customerLogoFileData)) {
                    allData.customerLogoFileData = null;
                    setPrevUpdatedOn(now);
                    setMsg(newMsg);
                    previewWindow.contentWindow.postMessage({ data: allData, event: 'edit', when: now }, '*');
                    return;
                }

                if (allData.customerLogoFile === undefined && customFormData.customerLogoFileData === undefined && values.customerLogoFileData === null) {
                    allData.customerLogoFileData = null;
                }
                if (!msg || msg !== newMsg) {
                    setPrevUpdatedOn(now);
                    setMsg(newMsg);
                    previewWindow.contentWindow.postMessage({ data: allData, event: 'edit', when: now }, '*');
                }
            }
        }

        return null;
    };

    const setFormValue = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const setCustomFormValue = (name, value) => {
        setCustomFormData({ ...customFormData, [name]: value });
    };

    const { basePath } = props;

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <UiToolbar>
                    <ListButton basePath={basePath} color="inherit" label="" icon={<CloseIcon />} />
                    <Typography variant="h6" className={classes.title}>
                        Edit Microsite: {record.name} ({record.fundingTypeName})
                    </Typography>
                </UiToolbar>
            </AppBar>
            {
                floatMode
                    ? (
                        <Box style={{ position: 'relative' }}>
                            <Draggable
                                handle=".drag-icon"
                                defaultPosition={{ x: 32, y: 32 }}
                                position={null}
                            >
                                <Box
                                    className={classes.editBox}
                                >
                                    <div
                                        className={classes.header}
                                        onDoubleClick={() => setFloatMode(!floatMode)}
                                    >
                                        <Box className="drag-icon">
                                            <Grid container>
                                                <Grid item xs={2}><DragIndicatorIcon className={classes.icon} /></Grid>
                                                <Grid item xs={8}><span className="text">Edit panel</span></Grid>
                                                <Grid item xs={2} style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => setCollapsed(!collapsed)}>
                                                    {
                                                        collapsed
                                                            ? <ChevronRightIcon className={classes.icon} />
                                                            : <ExpandMoreIcon className={classes.icon} />
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </div>
                                    <Edit
                                        {...props}
                                        style={{ marginTop: '-1rem', display: collapsed ? 'none' : 'block', boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.75)' }}
                                    >
                                        <SimpleForm
                                            toolbar={(
                                                <EditToolbar
                                                    restFormData={{ ...formData, coverPathId: selectCover, ...customFormData }}
                                                    allAgreed={allAgreed}
                                                    fundingType={record.fundingTypeName}
                                                    headlines={headlines}
                                                    subHeadlines={subHeadlines}
                                                />
                                            )}
                                            redirect={false}
                                            variant="outlined"
                                            validate={validateEdit}
                                            className={classes.form}
                                            style={{ maxHeight: 'calc( 100vh - 10rem )', overflowY: 'auto', overflowX: 'hidden' }}
                                        >
                                            <EditPane
                                                {...props}
                                                setFormData={setFormValue}
                                                formData={formData}
                                                customFormData={customFormData}
                                                setCustomFormData={setCustomFormValue}
                                                setHeadlines={setHeadlines}
                                                setSubHeadlines={setSubHeadlines}
                                                selectCover={selectCover}
                                                setSelectCover={setSelectCover}
                                                setCovers={setCovers}
                                                onAllAgreed={(b) => setAllAgreed(b)}
                                                onAssetLoaded={() => setShowIframe(true)}
                                            />
                                        </SimpleForm>
                                    </Edit>
                                </Box>
                            </Draggable>
                            <Box
                                className={classes.previewBox}
                            >
                                {showIframe && (
                                    <iframe
                                        title="preview"
                                        src={previewLink}
                                        height="100%"
                                        width="100%"
                                        id="preview-window"
                                        className={classes.iframe}
                                    />
                                )}
                            </Box>
                        </Box>

                    )
                    : (
                        <Grid container>
                            <Grid item xs={3}>
                                <div
                                    className={classes.header}
                                    onDoubleClick={() => setFloatMode(!floatMode)}
                                >
                                    <Box className="drag-icon">
                                        <Grid container>
                                            <Grid item xs={12}><span className="text">Edit panel</span></Grid>
                                        </Grid>

                                    </Box>
                                </div>
                                <Edit
                                    {...props}
                                    style={{ marginTop: '-1rem', display: collapsed ? 'none' : 'block', boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.75)' }}
                                >
                                    <SimpleForm
                                        toolbar={(
                                            <EditToolbar
                                                restFormData={{ ...formData, coverPathId: selectCover, ...customFormData }}
                                                allAgreed={allAgreed}
                                                fundingType={record.fundingTypeName}
                                                headlines={headlines}
                                                subHeadlines={subHeadlines}
                                            />
                                        )}
                                        redirect={false}
                                        variant="outlined"
                                        validate={validateEdit}
                                        className={classes.form}
                                        style={{ maxHeight: 'calc( 100vh )', overflowY: 'auto', overflowX: 'hidden' }}
                                    >
                                        <EditPane
                                            {...props}
                                            setFormData={setFormValue}
                                            formData={formData}
                                            customFormData={customFormData}
                                            setCustomFormData={setCustomFormValue}
                                            setHeadlines={setHeadlines}
                                            setSubHeadlines={setSubHeadlines}
                                            selectCover={selectCover}
                                            setSelectCover={setSelectCover}
                                            setCovers={setCovers}
                                            onAllAgreed={(b) => setAllAgreed(b)}
                                            onAssetLoaded={() => setShowIframe(true)}
                                        />
                                    </SimpleForm>
                                </Edit>
                            </Grid>
                            <Grid item xs={9}>
                                {showIframe && (
                                    <iframe
                                        title="preview"
                                        src={previewLink}
                                        height="100%"
                                        width="100%"
                                        id="preview-window"
                                        className={classes.iframe}
                                    />
                                )}
                            </Grid>
                        </Grid>

                    )
            }
            <div id="draggableForModal" />
        </Dialog>
    );
};

export default PresentationEdit;
