import * as React from 'react';
import Typography from '@material-ui/core/Typography';

function StatusField(props) {
    const {
        source, record = {}
    } = props;

    if (record.expired) {
        return (
            <Typography variant="body1">
                Expired
            </Typography>
        );
    }

    return (
        <Typography variant="body1">
            {record[source]}
        </Typography>
    );
    /*
        return (
            <Select
                labelId={`status-select-label-${record.id}`}
                id={`status-select-${record.id}`}
                value={record[source] || ''}
                onChange={onChange}
                color="primary"
                variant="outlined"
                className={classes.root}
            >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
                <MenuItem value="Deleted">Deleted</MenuItem>
            </Select>
        );
     */
}

export default StatusField;
