/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
    Create, SimpleForm, maxLength,
    TextInput, RadioButtonGroupInput, Toolbar, SaveButton, BooleanInput,
    required, SelectInput, useDataProvider, FormDataConsumer, LinearProgress,
    useCreateController, useCreate
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Select, FormControl, InputLabel, MenuItem, Container,
    FormHelperText, Link, Switch, FormControlLabel
} from '@material-ui/core';
import RenderLayoutOption from 'components/RenderLayoutOption';
import StateInput from 'components/StateInput';
import SelectInput2 from 'components/FormInputs/SelectInput2';
import { TourContainer, TourHeader } from 'components/Tour/TourWrapper';
import CoverSelect from 'components/Microsite/control/CoverSelect';

const CreateToolbar = (props) => {
    const transformData = (d) => {
        let { segmentId } = d;

        const {
            options,
            fundingTypeOptions,
            restFormData,
            isSpanish,
            isFrench
        } = props;
        if (!options.brandSegments.find((x) => x.brand === d.brandName && x.segmentId === segmentId)) {
            segmentId = null;
        }

        const transformed = {
            ...d,
            vanityUrl: d.vanityUrl || d.name,
            fundingTypeName: fundingTypeOptions.find((o) => o.id === d.fundingTypeId).name,
            segmentId,
            ...restFormData
        };

        if (transformed.brandName === 'Aetna') {
            transformed.isSpanish = isSpanish;
        } else if (transformed.brandName === 'Aetna International') {
            transformed.isSpanish = isSpanish;
            transformed.isFrench = isFrench;
        }

        return transformed;
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                invalid={false}
                label="Create"
                redirect="edit"
                transform={transformData}
            />
        </Toolbar>
    );
};

const useStyles = makeStyles(() => ({
    vRadio: {
        position: 'relative',

        '& #fundingTypeId': {
            paddingLeft: '.25rem',
            flexDirection: 'column'
        }
    },
    stateSelect: {
        position: 'absolute', width: '50%', right: '.5rem', bottom: '2.5rem'
    }
}));

const PresentationCreate = (props) => {
    const { resource } = props;
    const [create] = useCreate();
    const createControllerProps = useCreateController(props);
    const dataProvider = useDataProvider();
    const [options, setOptions] = useState({});
    const [brandOptions, setBrandOptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [fundingTypeId, setFundingTypeId] = useState(null);
    const [brandName, setBrandName] = useState(null);
    const [product, setProduct] = useState('');
    const [coverOptions, setCoverOptions] = useState([]);
    const [headlineOptions, setHeadlineOptions] = useState([]);
    const [subHeadlineOptions, setSubHeadlineOptions] = useState([]);
    const [isSpanish, setIsSpanish] = useState(false);
    const [isFrench, setIsFrench] = useState(false);

    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        dataProvider.getOptions(resource)
            .then(({ data }) => {
                setBrandOptions(data.brands.map((x) => ({ id: x.name, name: x.name, subPath: x.subPath })));

                setOptions(data);

                setLoading(false);
            })
            .catch((optionError) => {
                setError(optionError);
                setLoading(false);
            });
    }, [dataProvider, resource]);

    useEffect(() => {
        if (brandName && fundingTypeId) {
            const brand = options.brands.find((item) => item.name === brandName);
            const requestData = {
                fundingTypeId,
                brandId: (brand || {}).id || null,
                product
            };

            dataProvider.getCoverPaths(resource, requestData)
                .then(({ data }) => {
                    setCoverOptions(data);
                    if (data.length > 0) {
                        setFormValue('coverPathId', data[0].id);
                    }
                });
            dataProvider.getHeadlines(resource, requestData)
                .then(({ data }) => {
                    const newData = data.map((item) => {
                        return {
                            id: item.id,
                            name: item.value
                        };
                    });
                    setHeadlineOptions(newData);
                });
            dataProvider.getSubheadlines(resource, requestData)
                .then(({ data }) => {
                    const newData = data.map((item) => {
                        return {
                            id: item.id,
                            name: item.value
                        };
                    });
                    setSubHeadlineOptions(newData);
                });
        }
    }, [brandName, product, fundingTypeId]);

    useEffect(() => {
        setIsSpanish(false);
        setIsFrench(false);
    }, [brandName]);

    const layouts = [
        { id: '1', name: 'Layout 1' },
        { id: '2', name: 'Layout 2' },
        { id: '3', name: 'Layout 3' }
    ];
    const fundingTypeOptions = [
        { id: '1', name: 'Self Insured' },
        { id: '2', name: 'Fully Insured' }
    ];

    const tourNames = [
        'Name', 'Client Name', 'Vanity URL', 'PS Unique Code', 'Funding Type', 'Brand', 'Segment', 'Theme', 'Layout', 'Headline', 'Subheadline'
    ];
    const tourRef = React.useRef();
    const forwardShowTour = (s) => tourRef.current.onShowTour(s);

    const classes = useStyles();

    if (!options || !options.marketSegments) {
        return <LinearProgress />;
    }

    const initValues = {};
    if (options.marketSegments.length) {
        initValues.segmentId = options.marketSegments[0].id;
    }

    const setFormValue = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const validateEdit = (values) => {
        setFundingTypeId(values.fundingTypeId);
        setBrandName(values.brandName);
        if (values.brandName) {
            const brand = options.brands.find((t) => t.name === values.brandName);
            let products = options.products.filter((t) => t.product && t.brandId === brand.id);
            products = [...new Set(products.map((t) => t.product))].map((p) => ({ value: p, text: p }));
            if (products.length) {
                const current = products.find((item) => item.value === values.product);
                if (current != null) {
                    setProduct(values.product);
                } else {
                    setProduct(products[0].value);
                }
            } else {
                setProduct('');
            }
        } else {
            setProduct('');
        }

        // if (values.brandName && !formData.themeName) {
        //     let brandThemes = options.themes.filter((t) => t.brand === values.brandName);
        //     if (!brandThemes.length) {
        //         brandThemes = options.themes.filter((t) => !t.brand);
        //     }

        //     brandThemes = brandThemes.filter((t) => !t.product || t.product === values.product).map((t) => ({ value: t.id, text: t.name }));

        //     if (brandThemes.length) {
        //         setFormValue('themeName', brandThemes[0].text);
        //     }
        // }
    };

    return (
        <Container maxWidth="md" style={{ margin: 0 }}>
            <TourContainer
                ref={tourRef}
                stepNames={tourNames}
            >
                <Create {...props}>
                    <SimpleForm
                        toolbar={(
                            <CreateToolbar
                                restFormData={{
                                    ...formData,
                                    product
                                }}
                                isSpanish={isSpanish}
                                isFrench={isFrench}
                                options={options}
                                fundingTypeOptions={fundingTypeOptions}
                            />
                        )}
                        redirect="edit"
                        validate={validateEdit}
                    >
                        <Grid container spacing={2} fullWidth>
                            <Grid item xs={6}>
                                <TextInput
                                    source="name"
                                    id="name-box"
                                    validate={[required()]}
                                    variant="outlined"
                                    label={(
                                        <TourHeader tourSelector="Name" onShowTour={forwardShowTour}>
                                            <span>Microsite Name</span>
                                        </TourHeader>
                                    )}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="clientName"
                                    validate={[required()]}
                                    variant="outlined"
                                    label={(
                                        <TourHeader tourSelector="Client Name" onShowTour={forwardShowTour}>
                                            <span>Client Name</span>
                                        </TourHeader>
                                    )}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData: fd }) => {
                                        let urlValue = '';
                                        if (fd) {
                                            if (!fd.vanityUrl) {
                                                urlValue = fd.name || '';
                                            } else {
                                                urlValue = fd.vanityUrl;
                                            }
                                        }
                                        urlValue = urlValue.replace(/ /g, '-');

                                        return (
                                            <TextInput
                                                source="vanityUrl"
                                                variant="outlined"
                                                label={(
                                                    <TourHeader tourSelector="Vanity URL" onShowTour={forwardShowTour}>
                                                        <span>Vanity URL</span>
                                                    </TourHeader>
                                                )}
                                                helperText=""
                                                fullWidth
                                                format={() => urlValue}
                                            />
                                        );
                                    }}
                                </FormDataConsumer>

                                <FormHelperText>
                                    Microsite URL: &nbsp;
                                    <FormDataConsumer>
                                        {({ formData: fd }) => {
                                            let urlValue = '';
                                            if (fd) {
                                                if (!fd.vanityUrl) {
                                                    urlValue = fd.name || '';
                                                } else {
                                                    urlValue = fd.vanityUrl;
                                                }
                                            }
                                            if (!urlValue) return null;
                                            urlValue = urlValue.replace(/ /g, '-');
                                            const subSegments = [];
                                            if (fd.brandName) {
                                                const brandSubPath = brandOptions.find((bo) => bo.name === fd.brandName).subPath;
                                                if (brandSubPath) {
                                                    subSegments.push(brandSubPath);
                                                }
                                            }
                                            if (fd.segmentId) {
                                                subSegments.push(options.marketSegments.find((s) => s.id === fd.segmentId).subPath);
                                            }
                                            const subPath = subSegments.join('/');
                                            const link = `${window.location.origin}/${subPath}/${urlValue}`;

                                            return (
                                                <Link color="primary" target="_blank" href={link}>{link}</Link>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput
                                    source="psunique"
                                    validate={[required(), maxLength(10)]}
                                    variant="outlined"
                                    label={(
                                        <TourHeader tourSelector="PS Unique Code" onShowTour={forwardShowTour}>
                                            <span>PSUnique Code</span>
                                        </TourHeader>
                                    )}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={classes.vRadio}
                            >
                                <RadioButtonGroupInput
                                    source="fundingTypeId"
                                    label={(
                                        <TourHeader tourSelector="Funding Type" onShowTour={forwardShowTour}>
                                            <span>Funding Type</span>
                                        </TourHeader>
                                    )}
                                    defaultValue="1"
                                    choices={fundingTypeOptions}
                                />
                                <FormDataConsumer>
                                    {({ formData: fd }) => {
                                        // eslint-disable-next-line eqeqeq
                                        if (fd.fundingTypeId === '2' && fd.brandName && fd.brandName !== 'Aetna International') {
                                            return (
                                                <StateInput
                                                    source="stateSitus"
                                                    className={classes.stateSelect}
                                                    fullWidth
                                                />
                                            );
                                        }

                                        return null;
                                    }}
                                </FormDataConsumer>

                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput
                                    label={(
                                        <TourHeader tourSelector="Brand" onShowTour={forwardShowTour}>
                                            <span>Brand</span>
                                        </TourHeader>
                                    )}
                                    source="brandName"
                                    validate={[required()]}
                                    variant="outlined"
                                    fullWidth
                                    choices={brandOptions}
                                />

                                <FormDataConsumer>
                                    {(p) => {
                                        const { formData: fd, ...rest } = p;
                                        let segments = [];
                                        if (fd.brandName) {
                                            const sIds = options.brandSegments.filter((x) => x.brand === fd.brandName).map((x) => x.segmentId);
                                            segments = options.marketSegments
                                                .filter((s) => sIds.includes(s.id))
                                                .map((s) => ({
                                                    value: s.id,
                                                    text: s.name
                                                }));
                                        }

                                        return (
                                            <SelectInput2
                                                label={(
                                                    <TourHeader tourSelector="Segment" onShowTour={forwardShowTour}>
                                                        <span>Segment</span>
                                                    </TourHeader>
                                                )}
                                                source="segmentId"
                                                variant="outlined"
                                                fullWidth
                                                menuItems={segments}
                                                currentValue={fd.segmentId}
                                                {...rest}
                                            />
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>
                            <FormDataConsumer>
                                {({ formData: fd, ...rest }) => {
                                    if (!fd.brandName) return null;
                                    const brand = options.brands.find((t) => t.name === fd.brandName);
                                    let products = options.products.filter((t) => t.product && t.brandId === brand.id);
                                    products = [...new Set(products.map((t) => t.product))].map((p) => ({ value: p, text: p }));

                                    if (products.length) {
                                        return (
                                            <Grid item xs={6}>
                                                <SelectInput2
                                                    label={(
                                                        <TourHeader tourSelector="Product" onShowTour={forwardShowTour}>
                                                            <span>Product</span>
                                                        </TourHeader>
                                                    )}
                                                    source="product"
                                                    validate={[required()]}
                                                    variant="outlined"
                                                    fullWidth
                                                    menuItems={products}
                                                    currentValue={fd.product}
                                                    {...rest}
                                                />
                                            </Grid>
                                        );
                                    }
                                    return null;
                                }}
                            </FormDataConsumer>
                            {/* <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData: fd, ...rest }) => {
                                        if (!fd.brandName) return null;

                                        let brandThemes = options.themes.filter((t) => t.brand === fd.brandName);
                                        if (!brandThemes.length) {
                                            brandThemes = options.themes.filter((t) => !t.brand);
                                        }

                                        brandThemes = brandThemes.filter((t) => !t.product || t.product === fd.product).map((t) => ({ value: t.id, text: t.name }));
                                        if (brandThemes.length) {
                                            return (
                                                <SelectInput2
                                                    label={(
                                                        <TourHeader tourSelector="Theme" onShowTour={forwardShowTour}>
                                                            <span>Theme</span>
                                                        </TourHeader>
                                                    )}
                                                    source="themeName"
                                                    variant="outlined"
                                                    fullWidth
                                                    menuItems={brandThemes}
                                                    currentValue={fd.themeName}
                                                    {...rest}
                                                />
                                            );
                                        }

                                        return null;
                                    }}
                                </FormDataConsumer>
                            </Grid> */}
                            <FormDataConsumer>
                                {({ formData: fd, ...rest }) => {
                                    if (!fd.brandName) return null;
                                    return (
                                        <Grid item xs={6}>
                                            <SelectInput
                                                source="layout"
                                                style={{ marginTop: '0' }}
                                                label={(
                                                    <TourHeader tourSelector="Layout" onShowTour={forwardShowTour}>
                                                        <span>Layout</span>
                                                    </TourHeader>
                                                )}
                                                variant="outlined"
                                                defaultValue={layouts[0].id}
                                                fullWidth
                                                choices={layouts}
                                                optionText={<RenderLayoutOption brandName={fd.brandName} />}
                                            />
                                        </Grid>
                                    );
                                }}
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData: fd }) => {
                                    if (fd.brandName === 'Aetna International') {
                                        return (
                                            <Grid item xs={6}>
                                                <Grid container fullWidth>
                                                    <Grid item xs={6}>
                                                        {/* <BooleanInput style={{ width: '4rem' }} helperText="" label="Spanish" source="isSpanish" /> */}
                                                        <FormControlLabel
                                                            control={(
                                                                <Switch
                                                                    disabled={isFrench}
                                                                    checked={isSpanish}
                                                                    onChange={(e) => {
                                                                        // if (e.target.checked) {
                                                                        //     setIsFrench(false);
                                                                        // }
                                                                        setIsSpanish(e.target.checked);
                                                                    }}
                                                                    name="isSpanish"
                                                                />
                                                            )}
                                                            label="Spanish"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {/* <BooleanInput style={{ width: '12rem' }} helperText="" label="French Canadian" source="isFrench" /> */}
                                                        <FormControlLabel
                                                            control={(
                                                                <Switch
                                                                    disabled={isSpanish}
                                                                    checked={isFrench}
                                                                    onChange={(e) => {
                                                                        // if (e.target.checked) {
                                                                        //     setIsSpanish(false);
                                                                        // }
                                                                        setIsFrench(e.target.checked);
                                                                    }}
                                                                    name="isFrench"
                                                                />
                                                            )}
                                                            label="French Canadian"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    if (fd.brandName !== 'Aetna') return null;
                                    return (
                                        <Grid item xs={6}>
                                            {/* <BooleanInput style={{ width: '4rem' }} helperText="" label="Spanish" source="isSpanish" /> */}
                                            <FormControlLabel
                                                control={(
                                                    <Switch
                                                        checked={isSpanish}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setIsFrench(false);
                                                            }
                                                            setIsSpanish(e.target.checked);
                                                        }}
                                                        name="isSpanish"
                                                    />
                                                )}
                                                label="Spanish"
                                            />
                                        </Grid>
                                    );
                                }}
                            </FormDataConsumer>
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData: fd, ...rest }) => {
                                        if (!fd.brandName) return null;
                                        return (
                                            <SelectInput
                                                label={(
                                                    <TourHeader tourSelector="Headline" onShowTour={forwardShowTour}>
                                                        <span>Headline</span>
                                                    </TourHeader>
                                                )}
                                                source="headlineId"
                                                variant="outlined"
                                                validate={[required()]}
                                                fullWidth
                                                choices={headlineOptions}
                                                {...rest}
                                            />
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                    {({ formData: fd, ...rest }) => {
                                        if (!fd.brandName) return null;
                                        return (
                                            <SelectInput
                                                label={(
                                                    <TourHeader tourSelector="Subheadline" onShowTour={forwardShowTour}>
                                                        <span>Sub-headline</span>
                                                    </TourHeader>
                                                )}
                                                source="subheadlineId"
                                                variant="outlined"
                                                validate={[required()]}
                                                fullWidth
                                                choices={subHeadlineOptions}
                                                {...rest}
                                            />
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={12}>
                                <FormDataConsumer>
                                    {({ formData: fd, ...rest }) => {
                                        if (!fd.brandName) return null;
                                        return (
                                            <>
                                                <div>
                                                    Select Image
                                                </div>
                                                <CoverSelect
                                                    selected={formData.coverPathId}
                                                    covers={coverOptions}
                                                    onSelect={(val) => {
                                                        setFormValue('coverPathId', val);
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>
                            </Grid>
                        </Grid>
                    </SimpleForm>
                </Create>
            </TourContainer>
        </Container>
    );
};

export default PresentationCreate;
