import Box from '@material-ui/core/Box';
import { useInput, TextInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResourceDialog from './ResourceDialog';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        flexFlow: 'row wrap',
        alignItems: 'stretch',
        padding: '.5rem 0'
    },
    btn: {
        flex: 1,
        height: '2.5rem',
        borderRadius: '.25rem 0 0 .25rem',
        backgroundColor: 'whitesmoke',
        borderRight: '0'
    },
    input: {
        flex: 20,
        marginTop: '0',
        '& .MuiOutlinedInput-root': {
            borderRadius: '0 .25rem .25rem 0'
        }
    }
}));

const ResourceInput = (props) => {
    const classes = useStyles();
    const {
        input: { name, onChange }
    } = useInput(props);

    const {
        label, source, module, record, useStaticCallouts, brandName
    } = props;
    const [openDialog, setOpenDialog] = useState(false);

    const onDialogClosed = () => {
        setOpenDialog(false);
    };
    const onFileSelect = (fileInfo) => {
        const e = { target: { name, value: fileInfo.relativePath } };
        onChange(e);
        // onFileChange();
        setOpenDialog(false);
    };

    // const onFileChange = (e) => {
    //     setValue(e.target.value);
    //     onChange(e);
    // };

    return (
        <Box className={classes.root}>

            <Button
                variant="outlined"
                color="primary"
                size="small"
                className={classes.btn}
                onClick={() => setOpenDialog(true)}
            >
                Select
            </Button>
            <TextInput
                label={label}
                source={source}
                variant="outlined"
                record={record}
                fullWidth
                helperText=""
                className={classes.input}
            />

            {openDialog && (
                <ResourceDialog
                    open={openDialog}
                    onClose={onDialogClosed}
                    onFileSelect={onFileSelect}
                    module={module}
                    brandName={brandName}
                    useStaticCallouts={useStaticCallouts}
                />
            )}
        </Box>
    );
};

export default ResourceInput;
