/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
    Box, Divider, Grid
} from '@material-ui/core';
import {
    BooleanInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { TourHeader } from 'components/Tour/TourWrapper';

const useStyles = makeStyles(() => ({
    div: {
        marginBottom: '.5rem',
        marginTop: '.5rem'
    }
}));
export default function NavigationPayFlex({
    forwardShowTour
}) {
    const classes = useStyles();

    return (
        <Box style={{ width: '100%' }}>
            <Box mb={2}>
                Navigation links to appear at the top of the site. Content tiles to appear in the middle.
            </Box>
            <Divider classnames={classes.div} />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <BooleanInput
                        label={(
                            <TourHeader tourSelector="Log In" onShowTour={forwardShowTour}>
                                <span>Member Login</span>
                            </TourHeader>
                        )}
                        helperText=""
                        source="topLinks.PayFlexLogin.enabled"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
