/* eslint-disable max-len */
import React from 'react';

const tourItems = [{
    content: (<p>Create a Web Microsite button allows you to create a new microsite.</p>),
    title: 'Create a Web Microsite'
}, {
    content: (<p>Download CSV button allows you to download a .csv export of only your microsites.</p>),
    title: 'Download CSV'
}, {
    content: (<p>Allows you to pick the amount of entries to be shown per page (5, 10, 25, 50, 100, All).</p>),
    title: 'Entries'
}, {
    content: (<p>Allows you to filter by Active or Inactive status.</p>),
    title: 'Status'
}, {
    content: (<p>Allows you to utilize the search filter to quickly find a particular entry.</p>),
    title: 'Search'
}, {
    content: (<p>Microsite&apos;s customer name.</p>),
    title: 'Customer'
}, {
    content: (
        <p>
            You can view your Active microsite by clicking on the link in the Microsite column.<br />
            To Edit your microsite, click on <i className="fa fa-wrench" /> in Action column.
        </p>
    ),
    title: 'Microsite Name/Link'
}, {
    content: (<p>The functionality and content of the themes are the same - only the images and headlines vary.</p>),
    title: 'Theme'
}, {
    content: (<p>Choose your brand.</p>),
    title: 'Brand'
}, {
    content: (<p>Layout assigned to the microsite. The functionality and content of the layouts are the same - only the sequence vary.</p>),
    title: 'Layout'
}, {
    content: (<p>Is the microsite for Fully or Self Insured?</p>),
    title: 'Funding Type'
}, {
    content: (<p>The last date the microsite was saved.</p>),
    title: 'Modification Date'
}, {
    content: (
        <ul>
            <li>
                <strong className="colon">Test:&nbsp;</strong>
                <span>
                    Available as part of the tool but can only be accessed by administrative users.
                </span>
            </li>
            <li>
                <strong className="colon">Delete:&nbsp;</strong>
                <span>
                    <strong>Removes the microsite permanently from the system and cannot be retrieved.</strong>
                </span>
            </li>
            <li><strong className="colon">Inactive:&nbsp;</strong>
                <span>
                    Available as part of the tool but can only be accessed by administrative users. For regular users, if a microsite is set to &quot;Inactive&quot;, the microsite can only be viewed in the preview mode.
                </span>
            </li>
            <li><strong className="colon">Active:&nbsp;</strong>
                <span>Microsite is live and can be shared with clients.</span>
            </li>
            <li>
                <strong className="colon">Expired:&nbsp;</strong>
                <span>Microsite expiry date has been reached and the microsite is no longer viewable to the public.</span>
            </li>
        </ul>
    ),
    title: 'Microsite Status'
}, {
    content: (
        <>
            <p>This is where the actions for this entry are listed:</p>
            <ul>
                <li><i className="fa fa-wrench" /><span>Edit the microsite</span></li>
                <li><i className="fa fa-search" /><span>Opens a preview version of the microsite in a new tab.</span></li>
                <li><i className="fa fa-clone" /><span>Creates a duplicate of the microsite.</span></li>
                <li><i className="fa fa-remove" />
                    <span>Lets you delete a test microsite.
                        <strong>NOTE: Only when that microsite is a test, otherwise use the status drop-down to delete the microsite.</strong>
                    </span>
                </li>
            </ul>
        </>
    ),
    title: 'Microsite Actions'
}, {
    content: (<p>Allows you to view the microsite owner’s user name and email address.</p>),
    title: 'Microsite Owner'
}, {
    content: (<p>The date the microsite was first created.</p>),
    title: 'Creation Date'
}, {
    content: (
        <>
            <p>This is where the actions for this entry are listed:</p>
            <ul>
                <li>
                    <i className="fa fa-wrench" />
                    <span>Edit the microsite</span>
                </li>
                <li>
                    <i className="fa fa-search" />
                    <span>Opens a preview version of the microsite in a new tab.</span>
                </li>
                <li>
                    <i className="fa fa-clone" />
                    <span>Creates a duplicate of the microsite.</span>
                </li>
                <li>
                    <i className="fa fa-users" />
                    <span>Allows you to change the owner of the microsite.</span>
                </li>
                <li>
                    <i className="fa fa-remove" />
                    <span>
                        Lets you delete a test microsite. <strong>NOTE: Only when that microsite is a test, otherwise use the status drop-down to delete the microsite.</strong>
                    </span>
                </li>
                <li>
                    <strong className="colon">Restrictions</strong> <span>enables or removes the character restrictions for the main introduction content. <strong>NOTE: available to administrative users only.</strong></span>
                </li>
            </ul>
        </>
    ),
    title: 'Microsite Actions'
}, {
    content: (
        <p>
            This will open the microsite in a separate window indicated with the red bar. Note any changes you may need to make, close the preview window and make any changes in Microsite Edit screen.
        </p>
    ),
    title: 'Preview'
}, {
    content: (<p>Select the Segment that this microsite belongs to.</p>),
    title: 'Segment'
}, {
    content: (<p>Enter the microsite name. <strong>NOTE: This is important because the name you enter becomes part of the finished microsite&apos;s URL.</strong></p>),
    title: 'Name'
}, {
    content: (
        <p>
            Select the theme from the drop-down that best fits your customers&apos; demographic and aesthetic. The functionality and content of the themes are the same – only the images vary.
        </p>
    ),
    title: 'Theme'
}, {
    content: (<p>Enter the client name.</p>),
    title: 'Client Name'
}, {
    content: (
        <p>
            You can change the URL that was automatically generated using the name of the microsite. The text you enter here will be used to make the &quot;vanity URL&quot; for your customer. Do not use any special characters or spaces. &nbsp;
            <strong>
                NOTE: If the vanity url you choose is already taken, the system will throw an error message indicating that you must choose another name.
            </strong>
        </p>),
    title: 'Vanity URL'
}, {
    content: (
        <p>
            The PS Unique (also called PSUID) is a number each case is assigned internally. Each Active case has one, and it eliminates confusion when dealing with plan sponsor names that may be similar or confusing.
        </p>
    ),
    title: 'PS Unique Code'
}, {
    content: (
        <p>
            Upload the client’s logo and check off that you have permission to use their logo. Uploading the logo is optional. <strong>Recommended specs: max height of 60px and/or max width of 225px</strong>
        </p>
    ),
    title: 'Client Logo'
}, {
    content: (<p>Check off that you have permission to use their logo.</p>),
    title: 'Client Logo Permission'
}, {
    content: (
        <p>
            When this is enabled, instructions will be given on the front end that will say &quot;Inspira members can log in to your member Inspira account to check your HSA, FSA or Limited Purpose FSA.&quot; Users will be taken to inspirafinancial.com to log in.
        </p>
    ),
    title: 'Inspira HSA/FSA'
}, {
    content: (
        <p>
            When this is enabled, instructions will be given on the front end that will say &quot;Aetna members log in to your secure member website to access your digital ID card. Once logged in Click on &quot;ID Card&quot; at the top of the page.&quot; Users will be taken to the Aetna secure website log in.
        </p>
    ),
    title: 'ID Card'
}, {
    content: (
        <p>
            When this is enabled, instructions will be given on the front end that will say &quot;Aetna members can log in to your secure member website and find care, manage claims and benefits, see coverage and costs, stay healthy.&quot; Users will be taken to the Aetna secure website log in.
        </p>
    ),
    title: 'Log In'
}, {
    content: (<p>When this is enabled, a link to the Aetna Health Care 101 link will appear.</p>),
    title: 'Health Care 101'
}, {
    content: (
        <p>
            When this is enabled, you will have the ability to add a URL to the public find a doctor site or your custom deep link and any steps/directions needed for members to access and search for in-network providers.
        </p>
    ),
    title: 'Find A Doctor Link'
}, {
    content: (
        <p>
            Inserting content here will make a popup appear with that content when the end user click on the microsite find a doctor button/link.
        </p>
    ),
    title: 'Find A Doctor Popup'
}, {
    content: (
        <p>
            Simply click &quot;Enable Contact&quot; and enter the Aetna customer service phone number, start time and end time.
        </p>
    ),
    title: 'Contact Popup'
}, {
    content: (<p>The phone number used in the contact popup/modal.</p>),
    title: 'Contact Phone'
}, {
    content: (<p>The time your client can start contacting you at.</p>),
    title: 'Contact Start Time'
}, {
    content: (<p>The time that this number can no longer be reached.</p>),
    title: 'Contact End Time'
}, {
    content: (
        <p>
            Enter a title for the microsite. We suggest using the customer&apos;s name if possible. The microsite title is optional. The title will appear below the customer logo, if it exists, otherwise it will appear below the header image.
        </p>
    ),
    title: 'Title'
}, {
    content: (
        <p>
            You can paste a link from Youtube or Vimeo and when the page loads on the front end it will embed the correct code into the page.
        </p>
    ),
    title: 'Media Oembed'
}, {
    content: (
        <p>
            Password protection for the microsite is optional. It will force the regular user to have to enter that password to be able to view the microsite.
        </p>
    ),
    title: 'Password'
}, {
    content: (
        <p>
            If you would like the microsite to no longer be available for viewing after a specific date, you can enter that date in the &quot;Microsite Expiry&quot; field. If you do not enter a date, the microsite will continue to be active for users.
        </p>
    ),
    title: 'Expiry Date'
}, {
    content: (
        <p>
            This section will only be visible on the site during the OE dates you included in the &quot;begins/ends&quot; section, and will appear above the Plans, Programs and Tools tiles. <strong>You must check the box to activate this feature.</strong>
        </p>
    ),
    title: 'Open Enrollment'
}, {
    content: (
        <>
            <p>
                Check this box for the Open Enrollment area to display in your microsite.
            </p>
        </>
    ),
    title: 'Enable Open Enrollment'
}, {
    content: (
        <p>
            You can provide a link to your customer&apos;s enrollment site, allowing their employees to go directly to their OE site to sign up for benefits. Simply enter the URL for the customer&apos;s OE site.
        </p>
    ),
    title: 'Link To Open Enrollment'
}, {
    content: (
        <div className="slickslider">
            <div className="slide">
                <p>You can select an existing document.</p>
                <img src="/portfolio/front/userguide/img/file-tools/1.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <p>You can arrange and filter documents.</p>
                <img src="/portfolio/front/userguide/img/file-tools/2.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <p>Click on the menu icon on the File Browser system (to make extra menu item appear).</p>
                <img src="/portfolio/front/userguide/img/file-tools/3.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <p>You can change the layout of the documents from a tile view to a list. As a list you then have access to sort documents by size, date created, etc.</p>
                <img src="/portfolio/front/userguide/img/file-tools/4.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <p>
                    You can upload a new document (PDF, WORD, EXCEL, etc.)
                </p>
                <img src="/portfolio/front/userguide/img/file-tools/5.png" className="img-fluid" alt="" />
            </div>
        </div>
    ),
    title: 'Document Browser'
}, {
    content: (<p>Enter the date Open Enrollment begins.</p>),
    title: 'OE Start Date'
}, {
    content: (<p>Enter the date Open Enrollment ends.</p>),
    title: 'OE End Date'
}, {
    content: (<p>You can provide a link to your customer&apos;s custom DocFind URL, if available.</p>),
    title: 'Find A Doctor Button'
}, {
    content: (
        <p>
            When this is enabled, you will have the ability to add a URL to the public find a doctor site or your custom deep link and any steps/directions needed for members to access and search for in-network providers.
        </p>
    ),
    title: 'Enable Find A Doctor'
}, {
    content: (<p>You can provide a link to your customer&apos;s custom DocFind URL, if available.</p>),
    title: 'Find A Doctor Button Link'
}, {
    content: (<p>Inserting content here will make it as the label of the Button.</p>),
    title: 'Find A Doctor Button Label'
}, {
    content: (<p>Inserting content here will make it appear below the button on the microsite.</p>),
    title: 'Find A Doctor Content'
}, {
    content: (
        <>
            <ul>
                <li>
                    <span>You can add additional reference documents to the microsite using this upload tool.</span>
                </li>
                <li><span>Each of the files are encrypted and unique to this microsite.</span></li>
                <li><span>Attachments will appear under any Custom Callout tiles, to the right of the Introduction Text.</span></li>
            </ul>
            <img src="/portfolio/front/userguide/img/callouts/Callout-Additional.jpg" alt="Additional Callout Position on the microsite" className="img-fluid" />
        </>
    ),
    title: 'Additional Attachments'
}, {
    content: (<p>You can enter a title to the attachment.</p>),
    title: 'Attachment Title'
}, {
    content: (<p>You can enter a description to the attachment.</p>),
    title: 'Attachment Description'
}, {
    content: (<p>The size limit on an individual file is 8MB and the size limit on each microsite is 30MB.</p>),
    title: 'File Upload'
}, {
    content: (
        <p>
            You must acknowledge that the document you are uploading has been reviewed and approved or is directly from your customer and they&apos;ve asked you to post to the site. No third party documents are allowed.
        </p>
    ),
    title: 'Acknowledgement'
}, {
    content: (<p>This is to verify that the content author understand that they are not supposed to promote these services in the state mentioned.</p>),
    title: 'State Restrictions'
},
{
    content: (
        <p>
            You must acknowledge that this microsite tool is only to used for Self-Insured National Customers.<br />
            <strong>NOTE: Acknowledgements need to be checked each time you edit the microsite.</strong>
        </p>
    ),
    title: 'Acknowledgement2'
},
{
    content: (<p>After saving the microsite, a box will appear at the top of the page indicating that the web microsite has been saved.</p>),
    title: 'Save'
}, {
    content: (<p>After saving the microsite successfully, you will get back to the new microsite form to start a new microsite.</p>),
    title: 'Save and Create New Microsite'
}, {
    content: (
        <p>
            Review the Introduction language. <strong>You can make more substantial changes to the wording if you like, but custom wording does require Copy Quality and Legal review.</strong> There is a 1,200 character limit. To increase this limit as a user, reach out to the <a href="mailto:aetnaresourcemicrosite@aetna.com">AetnaResourceMicrosite</a> mailbox for assistance. As an Admin you can uncheck the restriction checkbox for that microsite in the All Microsite screen.
        </p>
    ),
    title: 'Introduction Content'
}, {
    content: (
        <>
            <p>
                Check this box for the Top Callout area to display in your microsite. This is an option to include a video or document with a brief description to the right of the introduction content. <strong>This is the opportunity to highlight a program or message of particular interest to your customer.</strong>
            </p>
            <img src="/portfolio/front/userguide/img/callouts/Callout-Additional.jpg" alt="Top Callout Position on the microsite" className="img-fluid" />
        </>
    ),
    title: 'Enable Top Callout'
}, {
    content: (
        <p>
            Select an Image/Video that represent the callout content. If you have issues with uploading your images or documents, contact the <a href="mailto:aetnaresourcemicrosite@aetna.com">AetnaResourceMicrosite</a> mailbox.
        </p>
    ),
    title: 'Top Callout Image'
}, {
    content: (
        <div className="slickslider">
            <div className="slide">
                <p>You can select an existing image.</p>
                <img src="/portfolio/front/userguide/img/image-tools/1.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <p>
                    <strong>Uploading an image</strong>
                </p>
                <ol>
                    <li>
                        <span>Click on the menu icon in the File Browser system (see previous slide 1 of 6). </span>
                    </li>
                    <li>
                        <span>Click on the Up Arrow icon to upload (see previous slide 1 of 6).</span>
                    </li>
                    <li>
                        <span>Click on the Return to Files List button after uploading.</span>
                    </li>
                </ol>
                <img src="/portfolio/front/userguide/img/image-tools/2.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <p>Right Mouse clicking on any of the images will bring a contextual menu.</p>
                <img src="/portfolio/front/userguide/img/image-tools/3.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <ul>
                    <li>
                        <strong className="colon">Show URL</strong>
                        <span>Will show you the path to the image.
                            <strong>
                                NOTE: this is part of the standard tool but not used for AetnaResource.
                            </strong>
                        </span>
                    </li>
                    <li>
                        <strong className="colon">Duplicate</strong>
                        <span>Will allow you to duplicate an image.</span>
                    </li>
                    <li>
                        <strong className="colon">Copy</strong>
                        <span>Will put the image on your computer clipboard so that you can paste it in another program if you wish (MS Word, Excel, etc.)</span>
                    </li>
                    <li>
                        <strong className="colon">Cut</strong>
                        <span>Same as copy but this action will delete the image from the File Browser system.</span>
                    </li>
                    <li>
                        <strong className="colon">File Permission</strong> <span>Allows you to change permission to this file. <strong>NOTE: this is part of the standard tool but not used for AetnaResource.</strong></span>
                    </li>
                </ul>
            </div>
            <div className="slide">
                <ul>
                    <li>
                        <strong>Edit image</strong>
                        <span>This will allow you to crop/resize the image to fit the size it needs to be to conform to the correct site specifications.</span>
                        <ul>
                            <li>
                                <strong>Select crop Icon.</strong>
                            </li>
                        </ul>
                    </li>
                </ul>
                <img src="/portfolio/front/userguide/img/image-tools/4.png" className="img-fluid" alt="" />
            </div>
            <div className="slide">
                <ul>
                    <li>
                        <strong>Edit image (part2)</strong>
                        <ul>
                            <li>
                                <strong>Select</strong> <span>the area that you want to crop and the tool will automatically resize the image to the correct dimensions.</span>
                            </li>
                            <li>
                                <strong>Click Apply</strong> <span>If you are happy with the changes made. You will then be brought back to the previous screen.</span>
                            </li>
                            <li>
                                <strong>Click Cancel</strong> <span>if you want to go to the previous screen.</span>
                            </li>
                            <li>
                                <strong>Click Save</strong> <span>to bring you back to the list of all the images.</span>
                            </li>
                            <li>
                                <strong>Select the image</strong> <span>you just edited (it will now be 256x196).</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <img src="/portfolio/front/userguide/img/image-tools/5.png" className="img-fluid" alt="" />
            </div>
        </div>

    ),
    title: 'Image Browser'
}, {
    content: (<p>Select document or URL for the end user to access. </p>),
    title: 'Top Callout Link'
}, {
    content: (<p>Title or name that you assigned to this callout. if enabled, it will appear to the right of the intro text.</p>),
    title: 'Top Callout'
}, {
    content: (<p>Add custom content wording up to 140 characters. <strong>NOTE: Custom wording does require Copy Quality and Legal review.</strong></p>),
    title: 'Custom Content'
}, {
    content: (
        <>
            <p>
                Check this box for the Bottom Callout area to display in your microsite. This is an option to include a video or document with a brief description to the right of the introduction content. <strong>This is the opportunity to highlight a program or message of particular interest to your customer.</strong>
            </p>
            <img src="/portfolio/front/userguide/img/callouts/Callout-Additional.jpg" alt="Bottom Callout Position on the microsite" className="img-fluid" />
        </>
    ),
    title: 'Enable Bottom Callout'
}, {
    content: (
        <p>
            Select an Image/Video that represent the callout content. If you have issues with uploading your images or documents, contact the <a href="mailto:aetnaresourcemicrosite@aetna.com">AetnaResourceMicrosite</a> mailbox.
        </p>
    ),
    title: 'Bottom Callout Image'
}, {
    content: (<p>Select document or URL for the end user to access.</p>),
    title: 'Bottom Callout Link'
}, {
    content: (<p>Title or name that you assigned to this callout. If enabled, it will appear to the right of the intro text.</p>),
    title: 'Bottom Callout Title'
}, {
    content: (<p>Allows you to create a new asset.</p>),
    title: 'Create an Asset'
}, {
    content: (<p>Allows you to download a .csv export of all assets.</p>),
    title: 'Download CSV'
}, {
    content: (<p>Thumbnail image to represent asset.</p>),
    title: 'Image'
}, {
    content: (<p>The asset title, asset name and you can click to view the asset.</p>),
    title: 'Asset Information'
}, {
    content: (<p>Corresponds to the specific asset category. The three categories are Aetna Plans, Helpful Programs, Member Tools.</p>),
    title: 'Category'
}, {
    content: (
        <>
            <p>You can change the status of any one asset.</p>
            <ul>
                <li>
                    <strong className="colon">Delete:</strong> <span>Removes the asset permanently from the system and cannot be retrieved.</span>
                </li>
                <li>
                    <strong className="colon">Inactive:</strong> <span>Available in the listing/index of assets, not available on the microsite or on the asset picker in microsite forms.</span>
                </li>
                <li>
                    <strong className="colon">Active:</strong> <span>Available across the system.</span>
                </li>
            </ul>
        </>
    ),
    title: 'Asset Status'
}, {
    content: (<p>You can see which microsites are using this asset by clicking the usage number on that record.</p>),
    title: 'Usage'
}, {
    content: (<ul><li><i className="fa fa-wrench" /><span>lets you edit the asset.</span></li></ul>),
    title: 'Edit asset'
}, {
    content: (<p>Enter the name of the asset.</p>),
    title: 'Name'
}, {
    content: (<p>Enter the title that will be shown in the microsite.</p>),
    title: 'Display'
}, {
    content: (<p>Click the Select File Button to load the File Browser system.</p>),
    title: 'Image'
}, {
    content: (
        <p>Select the Segment that this assets belongs to. <strong>Note:</strong> if you select more than one segment, the assets will be duplicated into each of the subsequent selected segments
        </p>
    ),
    title: 'Segments'
}, {
    content: (<p>Select the category that this asset belongs to.</p>),
    title: 'Category'
}, {
    content: (<p>Enter content for the asset as it will appear in the microsite.</p>),
    title: 'Description'
}, {
    content: (<p>Click the Select File Button to load the File Browser system.</p>),
    title: 'Asset'
}, {
    content: (<ul><li><i className="fa fa-wrench" /><span>lets you edit the asset.</span></li></ul>)
}, {
    content: (<p>Allows you to create a new user.</p>),
    title: 'Create User'
}, {
    content: (<p>Listing of all registered users.</p>),
    title: 'Microsite User'
}, {
    content: (<p>Email address of users.</p>),
    title: 'Email'
}, {
    content: (<p>Unique Username corresponding to microsite owner.</p>),
    title: 'Username'
}, {
    content: (<p>The group the user belongs to (either User or Admin).</p>),
    title: 'Group'
}, {
    content: (
        <ul>
            <li><strong className="colon">Delete</strong>&nbsp;<span>Removes the user permanently from the system and cannot be retrieved.</span></li>
            <li>
                <strong className="colon">Inactive</strong>&nbsp;<span>Available in the listing/index of users, but cannot log in and use the application. An administrator has the ability to make a user inactive if that person no longer works at Aetna, for example.</span>
            </li>
            <li>
                <strong className="colon">Active</strong>&nbsp;<span>Able to log in and use the application.</span>
            </li>
        </ul>
    ),
    title: 'User Status'
}, {
    content: (
        <ul>
            <li><i className="fa fa-wrench" /><span>Lets you edit the user.</span></li>
            <li><i className="fa fa-ticket" /><span>Lets you send a password reset email to that user account.</span></li>
        </ul>
    ),
    title: 'User Actions'
}, {
    content: (<p>The full name of the user. </p>),
    title: 'Full Name'
}, {
    content: (<p>The first name of the user. </p>),
    title: 'First Name'
}, {
    content: (<p>The last name of the user. </p>),
    title: 'Last Name'
}, {
    content: (<p>The email address of the user for corresponding and password resetting.</p>),
    title: 'Email Address'
}, {
    content: (<p>A unique name for identifying the user and for the user to log in.</p>),
    title: 'Username'
}, {
    content: (
        <>
            <p>The group(s) that a user belongs to.</p>
            <ul>
                <li>
                    <strong className="colon">User</strong> <span>is able to log in and create a microsite, edit their profile and go to Piwik/reporting.</span>
                </li>
                <li>
                    <strong className="colon">Admin</strong> <span>is able to access all functions of the application <i>within their segment</i>.</span>
                </li>
                <li>
                    <strong className="colon">Super</strong> <span>Has access to all functionalities for all segments.</span>
                </li>
            </ul>
        </>
    ),
    title: 'Group'
}, {
    content: (<p>The Segment that this user belongs to.</p>),
    title: 'The Segment'
}, {
    content: (<p>The characters used by the user that validates their username.</p>),
    title: 'User Password'
}, {
    content: (<p>Field where you will have to confirm the password entered.</p>),
    title: 'Confirm Password'
}, {
    content: (<p>Click this link to show and force the user to enter password and confirm password.</p>),
    title: 'Change Password'
}, {
    content: (<p>Category in which assets are assigned to.</p>),
    title: 'Category Name'
}, {
    content: (<>Displays whether the Category handles Fully or Self Insured assets.</>),
    title: 'Fully Insured Category'
}, {
    content: (
        <ul>
            <li><strong className="colon">Delete</strong>&nbsp;<span>Removes the category permanently from the system and cannot be retrieved.</span></li>
            <li><strong className="colon">Inactive</strong>&nbsp;<span>Available in the listing/index of categories, but can not be used in the application.</span></li>
            <li><strong className="colon">Active</strong>&nbsp;<span>Able to use category in the application.</span></li>
        </ul>
    ),
    title: 'Category Status'
}, {
    content: (<ul><li><i className="fa fa-wrench" /><span>Lets you edit the category.</span></li></ul>),
    title: 'Category Actions'
}, {
    content: (<p>The name of the category, it will be displayed as such in the microsite and also in the application.</p>),
    title: 'Category Name'
}, {
    content: (<p>Is the category for Fully Insured asset.</p>),
    title: 'Fully Insured Category'
}, {
    content: (<p>Icon will be placed as part of the Category on the Front End.</p>),
    title: 'Category Icon'
}, {
    content: (<p>The description of the category (optional).</p>),
    title: 'Category Description'
}
];

export default tourItems;
