import {
    Typography, Grid, TextField, Box, IconButton
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import {
    useDataProvider, useNotify
} from 'react-admin';
import authProvider from './authProvider';

const defaultHelpRequest = { issueType: 'Other' };
export default function NeedHelpDialog({
    open, onClose
}) {
    const dataProvider = useDataProvider();
    const [formData, setFormData] = useState(defaultHelpRequest);
    const auth = authProvider.getAuth();
    const notify = useNotify();

    const validateAndSubmit = () => {
        dataProvider.saveHelpRequest(formData).then((d) => {
            if (d.data === true) {
                notify('Your request has been submitted.', 'info');
                setFormData(defaultHelpRequest);
                onClose();
            } else {
                notify(d.message || 'Fail to submit your request, please try again later.', 'error');
            }
        }).catch(() => {
            notify('Fail to submit your request, please try again later.', 'error');
        });
    };

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Grid container>
                    <Grid item xs={5}><Typography variant="h6">Need Help Now?</Typography></Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ float: 'right' }}>Toll free Number: (844) 954-2968</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={onClose} aria-label="close" style={{ padding: 0, float: 'right' }}>
                            <CloseIcon size="sm" />
                        </IconButton>
                    </Grid>
                </Grid>

            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <Box component="div" mb={2}>
                    <Typography variant="h6">
                        We&apos;re Here To Help
                    </Typography>
                    <Typography variant="span">
                        Your satisfaction is important to us! If you have a question or concern,
                        please review this <a style={{ color: '#7d3f98' }} target="_blank" rel="noreferrer" href={auth.faqLink || '#'}>FAQ</a>.
                        If the FAQ did not answer your question, please fill out the form below and our team will get back to you as soon as possible.
                    </Typography>
                </Box>
                <Grid container spacing={2} fullWidth>
                    <Grid item xs={12}>
                        <TextField
                            name="name"
                            label="Your Name"
                            required
                            variant="outlined"
                            fullWidth
                            value={formData.name || ''}
                            onChange={(e) => {
                                setFormData({ ...formData, name: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="email"
                            type="email"
                            label="Your Email Address"
                            required
                            variant="outlined"
                            fullWidth
                            value={formData.email || ''}
                            onChange={(e) => {
                                setFormData({ ...formData, email: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="phone"
                            type="phone"
                            label="Your Phone Number"
                            required
                            variant="outlined"
                            fullWidth
                            value={formData.phone || ''}
                            onChange={(e) => {
                                setFormData({ ...formData, phone: e.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="comments"
                            type="text"
                            label="Comments or Questions: "
                            required
                            variant="outlined"
                            fullWidth
                            value={formData.comments || ''}
                            multiline
                            rows={4}
                            onChange={(e) => {
                                setFormData({ ...formData, comments: e.target.value });
                            }}
                        />
                    </Grid>
                </Grid>

            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    variant="contained"
                    onClick={() => setFormData({})}
                >
                    RESET
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={
                        !formData.name
                        || !formData.phone
                        || !formData.email
                        || !formData.issueType
                        || !formData.comments
                    }
                    onClick={validateAndSubmit}
                >
                    SUBMIT
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
