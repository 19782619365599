/* eslint-disable no-param-reassign */
import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils, HttpError } from 'react-admin';
import apiUtil from './apiUtil';
import authProvider from './authProvider';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            // Accept: 'application/json'
        });
    }
    const auth = authProvider.getAuth();
    if (auth) {
        const { token } = auth;
        options.headers.set('Authorization', `${token}`);
    }

    // return fetchUtils.fetchJson(url, options);
    return msDataProvider.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(process.env.REACT_APP_SERVER_URL, httpClient);

const msDataProvider = {
    ...dataProvider,

    sendRequest: (resource, init = { method: 'GET', body: null }, failedError = 'Failed to get data') => {
        const auth = authProvider.getAuth();
        const { token } = auth || { token: '' };
        const theHeader = token ? { Authorization: `${token}` } : {};
        if (!init.defaultContentType) {
            theHeader['Content-Type'] = 'application/json';
        }

        const request = new Request(apiUtil.build(resource), {
            method: init.method || 'GET',
            body: init.body,
            headers: new Headers(theHeader)
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(Error(response.statusText));
                }
                return response.json();
            })
            .catch(() => {
                return Promise.reject(Error(failedError));
            });
    },

    getOptions: (resource) => {
        return msDataProvider.sendRequest(`${resource}/options`);
    },

    getUsage: (_resource, { assetId }) => {
        return msDataProvider.sendRequest(`FIAssets/usage/${assetId}`);
    },

    getProfile: () => {
        return msDataProvider.sendRequest('users/profile/me');
    },

    saveProfile: (profileData) => {
        return msDataProvider.sendRequest('users/save-profile', { method: 'POST', body: JSON.stringify(profileData) });
    },

    saveHelpRequest: (requestData) => {
        return msDataProvider.sendRequest('users/save-help', { method: 'POST', body: JSON.stringify(requestData) });
    },

    sendResetEmail: (fd) => {
        return msDataProvider.sendRequest(
            'account/reset',
            { method: 'POST', body: fd, defaultContentType: true }
        );
    },

    duplicate: (resource, { id, isDraft }) => {
        return msDataProvider.sendRequest(
            `${resource}/duplicate`,
            {
                method: 'POST',
                body: JSON.stringify({ id, isDraft })
            }
        );
    },

    changeStatus: (resource, { id, statusId }) => {
        return msDataProvider.sendRequest(
            `${resource}/change-status`,
            {
                method: 'POST',
                body: JSON.stringify({ id, statusId })
            }
        );
    },

    dir: (resource, { directory, module, brand }) => {
        return msDataProvider.sendRequest(
            `${resource}/dir`,
            {
                method: 'POST',
                body: JSON.stringify({ directory, module, brand })
            }
        );
    },

    addFolder: (resource, data) => {
        return msDataProvider.sendRequest(
            `${resource}/add-folder`,
            {
                method: 'POST',
                body: JSON.stringify(data)
            }
        );
    },

    upload: (resource, { directory, files, module }) => {
        const fd = new FormData();
        fd.append('directory', directory);
        fd.append('module', module);
        for (let i = 0; i < files.length; i += 1) {
            fd.append('files', files[i]);
        }
        return msDataProvider.sendRequest(
            `${resource}/upload`,
            {
                method: 'POST',
                body: fd,
                defaultContentType: true
            }
        );
    },

    delete: (resource, { relativePath, module, type }) => {
        const fd = new FormData();
        fd.append('relativePath', relativePath);
        fd.append('type', type);
        fd.append('module', module);
        return msDataProvider.sendRequest(
            `${resource}/delete`,
            {
                method: 'POST',
                body: fd,
                defaultContentType: true
            }
        );
    },

    rename: (resource, {
        relativePath, newName, type, module
    }) => {
        const fd = new FormData();
        fd.append('relativePath', relativePath || '/');
        fd.append('newName', newName);
        fd.append('type', type);
        fd.append('module', module);
        return msDataProvider.sendRequest(
            `${resource}/rename`,
            {
                method: 'POST',
                body: fd,
                defaultContentType: true
            }
        );
    },

    getAssetOptions: (resource, { fundingType, id, language }) => {
        return msDataProvider.sendRequest(`${resource}/asset-options/${fundingType}/${id}/${language}`);
    },

    getBenefitAssetOptions: (resource, { id, language }) => {
        return msDataProvider.sendRequest(`${resource}/benefit-asset-options/${id}/${language}`);
    },

    getPNPs: (o) => {
        return o.marketSegment
            ? msDataProvider.sendRequest(`my-microsites/pnps/${o.marketSegment}/${o.fundingTypeId}/${o.brandName}`)
            : msDataProvider.sendRequest(`my-microsites/pnps/${o.brandName}/${o.fundingTypeId}`);
    },

    getUsers: () => {
        return msDataProvider.sendRequest('all-microsites/users');
    },

    changeOwner: (_resource, { id, ownerId }) => {
        const fd = new FormData();
        fd.append('id', id);
        fd.append('ownerId', ownerId);
        return msDataProvider.sendRequest(
            'all-microsites/change-owner',
            {
                method: 'POST',
                body: JSON.stringify({ id, ownerId })
            }
        );
    },

    getSharing: (id) => {
        return msDataProvider.sendRequest(`attachment/sharing/${id}`);
    },

    findUsers: (q) => {
        const fd = new FormData();
        fd.append('q', q);
        return msDataProvider.sendRequest('users',
            {
                method: 'POST',
                body: fd,
                defaultContentType: true
            });
    },

    shareWith: (_, { id, shares }) => {
        return msDataProvider.sendRequest(
            'attachment/share-with',
            {
                method: 'POST',
                body: JSON.stringify({ id, shares })
            }
        );
    },

    getOPLink: () => {
        return msDataProvider.sendRequest('users/get-link');
    },

    getMicrosizteList: () => {
        return msDataProvider.sendRequest('my-microsites/micrositelist?_end=100000&_order=ASC&_sort=id&_start=0&brandName=Aetna');
    },
    createEvent: (resource, data) => {
        return msDataProvider.sendRequest(
            `${resource}`,
            {
                method: 'POST',
                body: JSON.stringify({ ...data })
            }
        );
    },
    updateMessage: (resource, data) => {
        return msDataProvider.sendRequest(
            `${resource}`,
            {
                method: 'POST',
                body: JSON.stringify({ ...data })
            }
        );
    },

    updateEvent: (resource, data) => {
        return msDataProvider.sendRequest(
            `${resource}/update`,
            {
                method: 'POST',
                body: JSON.stringify({ ...data })
            }
        );
    },

    updateMaxcapacity: (resource, data) => {
        return msDataProvider.sendRequest(
            `${resource}/update-maxcapacity`,
            {
                method: 'POST',
                body: JSON.stringify({ ...data })
            }
        );
    },

    enableEvent: (resource, data) => {
        return msDataProvider.sendRequest(
            `${resource}/enable-event`,
            {
                method: 'POST',
                body: JSON.stringify({ ...data })
            }
        );
    },

    deleteItem: (resource, id) => {
        return msDataProvider.sendRequest(
            `${resource}/${id}`,
            {
                method: 'DELETE'
            }
        );
    },

    getCoverPaths: (resource, data) => {
        return msDataProvider.sendRequest(`${resource}/get-cover-paths`, {
            method: 'POST',
            body: JSON.stringify({ ...data })
        });
    },

    getHeadlines: (resource, data) => {
        return msDataProvider.sendRequest(`${resource}/get-headlines`, {
            method: 'POST',
            body: JSON.stringify({ ...data })
        });
    },

    getSubheadlines: (resource, data) => {
        return msDataProvider.sendRequest(`${resource}/get-subheadlines`, {
            method: 'POST',
            body: JSON.stringify({ ...data })
        });
    },

    fetchJson: async (url, options = {}) => {
        const requestHeaders = (options.headers
            || new Headers({
                Accept: 'application/json'
            })
        );
        if (!requestHeaders.has('Content-Type')
            && !(options && options.body && options.body instanceof FormData)) {
            requestHeaders.set('Content-Type', 'application/json');
        }
        if (options.user && options.user.authenticated && options.user.token) {
            requestHeaders.set('Authorization', options.user.token);
        }
        const response = await fetch(url, { ...options, headers: requestHeaders });
        const text = await response.text();
        // const o = {
        //     status: response.status,
        //     statusText: response.statusText,
        //     headers: response.headers,
        //     body: text
        // };
        const status1 = response.status;
        const statusText1 = response.statusText;
        const headers1 = response.headers;
        const body1 = text;
        let json;
        try {
            json = JSON.parse(text);
        } catch (e) {
            // not json, no big deal
        }

        if (status1 < 200 || status1 >= 300) {
            return Promise.reject(new HttpError((json && json.error) || statusText1, status1, json));
        }
        return Promise.resolve({
            status: status1,
            headers: headers1,
            body: body1,
            json
        });
    }
};

export default msDataProvider;
