import React, { useState, createElement } from 'react';
import { useSelector } from 'react-redux';
import {
    Box, Link, MenuItem, Divider
} from '@material-ui/core';
import { MenuItemLink, getResources, useDataProvider } from 'react-admin';
import { withRouter } from 'react-router-dom';
import ExploreIcon from '@material-ui/icons/Explore';
import ReportIcon from '@material-ui/icons/LibraryBooks';
import PersonIcon from '@material-ui/icons/Person';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import authProvider from './authProvider';
import ReportingDialog from './Reporting/ReportingDialog';
import ProfileDialog from './Profile/ProfileDialog';

const Menu = ({ onMenuClick }) => {
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const auth = authProvider.getAuth();
    const [showReporting, setShowReporting] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const dataProvider = useDataProvider();

    const gotoOP = () => {
        dataProvider.getOPLink()
            .then((d) => {
                window.location.href = d.data;
            });
    };

    return (
        <Box mt={3}>
            {resources.map((resource) => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={resource.options && (resource.options.label || resource.name)}
                    leftIcon={createElement(resource.icon)}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            <MenuItem onClick={() => setShowReporting(true)}>
                <ReportIcon /> <span style={{ paddingLeft: '1rem' }}>Reporting</span>
            </MenuItem>

            {auth && (
                <Link
                    href={auth.guideLink}
                    target="_blank"
                >
                    <MenuItem>
                        <ExploreIcon /> <span style={{ paddingLeft: '1rem' }}>User Guide</span>
                    </MenuItem>
                </Link>
            )}

            <MenuItem onClick={() => setShowProfile(true)}>
                <PersonIcon /> <span style={{ paddingLeft: '1rem' }}>Profile</span>
            </MenuItem>

            <Divider />
            <MenuItem onClick={() => gotoOP()}>
                <SwapHorizontalCircleIcon /> <span style={{ paddingLeft: '1rem' }}>Dynamic OE Order Portal</span>
            </MenuItem>

            {showReporting && <ReportingDialog open={showReporting} onClose={() => setShowReporting(false)} />}
            {showProfile && <ProfileDialog open={showProfile} onClose={() => setShowProfile(false)} />}
        </Box>
    );
};

export default withRouter(Menu);
