/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import {
    BooleanInput, required, DateInput, FormDataConsumer, TextInput
} from 'react-admin';
import { TourHeader } from 'components/Tour/TourWrapper';

export default function OpenEnrollment({
    forwardShowTour
}) {
    return (
        <Box style={{ width: '100%' }}>
            <Typography variant="body2" gutterBottom>
                Please note: This callout will only display during the beginning and end dates and will not be seen in preview unless these dates are set.
            </Typography>
            <BooleanInput
                label={(
                    <TourHeader tourSelector="Enable Open Enrollment" onShowTour={forwardShowTour}>
                        <span>Enable Open Enrollment</span>
                    </TourHeader>
                )}
                helperText=""
                source="openEnrollmentEnabled"
            />
            <FormDataConsumer>
                {({ formData }) => {
                    const enabled = formData && formData.openEnrollmentEnabled;
                    const validate = enabled
                        ? [required()]
                        : [];

                    return (
                        <>
                            <TextInput
                                source="openEnrollmentLink"
                                label={(
                                    <TourHeader
                                        tourSelector="Link To Open Enrollment"
                                        onShowTour={forwardShowTour}
                                    >
                                        <span>Link</span>
                                    </TourHeader>
                                )}
                                variant="outlined"
                                helperText=""
                                fullWidth
                                validate={validate}
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <DateInput
                                        source="openEnrollmentBegin"
                                        variant="outlined"
                                        helperText=""
                                        fullWidth
                                        label={(
                                            <TourHeader tourSelector="OE Start Date" onShowTour={forwardShowTour}>
                                                <span>Begins</span>
                                            </TourHeader>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        source="openEnrollmentEnd"
                                        helperText=""
                                        variant="outlined"
                                        fullWidth
                                        label={(
                                            <TourHeader tourSelector="OE End Date" onShowTour={forwardShowTour}>
                                                <span>Ends</span>
                                            </TourHeader>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            </FormDataConsumer>
        </Box>
    );
}
