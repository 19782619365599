import * as React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

function PreviewButton(props) {
    const { record = {}, buildLink } = props;
    const previewLink = buildLink(record);

    return (
        <Button
            href={previewLink}
            target="_blank"
            color="primary"
        >
            <Tooltip title="Preview">
                <SearchIcon />
            </Tooltip>
        </Button>
    );
}

export default PreviewButton;
