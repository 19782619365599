import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDataProvider } from 'react-admin';

export default function AddFolderDialog({
    open, onClose, directory, module
}) {
    const [folderName, setFolderName] = useState('');
    const [creating, setCreating] = useState(false);
    const dataProvider = useDataProvider();
    const createFolder = () => {
        setCreating(true);
        dataProvider
            .addFolder('explorer', { directory, folderName, module })
            .then(() => {
                onClose(folderName);
                setCreating(false);
                setFolderName('');
            }).catch(() => {
                setCreating(false);
            });
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="form-dialog-title">Add New Folder</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="folderName"
                    label="Folder Name"
                    type="text"
                    fullWidth
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value.replace(/([^a-z0-9\s]+)/gi, '-'))}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={createFolder} color="primary" disabled={!folderName || creating}>
                    {creating ? 'Saving...' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
