import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip, IconButton } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { useDataProvider } from 'react-admin';
import AddFolderDialog from './AddFolderDialog';

const useStyles = makeStyles(() => ({
    input: {
        display: 'none'
    }
}));
export default function MenuBar({
    directory, onFolderAdded,
    onFileUploaded, module
}) {
    const classes = useStyles();
    const [openAddFolder, setOpenAddFolder] = useState(false);
    const [uploading, setUploading] = useState(false);

    const onAddFolderClose = (name) => {
        if (typeof name === 'string') {
            onFolderAdded(name);
        }

        setOpenAddFolder(false);
    };

    const dataProvider = useDataProvider();
    const onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setUploading(true);
            dataProvider
                .upload('explorer', { directory, files: e.target.files, module })
                .then(() => {
                    setUploading(false);
                    onFileUploaded();
                }).catch(() => {
                    setUploading(false);
                });
        }
    };

    return (
        <>
            <input className={classes.input} id="icon-button-file" multiple onChange={onFileChange} type="file" />
            {uploading
                ? <CircularProgress size={20} />
                : (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label htmlFor="icon-button-file">
                        <Tooltip title="Upload">
                            <IconButton
                                color="primary"
                                aria-label="upload files"
                                component="span"
                                style={{ padding: '4px' }}
                                disabled={uploading}
                            >
                                <CloudUploadIcon />
                            </IconButton>
                        </Tooltip>
                    </label>

                )}
            <Tooltip title="Add Folder">
                <IconButton
                    variant="contained"
                    color="primary"
                    style={{ padding: '4px' }}
                    onClick={() => setOpenAddFolder(true)}
                >
                    <CreateNewFolderIcon />
                </IconButton>
            </Tooltip>
            {/* <Tooltip title="Box View">
                <IconButton
                    variant="contained"
                    style={{ padding: '3px' }}
                    color={viewMode === 'Box' ? "Primary" : "default"}
                    onClick={() => onChangeViewMode('Box')}
                >
                    <ViewModuleIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="List View">
                <IconButton
                    variant="contained"
                    style={{ padding: '3px' }}
                    color={viewMode === 'List' ? "Primary" : "default"}
                    onClick={() => onChangeViewMode('List')}
                >
                    <ListIcon />
                </IconButton>
            </Tooltip> */}
            <AddFolderDialog open={openAddFolder} module={module} onClose={onAddFolderClose} directory={directory} />
        </>
    );
}
