import * as React from 'react';
import { Resource } from 'react-admin';

import MyIcon from '@material-ui/icons/Inbox';
import AllIcon from '@material-ui/icons/AllInbox';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AuditIcon from '@material-ui/icons/Assessment';
import ReportIcon from '@material-ui/icons/LibraryBooks';
import authProvider from 'components/authProvider';
import PresentationList from 'components/Microsite/List';
import PresentationCreate from 'components/Microsite/Create';
import PresentationEdit from 'components/Microsite/Edit';
import EventList from 'components/Event/List';
import EventCreate from 'components/Event/Create';
import EventEdit from 'components/Event/Edit';
import AttachmentList from 'components/Attachment/List';
import AttachmentEdit from 'components/Attachment/Edit';
import MessageManagementList from 'components/MessageManagement/List';

import AuditList from 'components/Audit/List';

const knownResources = [
    <Resource
        icon={MyIcon}
        name="my-microsites"
        options={{ label: 'My Microsites' }}
        list={PresentationList}
        create={PresentationCreate}
        edit={PresentationEdit}
    />,
    <Resource
        icon={MyIcon}
        name="my-events"
        options={{ label: 'My Events' }}
        list={EventList}
        create={EventCreate}
        edit={EventEdit}
    />,
    <Resource
        icon={AllIcon}
        name="all-microsites"
        requiredRole="Admin"
        options={{ label: 'All Microsites' }}
        list={PresentationList}
        create={PresentationCreate}
        edit={PresentationEdit}
    />,
    <Resource
        icon={AllIcon}
        name="all-microsites"
        requiredRole="AdminIntl"
        options={{ label: 'All Microsites' }}
        list={PresentationList}
        create={PresentationCreate}
        edit={PresentationEdit}
    />,
    <Resource
        icon={AttachmentIcon}
        name="attachment"
        options={{ label: 'My Attachment' }}
        list={AttachmentList}
        edit={AttachmentEdit}
    />,
    <Resource
        icon={ReportIcon}
        name="message"
        requiredRole="Admin"
        options={{ label: 'Message Management' }}
        list={MessageManagementList}
    />,
    // <Resource
    //     icon={SIIcon}
    //     name="SIAssets"
    //     requiredRole="Admin"
    //     options={{ label: 'SI Assets' }}
    //     list={AssetList}
    //     create={AssetCreate}
    //     edit={AssetEdit}
    // />,
    // <Resource
    //     icon={FIIcon}
    //     name="FIAssets"
    //     requiredRole="Admin"
    //     options={{ label: 'FI Assets' }}
    //     list={AssetList}
    //     create={AssetCreate}
    //     edit={AssetEdit}
    // />,

    // <Resource
    //     icon={UserIcon}
    //     name="users"
    //     requiredRole="Admin"
    //     options={{ label: 'Users' }}
    //     list={UserList}
    //     create={UserCreate}
    //     edit={UserEdit}
    // />,

    // <Resource
    //     icon={CategoryIcon}
    //     name="categories"
    //     requiredRole="Admin"
    //     options={{ label: 'Categories' }}
    //     list={CategoryList}
    //     edit={CategoryEdit}
    // />,
    <Resource icon={AuditIcon} name="audits" requiredRole="Admin" options={{ label: 'Audits' }} list={AuditList} />
];

const fetchResources = () => {
    const auth = authProvider.getAuth();
    if (!auth) return knownResources[0]; // return nothing will cause it fail to route to login
    return knownResources.filter((resource) => !resource.props.requiredRole || auth.roles.includes(resource.props.requiredRole));
};

export default fetchResources;
