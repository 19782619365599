/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import { Box, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import RadioButtonUncheckedIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@material-ui/icons/CheckBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import toFullLink from '../../UrlUtil';

export const useStyles = makeStyles(() => ({
    contentRow: {
        cursor: 'pointer'
    },
    thumb: {
        cursor: 'pointer',
        width: '6rem'
    },
    fab2: {
        position: 'absolute', right: '.5rem', top: '.5rem', zIndex: '2'
    }
}));

export function ContentGrid({
    assetOptions, toggleSelect, showImg
}) {
    const classes = useStyles();
    const [previewingAsset, setPreviewingAsset] = useState(null);
    const preview = (asset) => {
        setPreviewingAsset(asset);
    };
    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {showImg && <TableCell />}
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {assetOptions.map((a) => (
                            <TableRow
                                key={a.id}
                                className={classes.contentRow}
                                onClick={() => toggleSelect(a)}
                            >
                                {
                                    showImg ? (
                                        <>
                                            <TableCell scope="row" align="center" style={{ padding: '.5rem' }}>

                                                <img
                                                    src={toFullLink(a.imageLink, '/public/asset').replace('localhost:5001', 'aetna-pek-ff-ms-staging.memberdoc.com')}
                                                    className={classes.thumb}
                                                    alt={a.displayTitle}
                                                />
                                            </TableCell>
                                            <TableCell style={{ padding: '.5rem' }}>

                                                <b dangerouslySetInnerHTML={{ __html: a.name }} />
                                                <Box dangerouslySetInnerHTML={{ __html: a.displayTitle }} />
                                                {a.stateRestrictions && <i style={{ color: 'red' }}>{a.stateRestrictions}</i>}
                                            </TableCell>
                                        </>
                                    )
                                        : (
                                            <TableCell component="th" scope="row">
                                                <b dangerouslySetInnerHTML={{ __html: a.name }} />
                                                <Box dangerouslySetInnerHTML={{ __html: a.displayTitle }} />
                                                {a.stateRestrictions && <i style={{ color: 'red' }}>{a.stateRestrictions}</i>}
                                            </TableCell>
                                        )
                                }
                                <TableCell style={{ width: '12rem' }}>
                                    <IconButton
                                        aria-label={`select ${a.displayTitle}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.nativeEvent.stopImmediatePropagation();
                                            preview(a);
                                        }}
                                    >
                                        <VisibilityIcon color="primary" />
                                    </IconButton>
                                    <IconButton
                                        aria-label={`select ${a.displayTitle}`}
                                    >
                                        {a.selected
                                            ? <RadioButtonCheckedIcon color="primary" />
                                            : <RadioButtonUncheckedIcon color="primary" />}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {previewingAsset && (
                <Dialog
                    open={!!previewingAsset}
                    onClose={() => setPreviewingAsset(null)}
                    maxWidth="sm"
                    fullWidth
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">
                            <div
                                dangerouslySetInnerHTML={{ __html: previewingAsset.displayTitle }}
                            />
                        </Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        {
                            showImg
                                ? (
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={{ width: '50%', border: 'none' }}>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: previewingAsset.description }}
                                                        />
                                                    </TableCell>
                                                    <TableCell style={{ width: '50%', textAlign: 'center', border: 'none' }}>
                                                        <Link
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => setPreviewingAsset(null)}
                                                            target="_blank"
                                                            href={toFullLink(previewingAsset.assetLink, '/public/asset')}
                                                        >
                                                            View Asset File
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                                : (
                                    <div
                                        dangerouslySetInnerHTML={{ __html: previewingAsset.description }}
                                    />
                                )
                        }

                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button
                            variant="contained"
                            onClick={() => setPreviewingAsset(null)}
                        >
                            Close
                        </Button>
                    </MuiDialogActions>
                </Dialog>
            )}
        </>
    );
}
