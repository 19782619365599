import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CreateButton, useListContext } from 'react-admin';

const Empty = ({ label, create = true }) => {
    const { basePath } = useListContext();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No {label} yet
            </Typography>
            {
                create && <CreateButton basePath={basePath} />
            }
        </Box>
    );
};

export default Empty;
