import React, { useEffect, useState } from 'react';
import {
    List, Datagrid, TextField, EditButton,
    Filter, TextInput, DateField, SelectInput, useListContext
} from 'react-admin';
import {
    makeStyles, Typography, Tooltip, Box
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { TextLinkField, StatusField } from 'components/ListFields';

import toFullLink from 'components/UrlUtil';
import Pagination from 'components/Pagination';
import PreviewButton from 'components/ListButtons/Preview';
import CopyButton from 'components/ListButtons/Copy';
import StatusButton from 'components/ListButtons/Status';
import DeleteButton from 'components/ListButtons/Delete';
import ChangeOwnerButton from 'components/ListButtons/ChangeOwner';
import TestButton from 'components/ListButtons/Test';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import authProvider from 'components/authProvider';
import Empty from 'components/Empty';
import { TourContainer, TourHeader } from 'components/Tour/TourWrapper';

const useStyles = makeStyles({
    actions: {
        backgroundColor: '#ccc'
    },
    button: {
    }
});

const ListFilter = (props) => {
    const {
        setFilters
    } = useListContext();
    const auth = authProvider.getAuth();
    const { filterValues, resource } = props;
    const brandValue = filterValues.brandName || auth.brands[0];
    const segments = auth.brandSegment.filter((x) => x.key === brandValue && auth.segments.includes(x.value)).map((x) => x.value);

    return (
        <Filter
            {...props}
            variant="outlined"
        >
            <TextInput label="Search" source="q" alwaysOn />
            {
                resource.startsWith('a') && <TextInput label="Staff" source="staff" alwaysOn />
            }
            <SelectInput
                choices={[
                    { id: '2', name: 'Active' },
                    { id: '1', name: 'Inactive' }
                ]}
                source="status"
                alwaysOn
            />
            <SelectInput
                source="brandName"
                label="Brand"
                initialValue={auth.brands[0]}
                choices={auth.brands.map((s) => ({ id: s, name: s }))}
                disabled={auth.brands.length === 1}
                alwaysOn
                allowEmpty={false}
                onChange={(e) => {
                    const newSegments = auth.brandSegment.filter((x) => x.key === e.target.value && auth.segments.includes(x.value)).map((x) => x.value);
                    setFilters({ ...filterValues, brandName: e.target.value, segment: newSegments[0] });
                }}
            />
            {
                segments.length > 0 && (
                    <SelectInput
                        source="segment"
                        label="Segment"
                        initialValue={segments[0]}
                        choices={segments.map((s) => ({ id: s, name: s }))}
                        disabled={segments.length === 1}
                        alwaysOn
                        allowEmpty={false}
                    />
                )
            }
            {
                (brandValue === 'Aetna' || brandValue === 'Aetna International') && (
                    <SelectInput
                        choices={brandValue === 'Aetna' ? [
                            { id: 'English', name: 'English' },
                            { id: 'Spanish', name: 'Spanish' }
                        ] : [
                            { id: 'English', name: 'English' },
                            { id: 'Spanish', name: 'Spanish' },
                            { id: 'French Canadian', name: 'French Canadian' }
                        ]}
                        source="language"
                        alwaysOn
                    />
                )
            }
        </Filter>
    );
};

export default function PresentationList(props) {
    const { resource, history } = props;
    const [open, setOpen] = useState(false);
    const auth = authProvider.getAuth();
    const linkFn = (r) => toFullLink(`${r.vanityUrl}`, `/${r.segment}`);
    const showLink = (r) => r?.status === 'Active';
    const classes = useStyles(props);

    const tourRef = React.useRef();
    const forwardShowTour = (s) => tourRef.current.onShowTour(s);
    const tourNames = resource.startsWith('m')
        ? ['Customer', 'Microsite Name/Link', 'Theme', 'Funding Type', 'Creation Date', 'Modification Date', 'Microsite Status']
        : ['Microsite Owner', 'Customer', 'PS Unique Code', 'Microsite Name/Link', 'Theme', 'Funding Type', 'Creation Date', 'Modification Date', 'Microsite Status'];

    useEffect(() => {
        const isCloseMsg = localStorage.getItem('isCloseMsg');
        if (auth.message && !isCloseMsg) {
            localStorage.setItem('isCloseMsg', true);
            setOpen(true);
        }
    }, []);

    const onClose = () => {
        setOpen(false);
    };

    return (
        <TourContainer
            ref={tourRef}
            stepNames={tourNames}
        >

            <Typography variant="h6" gutterBottom>
                Welcome
            </Typography>
            <Typography variant="body1" gutterBottom>
                Welcome to the Aetna Resource microsite tool! Here, you can easily create a web microsite for your customer,
                adding in their logo, enrollment details, plan offerings and marketing materials with just a few clicks of your mouse.
                To get started, click  &quot;Create&quot; below.
            </Typography>
            <List
                {...props}
                title={resource.startsWith('m') ? 'My Microsite List' : 'All Microsite List'}
                filters={<ListFilter />}
                bulkActionButtons={false}
                classes={{ actions: classes.actions }}
                variant="outlined"
                pagination={<Pagination />}
                perPage={50}
                empty={<Empty label="Microsite" />}
                filterDefaultValues={{ segment: auth.segments[0], brandName: auth.brands[0] }}
            >
                <Datagrid>
                    {resource.startsWith('a') && (
                        <TextField
                            source="staff.name"
                            sortable={false}
                            label={(
                                <TourHeader tourSelector="Microsite Owner" onShowTour={forwardShowTour}>
                                    <span>Staff</span>
                                </TourHeader>
                            )}
                        />
                    )}
                    <TextField
                        source="clientName"
                        label={(
                            <Box style={{ width: '5.5rem' }}>
                                <TourHeader tourSelector="Customer" onShowTour={forwardShowTour}>
                                    <span>Customer</span>
                                </TourHeader>
                            </Box>
                        )}
                    />
                    <TextField
                        source="language"
                    />
                    {resource.startsWith('a') && (
                        <TextField
                            source="psunique"
                            label={(
                                <Box style={{ width: '5.5rem' }}>
                                    <TourHeader tourSelector="PS Unique Code" onShowTour={forwardShowTour}>
                                        <span>PSUnique</span>
                                    </TourHeader>
                                </Box>
                            )}
                        />
                    )}
                    <TextLinkField
                        text="name"
                        source="name"
                        name="vanityUrl"
                        href={linkFn}
                        showLink={showLink}
                        label={(
                            <Box style={{ width: '5.5rem' }}>
                                <TourHeader tourSelector="Microsite Name/Link" onShowTour={forwardShowTour}>
                                    <span>Microsite</span>
                                </TourHeader>
                            </Box>
                        )}

                    />
                    {/* <TextField
                        source="themeName"
                        label={(
                            <Box style={{ width: '5.5rem' }}>
                                <TourHeader tourSelector="Theme" onShowTour={forwardShowTour}>
                                    <span>Theme</span>
                                </TourHeader>
                            </Box>
                        )}
                    /> */}
                    <TextField
                        source="fundingTypeName"
                        label={(
                            <Box style={{ width: '7.5rem' }}>
                                <TourHeader tourSelector="Funding Type" onShowTour={forwardShowTour}>
                                    <span>Funding Type</span>
                                </TourHeader>
                            </Box>
                        )}
                    />
                    <DateField
                        source="createdOn"
                        label={(
                            <Box style={{ width: '5.5rem' }}>
                                <TourHeader tourSelector="Creation Date" onShowTour={forwardShowTour}>
                                    <span>Created</span>
                                </TourHeader>
                            </Box>
                        )}
                    />
                    <DateField
                        source="updatedOn"
                        label={(
                            <Box style={{ width: '5.5rem' }}>
                                <TourHeader tourSelector="Modification Date" onShowTour={forwardShowTour}>
                                    <span>Modified</span>
                                </TourHeader>
                            </Box>
                        )}
                    />
                    <StatusField
                        source="status"
                        label={(
                            <Box style={{ width: '4.5rem' }}>
                                <TourHeader tourSelector="Microsite Status" onShowTour={forwardShowTour}>
                                    <span>Status</span>
                                </TourHeader>
                            </Box>
                        )}
                    />
                    <EditButton icon={(
                        <Tooltip title="Edit">
                            <EditIcon />
                        </Tooltip>
                    )}
                    />
                    <PreviewButton buildLink={(r) => toFullLink(`${r.id || ''}`, '/preview')} />
                    <CopyButton history={history} />
                    <StatusButton />
                    <TestButton />
                    <DeleteButton />
                    {resource.startsWith('a') && (<ChangeOwnerButton />)}
                </Datagrid>
            </List>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
            >
                <MuiDialogTitle disableTypography>
                    <Typography variant="h6">Message</Typography>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    {auth?.message?.content ? <div dangerouslySetInnerHTML={{ __html: auth?.message?.content }} /> : null}
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </TourContainer>
    );
}
