/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Box } from '@material-ui/core';
import {
    TextInput
} from 'react-admin';

export default function TransparencyCoverage() {
    return (
        <Box style={{ width: '100%' }}>
            <TextInput
                label="Link"
                source="tcLink"
                multiline
                variant="outlined"
                helperText=""
                fullWidth
            />
        </Box>
    );
}
