/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
    Grid,
    IconButton,
    FormHelperText,
    Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    BooleanInput, SelectInput, FileInput, ImageField, FormDataConsumer, TextInput, required, maxLength
} from 'react-admin';

import {
    RemoveCircle as RemoveCircleIcon
} from '@material-ui/icons';
import RenderLayoutOption from 'components/RenderLayoutOption';
import { TourHeader } from 'components/Tour/TourWrapper';
import StateInput from 'components/StateInput';

const useStyles = makeStyles(() => ({
    logoPreview: {
        '& img': {
            maxWidth: '24rem'
        }
    }
}));

export default function Branding({
    themes, layouts, onLogoChange, formData,
    forwardShowTour, subPath, vanityUrlReadonly, fundingTypeName
}) {
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextInput
                    source="name"
                    label={(
                        <TourHeader tourSelector="Name" onShowTour={forwardShowTour}>
                            <span>Name</span>
                        </TourHeader>
                    )}
                    variant="outlined"
                    validate={[required()]}
                    helperText=""
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    source="clientName"
                    label={(
                        <TourHeader tourSelector="Client Name" onShowTour={forwardShowTour}>
                            <span>Client Name</span>
                        </TourHeader>
                    )}
                    variant="outlined"
                    validate={[required()]}
                    helperText=""
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextInput
                    label={(
                        <TourHeader tourSelector="Vanity URL" onShowTour={forwardShowTour}>
                            <span>Vanity URL</span>
                        </TourHeader>
                    )}
                    source="vanityUrl"
                    variant="outlined"
                    validate={[required()]}
                    helperText=""
                    disabled={vanityUrlReadonly}
                    fullWidth
                />
                <FormHelperText>
                    Microsite URL: &nbsp;
                    <FormDataConsumer>
                        {({ formData: fd }) => {
                            if (!fd || !fd.vanityUrl) return null;

                            const link = `${window.location.origin}/${subPath}/${fd.vanityUrl}`;

                            return (
                                <Link color="primary" target="_blank" href={link}>{link}</Link>
                            );
                        }}
                    </FormDataConsumer>
                </FormHelperText>
            </Grid>
            <Grid item xs={6}>
                <TextInput
                    label={(
                        <TourHeader tourSelector="PS Unique Code" onShowTour={forwardShowTour}>
                            <span>PSUnique</span>
                        </TourHeader>
                    )}
                    source="psunique"
                    variant="outlined"
                    validate={[required(), maxLength(10)]}
                    helperText=""
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                {
                    themes && themes.length > 0 && (
                        <SelectInput
                            source="themeName"
                            label={(
                                <TourHeader tourSelector="Theme" onShowTour={forwardShowTour}>
                                    <span>Theme</span>
                                </TourHeader>
                            )}
                            variant="outlined"
                            defaultValue={themes[0].id}
                            fullWidth
                            choices={themes}
                            helperText=""
                        />

                    )
                }
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    source="layout"
                    id="layout-select"
                    label={(
                        <TourHeader tourSelector="Layout" onShowTour={forwardShowTour}>
                            <span>Layout</span>
                        </TourHeader>
                    )}
                    variant="outlined"
                    defaultValue={layouts[0].id}
                    fullWidth
                    choices={layouts}
                    optionText={<RenderLayoutOption />}
                    helperText=""
                />
            </Grid>
            <Grid item xs={6}>
                {
                    fundingTypeName && fundingTypeName.startsWith('F') && (
                        <StateInput
                            source="stateSitus"
                            className={classes.stateSelect}
                            fullWidth
                        />

                    )
                }
            </Grid>
            <Grid item xs={12}>
                <FileInput
                    source="customerLogoFile"
                    label={(
                        <TourHeader tourSelector="Client Logo" onShowTour={forwardShowTour}>
                            <span>Client Logo</span>
                        </TourHeader>
                    )}
                    accept="image/png, image/jpeg"
                    placeholder={<p>Drop your logo here</p>}
                    onChange={onLogoChange}
                    className={classes.logoPreview}
                >
                    <ImageField source="src" title="title" />
                </FileInput>
            </Grid>
            {
                formData && formData.customerLogoFileData && formData.customerLogoFileData.id > 0
                    ? (
                        <Grid item xs={12}>
                            <IconButton onClick={() => onLogoChange()} style={{ color: '#f00' }}>
                                <RemoveCircleIcon />
                            </IconButton>
                            {formData.customerLogoFileData.fileName}
                        </Grid>
                    )
                    : null
            }
            <Grid item xs={12}>
                <FormDataConsumer>
                    {({ formData: fd }) => {
                        const confirmRequired = fd && !!fd.customerLogoFile && !fd.customerLogoConfirmed;
                        const style = confirmRequired
                            ? { color: 'red' }
                            : {};

                        return (
                            <BooleanInput
                                label={(
                                    <TourHeader tourSelector="Client Logo Permission" onShowTour={forwardShowTour}>
                                        <span>I have the customer&apos;s permission to use their logo on the site</span>
                                    </TourHeader>
                                )}
                                helperText={confirmRequired ? 'Please check' : ''}
                                source="customerLogoConfirmed"
                                style={style}
                            />
                        );
                    }}
                </FormDataConsumer>
            </Grid>
        </Grid>
    );
}
