import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import React from 'react';

export default function ReportingDialog({
    open, onClose
}) {
    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Reporting</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <Typography variant="h6" gutterBottom>
                    To access microsite reporting, please log into the
                    &nbsp;<a href="https://login.experiencecloud.adobe.com/" target="_blank" rel="noreferrer">Customer Journey Analytics</a>
                    &nbsp;tool. If you do not have a login, please submit a
                    &nbsp;<a href="https://aetna.quickbase.com/db/brgdef2wh?a=nwr" target="_blank" rel="noreferrer">Digital Analytics Request</a>.
                </Typography>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
