/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
    List, Datagrid, TextField,
    Filter, TextInput, FunctionField
} from 'react-admin';
import {
    Link
} from '@material-ui/core';
import Empty from 'components/Empty';
import FoundDialog from './FoundDialog';

const ListFilter = (props) => (
    <Filter
        {...props}
        variant="outlined"
    >
        <TextInput label="Name" source="q" alwaysOn />
    </Filter>
);

export default function AuditList(props) {
    const [found, setFound] = useState(null);
    const [fileName, setFileName] = useState(null);
    const showUsage = (n, ps) => {
        setFileName(n);
        setFound(ps);
    };
    const { history } = props;

    return (
        <>
            <List
                {...props}
                filters={<ListFilter />}
                bulkActionButtons={false}
                empty={<Empty label="Audit Data" />}
            >
                <Datagrid>
                    <TextField source="folder" sortable={false} />
                    <TextField source="name" sortable={false} />
                    <TextField source="id" label="Path" sortable={false} />
                    <TextField source="userName" sortable={false} />
                    <FunctionField
                        label="Found"
                        sortable={false}
                        render={(record) => {
                            if (!record.presentation || !record.presentation.length) {
                                return <span>Not Found</span>;
                            }

                            return <Link style={{ cursor: 'pointer' }} onClick={() => showUsage(record.name, record.presentation)}>Found</Link>;
                        }}
                    />
                </Datagrid>
            </List>
            {found && (
                <FoundDialog
                    open={!!found}
                    onClose={() => {
                        setFound(null);
                        setFileName(null);
                    }}
                    fileName={fileName}
                    presentations={found}
                    history={history}
                />
            )}
        </>
    );
}
