/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Typography, Grid, makeStyles } from '@material-ui/core';
import {
    DragIndicator as DragIndicatorIcon,
    Cancel as CancelIcon
} from '@material-ui/icons';
import FixedDraggable from '../../FixedDraggable';

const useStyles = makeStyles({
    assetItemTitle: {
        '& strong': {
            fontWeight: 700
        }
    }
});

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#DDD' : '#FFF',
    margin: `-20x 0 ${grid}px 0`,
    ...draggableStyle
});

const getListStyle = () => ({
    padding: grid,
    width: '100%'
});
function BenefitAssetList(props) {
    const { value, onChange } = props;
    const classes = useStyles(props);
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const sorted = reorder(
            value,
            result.source.index,
            result.destination.index
        );

        onChange(sorted);
    };
    const removeItem = (i) => {
        const removed = [...value];
        removed.splice(i, 1);
        onChange([...removed]);
    };
    const items = value || [];

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {items.map((item, index) => (
                            <FixedDraggable key={item.id} draggableId={item.id + item.displayTitle} index={index}>
                                {(dragProvided, s) => (
                                    <Grid
                                        container
                                        ref={dragProvided.innerRef}
                                        {...dragProvided.draggableProps}
                                        {...dragProvided.dragHandleProps}
                                        style={getItemStyle(
                                            s.isDragging,
                                            dragProvided.draggableProps.style
                                        )}
                                        spacing={2}
                                    >
                                        <Grid item xs={1}><DragIndicatorIcon style={{ color: '#2185D0' }} /></Grid>
                                        <Grid item xs={9}>
                                            <Typography variant="subtitle1" className={classes.assetItemTitle} style={{ fontWeight: 600 }} gutterBottom>
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: item.displayTitle }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            <CancelIcon size="small" color="primary" style={{ cursor: 'pointer' }} onClick={() => removeItem(index)} />
                                        </Grid>
                                    </Grid>
                                )}
                            </FixedDraggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default BenefitAssetList;
