import React, { useState } from 'react';
import {
    Button, Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

function EditButton(props) {
    const { record = {}, resource, callback = null } = props;

    const onCopyClick = () => {
        if (callback) {
            callback(resource, record);
        }
    };

    return (
        <>
            <Button
                color="primary"
                onClick={onCopyClick}
            >
                <Tooltip title="Edit">
                    <EditIcon />
                </Tooltip>
            </Button>
        </>
    );
}

export default EditButton;
