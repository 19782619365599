import { defaultTheme } from 'react-admin';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const theme = createMuiTheme(
    {
        ...defaultTheme,
        palette: {
            primary: {
                light: '#ae6dc9',
                main: '#7d3f98 !important',
                dark: '#4e1169',
                contrastText: '#ffffff'
            },
            secondary: {
                light: '#ae6dc9',
                main: '#7d3f98',
                dark: '#4e1169',
                contrastText: '#ffffff'
            }
        },
        overrides: {
            // ...
            Mui: {
                '&$focused': {
                    color: '#7d3f98 !important'
                }
            },
            MuiTextField: {
                root: {
                    '& legend': {
                        fontSize: '.85rem'
                    }
                }
            },
            MuiButtonBase: {
                root: {
                    color: '#414141'
                }
            },
            MuiInputLabel: {
                shrink: {
                    fontSize: '1.1rem'
                }
            },
            MuiFormLabel: {
                root: {
                    color: '#414141'
                }
            },
            RaMenuItemLink: {
                root: {
                    color: '#414141'
                },
                active: {
                    fontWeight: 'bold',
                    color: '#7d3f98'
                },
                icon: {
                    color: 'inherit'
                }
            },
            RaDatagrid: {
                headerCell: {
                    backgroundColor: '#7d3f98',
                    color: '#fff',
                    padding: '.4rem',
                    fontWeight: 'bold',
                    '& .MuiButtonBase-root': {
                        color: '#fff !important'
                    }
                },
                tbody: {
                    '& .column-undefined': {
                        padding: '.25rem 0 .25rem 0',
                        '& >a': {
                            minWidth: '2rem'
                        },
                        '& >button': {
                            minWidth: '2rem'
                        },
                        '& svg+span': {
                            display: 'none'
                        }
                    }
                },
                rowCell: {
                    padding: '.5rem'
                }
            },
            RaDatagridHeaderCell: {
                active: {
                    color: ' #fff !important',
                    '& svg': {
                    }
                },
                icon: {
                    color: ' #fff !important'
                }
            },
            RaLogin: {
                main: {
                    backgroundColor: '#fafafa',
                    backgroundImage: 'none !important',
                    '.MuiFormLabel-root.Mui-focused': {
                        color: '#7d3f98 !important'
                    }
                }
            },
            RaLoginForm: {
                input: {
                    MuiFormControl: {
                        root: {
                            backgroundColor: '#f00',
                            '& .MuiFormLabel-root.Mui-focused': {
                                color: '#7d3f98 !important'
                            }
                        }
                    }
                }
            }
        }
    }
);
export const aetnaTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#ae6dc9',
            main: '#7d3f98',
            dark: '#4e1169',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#ae6dc9',
            main: '#7d3f98',
            dark: '#4e1169',
            contrastText: '#ffffff'
        }
    },
    status: {
        danger: 'orange'
    }
});
export const useStyles = makeStyles((t) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    main: {
        marginBottom: t.spacing(2),
        paddingBottom: t.spacing(2)
    },
    footer: {
        padding: t.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            t.palette.type === 'light'
                ? t.palette.grey[200]
                : t.palette.grey[800]
    }
}));
