import React from 'react';
import {
    ArrayInput, SimpleFormIterator, TextInput, BooleanInput, FormDataConsumer, maxLength
} from 'react-admin';
import {
    RemoveCircle as RemoveCircleIcon,
    AddCircle as AddCircleIcon
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResourceInput from '../../FileBrowser/ResourceInput';

const useStyles = makeStyles(() => ({
    callouts: {
        width: '100%',
        padding: '0 !important',
        marginTop: 0
    },
    simpleFormIterator: {
        marginTop: 0,
        RaSimpleFormIterator: {
            root: {
                marginTop: 0
            }
        }

    },
    textInput: {
        '& .Mui-error': {
            color: '#D93D0E'
        }
    }
}));

export default function CalloutsInput({ brandName, maxCount = 6 }) {
    const classes = useStyles();
    return (
        <FormDataConsumer>
            {({ formData: fd }) => {
                const max = maxCount || 6;
                const maxReached = fd.callouts && fd.callouts.length === max;

                return (
                    <ArrayInput
                        source="callouts"
                        label=""
                        variant="outlined"
                        className={classes.callouts}
                    >
                        <SimpleFormIterator
                            variant="outlined"
                            disableAdd={maxReached}
                            className={classes.simpleFormIterator}
                            removeButton={
                                (
                                    <IconButton aria-label="delete">
                                        <RemoveCircleIcon color="primary" fontSize="small" />
                                    </IconButton>
                                )
                            }
                            addButton={
                                (
                                    <IconButton aria-label="add">
                                        <AddCircleIcon color="primary" fontSize="small" />
                                    </IconButton>
                                )
                            }
                        >
                            <BooleanInput
                                label="Enabled"
                                source="enabled"
                                helperText=""
                            />
                            <FormDataConsumer>
                                {({ getSource, scopedFormData = {} }) => {
                                    if (scopedFormData.enabled) {
                                        return (
                                            <>
                                                <ResourceInput
                                                    source={getSource('image')}
                                                    record={scopedFormData}
                                                    brandName={brandName}
                                                    label="Image"
                                                    useStaticCallouts
                                                />
                                                <ResourceInput
                                                    source={getSource('link')}
                                                    record={scopedFormData}
                                                    brandName={brandName}
                                                    label="Link"
                                                    useStaticCallouts
                                                />
                                                <TextInput
                                                    label="Title of Link"
                                                    source={getSource('linkTitle')}
                                                    record={scopedFormData}
                                                    variant="outlined"
                                                    helperText=""
                                                    fullWidth
                                                />

                                                <TextInput
                                                    label="Custom Content"
                                                    source={getSource('customContent')}
                                                    record={scopedFormData}
                                                    multiline
                                                    className={classes.textInput}
                                                    variant="outlined"
                                                    helperText=""
                                                    fullWidth
                                                    validate={[maxLength(80, 'Maximum character limit is 80')]}
                                                />
                                            </>
                                        );
                                    }
                                    return null;
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                );
            }}
        </FormDataConsumer>
    );
}
