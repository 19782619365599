import React, { useState } from 'react';
import {
    Button,
    Dialog, DialogActions, DialogTitle, Tooltip
} from '@material-ui/core';
import TestIcon from '@material-ui/icons/Title';
import { useDataProvider, useRefresh } from 'react-admin';

function CopyButton(props) {
    const { record = {}, resource } = props;
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    const onCopyClick = () => {
        setOpenConfirm(true);
    };
    const closeConfirm = (confirmed) => {
        if (confirmed === true) {
            setLoading(true);
            dataProvider.changeStatus(resource, {
                id: record.id,
                statusId: (
                    record.status === 'Test' ? 2 : 3
                )
            })
                .then(() => {
                    refresh();
                })
                .finally(() => {
                    setLoading(false);
                    setOpenConfirm(false);
                });
        } else {
            setOpenConfirm(false);
        }
    };

    return (
        <>
            <Button
                color="primary"
                onClick={onCopyClick}
            >
                <Tooltip title="Make as Test">
                    <TestIcon />
                </Tooltip>
            </Button>
            {openConfirm && (
                <Dialog
                    open={openConfirm}
                    onClose={closeConfirm}
                >
                    <DialogTitle id="alert-dialog-title">
                        {record.status === 'Test' ? "Are you sure you'd like to reactivate?" : 'Are you sure you want to make it as Test?'}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={closeConfirm} disabled={loading}>
                            No
                        </Button>
                        <Button onClick={() => closeConfirm(true)} color="primary" disabled={loading}>
                            {loading ? 'Saving...' : 'Yes'}
                        </Button>
                    </DialogActions>
                </Dialog>

            )}
        </>
    );
}

export default CopyButton;
