import React from 'react';
import {
    Toolbar, SaveButton
} from 'react-admin';

const transformAllData = (data, restData) => {
    const { topLinks } = data;
    const navLinks = [];
    if (topLinks) {
        // eslint-disable-next-line guard-for-in
        for (const k in topLinks) {
            const link = topLinks[k];
            if (link.enabled) {
                navLinks.push({
                    code: k,
                    data: { ...link, enabled: 'true' }
                });
            }
        }
    }

    const fd = {
        ...data,
        navLinks,
        ...restData
    };

    return (fd);
};

export default function EditToolbar(props) {
    const {
        allAgreed,
        restFormData
    } = props;

    const transformData = (data) => {
        return transformAllData(data, restFormData);
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Save"
                transform={transformData}
                disabled={!allAgreed}
                redirect="list"
            />
        </Toolbar>
    );
}
