import * as React from 'react';
import { Admin } from 'react-admin';
import fetchResources from 'components/Resources';
import TheLayout from 'components/Layout';
import authProvider from 'components/authProvider';
import { theme } from 'components/theme';
import LoginPage from 'components/Login';

import DataProvider from 'components/DataProvider';

const App = () => {
    return (
        <Admin
            disableTelemetry
            theme={theme}
            layout={TheLayout}
            authProvider={authProvider}
            dataProvider={DataProvider}
            loginPage={LoginPage}
        >
            {fetchResources}
        </Admin>
    );
};

export default App;
