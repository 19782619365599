import React from 'react';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((t) => ({
    header: {
        width: '10rem',
        color: '#fff',
        background: '#707070',
        lineHeight: '28px',
        height: '28px',
        cursor: 'move',
        fontWeight: 'bold'
    },
    heading: {
        fontSize: t.typography.pxToRem(15),
        fontWeight: t.typography.fontWeightBold
    },
    root: {
        margin: '0 !important',
        borderRadius: '0 !important'
    }
}));

export default function EditAccordion({
    title, children
}) {
    const classes = useStyles();
    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
