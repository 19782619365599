/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    InputBase, Typography, Grid
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { ContentGrid } from './ContentGrid';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        width: '100%',
        minHeight: '11rem'
    },
    title: {
        color: '#000'
    },
    tile: {
        boxShadow: '0px 3px 6px #00000029',
        cursor: 'pointer'
    },
    thumb: {
        cursor: 'pointer'
    },
    titleBar: {
        background: '#fff',
        cursor: 'default'
    },
    contentRow: {
        cursor: 'pointer'
    },
    input: {
        width: '10rem'
    },
    inputIcon: {
        padding: '0'
    },
    fab: {
        position: 'absolute', left: '.5rem', top: '.5rem', zIndex: '2'
    }
}));

export default function AssetSelectDialog({
    open, onClose, assetOptions, title, toggleSelect, toggleFeatured, fundingType
}) {
    const classes = useStyles();
    const [q, setQ] = useState('');

    if (!open) return null;

    const qReg = q ? new RegExp(q, 'i') : null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={fundingType.startsWith('S') ? 'md' : 'sm'}
            fullWidth
        >
            <MuiDialogTitle disableTypography>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography variant="h6">Select Assets: {title}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <InputBase
                            className={classes.input}
                            placeholder="Search"
                            inputProps={{ 'aria-label': 'search' }}
                            value={q}
                            onChange={(e) => setQ(e.target.value)}
                        />
                        <IconButton type="submit" className={classes.inputIcon} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </MuiDialogTitle>
            <MuiDialogContent dividers>

                <ContentGrid
                    assetOptions={assetOptions.filter((a) => {
                        return !q || a.displayTitle.search(qReg) >= 0 || a.name.search(qReg) >= 0;
                    })}
                    showImg={false && fundingType.startsWith('S')}
                    toggleSelect={toggleSelect}
                    toggleFeatured={toggleFeatured}
                />
            </MuiDialogContent>
            <MuiDialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}
