/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import {
    Link, makeStyles
} from '@material-ui/core';
import message from 'components/Common/Message';

const useStyles = makeStyles({
    button: {
        color: '#7d3f98',
        '&:hover': {
            color: '#40a9ff',
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
});

const TextLinkField = ({
    record = {},
    text,
    name,
    source,
    href,
    showLink
}) => {
    const classes = useStyles();
    const row = typeof record[source] === 'object' ? record[source] : record;
    const link = href(row);
    const show = showLink ? showLink(row) : true;
    const onClick = () => {
        message.error('This site is not available.');
    };

    return (
        <>
            <span>{row[text]}</span>
            <br />
            {
                show ? (
                    <Link target="_blank" href={link} color="primary">
                        {row[name]}
                    </Link>
                )
                    : <span className={classes.button} onClick={onClick}>{row[name]}</span>
            }
        </>
    );
};

export default TextLinkField;
