import React from 'react';
import { Pagination } from 'react-admin';

export default function ThePagination(props) {
    return (
        <Pagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            {...props}
        />
    );
}
