import React, { useEffect } from 'react';
import {
    Select, FormControl, InputLabel, MenuItem
} from '@material-ui/core';
import { useInput } from 'react-admin';

const SelectInput2 = (props) => {
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);
    const { label, menuItems, currentValue } = props;

    useEffect(() => {
        if (menuItems && menuItems.length > 0) {
            if (!menuItems.find((i) => i.value === currentValue)) {
                onChange({ target: { name, value: menuItems[0].value } });
            }
        }
    }, [onChange, currentValue, name, menuItems]);

    if (!menuItems || !menuItems.length) {
        return null;
    }

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                margin="dense"
                name={name}
                label={label}
                onChange={onChange}
                error={!!(touched && error)}
                helperText={touched && error}
                required={isRequired}
                {...rest}
            >
                {(menuItems || []).map((m) => <MenuItem key={m.value} value={m.value}>{m.text}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default SelectInput2;
