import {
    Button,
    Dialog, DialogActions, DialogTitle
} from '@material-ui/core';
import React from 'react';

export default function ConfirmDeleteDialog({
    open, onClose, onYes, deleting
}) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to delete {deleting.type} <b>{deleting.name}</b>?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>
                    No
                </Button>
                <Button onClick={onYes} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}
